import React, { useState, useEffect } from 'react';
import Loader from 'components/loader';
import { Accordion } from "react-bootstrap";
import { USERPALNSAPI } from 'utils/Services';
function PayPer({ id }) {
    const [loading, setLoading] = useState(false)
    const [allData, setallData] = useState([])

    const fecthData = async () => {
        const data = {
            "planType": "Pay Per Use",
            "customerId": id?.data


        }

        try {
            setLoading(true)
            const result = await USERPALNSAPI(data);
            if (result?.res?.status == 200) {
                setallData(result?.res?.data)
                setLoading(false)
            }
            else {
                setLoading(false)
            }

        }
        catch (err) {
            console.log(err)
            setLoading(false)
        }

    }
    useEffect(() => {
        fecthData()
    }, []);


    return (
        <div className="container-fluid pb-5 mt-5">
            <Loader loading={loading} />
        {allData && allData.length > 0 ? (
            <div className="d-flex justify-content-center flex-column px-5">    
                {allData.map((tab) => (
                    <Accordion key={tab.planName.id} defaultActiveKey={tab.planName.id}>
                        <Accordion.Item eventKey="0">
                            <Accordion.Header>{tab.planName.plan_name}</Accordion.Header>
                            <Accordion.Body>
                                <div>
                                    <div className="row">
                                        <div className="d-flex justify-content-between">
                                            <div>
                                                <div className="d-flex justify-content-between">
                                                    <div>
                                                        <label>Plan Type:</label>
                                                    </div>
                                                    <div className="mx-2">
                                                        <label>{tab.planName.planType}</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <div className="d-flex justify-content-between">
                                                    <div>
                                                        <label>Booking Type:</label>
                                                    </div>
                                                    <div className="mx-2">
                                                        <label>{tab.planName.isBooking ?? "Confirm"}</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="d-flex justify-content-between">
                                            <div>
                                                <div className="d-flex justify-content-between">
                                                    <div>
                                                        <label>Security Deposit:</label>
                                                    </div>
                                                    <div className="mx-2">
                                                        <label>{tab.planName.securityDeposit}</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <div className="d-flex justify-content-between">
                                                    <div>
                                                        <label>Amount:</label>
                                                    </div>
                                                    <div className="mx-2">
                                                        <label>{tab.planName.amount}</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                ))}
            </div>
        ) : (
            <div className='d-flex justify-content-center'>
                <label>No content available</label>
            </div>
        )}
    </div>
    


    )
}

export default PayPer