import React, { useContext, useEffect ,useState} from 'react'
import LogOut from "components/modals/LogOut"
import { useNavigate } from 'react-router-dom';
import { ModalContext } from 'utils/Contexts/modalContext';
import { VIEWDOCKYARDAPI, DELETEDOCKYARDAPI } from 'utils/Services';
function Logout() {
    const modalContext = useContext(ModalContext);
    const [loading, setLoading] = useState(false)
    const { closeModal, handleModalData } = modalContext;
    const navigate = useNavigate();
    const access_tken = localStorage.getItem('_authData')
    const ftechData = () => {
        
        if(access_tken)
            {
                localStorage.clear();
                navigate('/login')
            }
            else{
                navigate('/login') 
            }

        
    }
    useEffect(() => {
        handle()
    }, [])
    const fecthData = async () => {
   
        try {
            setLoading(true)
            const result = await VIEWDOCKYARDAPI();
            if (result?.res?.status == 200) {
                
         
                setLoading(false)
            }
            else {
                setLoading(false)
            }

        }
        catch (err) {
            console.log(err)
            setLoading(false)
        }

    }
    useEffect(() => {
        fecthData()
    }, []);
    const handle = () => {
        const CreateDocumnet = <LogOut closeModal={closeModal} reload={ftechData} />;
                handleModalData(CreateDocumnet, "lg");
        }

   
    return (
        <div></div>
    )
}

export default Logout