import React from 'react'
function EditDelete({ text, type, disabled, isCancel }) {
    return (
        <div>
            <button className={`edit_delete`} type={type} disabled={disabled}>
                {text}
            </button>
        </div>
    );
}


export default EditDelete
