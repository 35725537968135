import React, { useEffect, useState } from 'react';
import { Formik, Form } from 'formik';  // Import Formik and Form
import FormControl from 'components/controls/FormControl';
import Button from 'components/buttons/ADD';




function LogOut({ closeModal, reload }) {

    return (
        <div className='row'>

            <div className='com_modal'>
                {/* Use Formik wrapper */}
                <Formik
                    
                >
                    {(formik) => (
                        // Wrap your content in the Form component
                        <Form className='form_default text_left'>
                            <div className='mb-3'>
                                <FormControl control="FormHead" title=" You Want To Logout" />
                            </div>
                         

                            <div className='d-flex justify-content-end'>
                                <div className='col-lg-2 mx-5' onClick={closeModal}>
                                    <Button text="Cancel" type="button" isCancel={true} />
                                </div>

                                <div className='col-lg-2' onClick={reload}>
                                    <Button text="Logout" type="button" />
                                </div>

                            </div>


                        </Form>
                    )}
                </Formik>
            </div>
        </div >
    );
}

export default LogOut;
