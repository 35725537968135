import React from 'react';
import FormikControl from "components/controls/FormControl";
import Button from "components/buttons/ADD";

import { Formik, Form } from 'formik'

function CoomonDelete({ closeModal, data, handle, reload, setCustomer }) {
    const handlecancel = () => {
        closeModal()
        {
            setCustomer
                && setCustomer([])
        }

        {
            reload && reload()
        }


    }
    return (
        <div className='row'>
            <div className='com_modal'>
                <Formik
                >
                    {formik => {
                        return (
                            <Form className="form_default">
                                <div className='mb-4'>
                                    <FormikControl
                                        control='heading'
                                        title='Are you sure you want to remove this?'

                                    />

                                </div>

                                <div className='d-flex justify-content-evenly align-items-center my-3'>
                                    <div className='col-lg-3'> 
                                    <div onClick={handlecancel}>
                                        <Button text="No" type="button" isCancel={true} />

                                    </div>
                                    </div>
                                    <div className='col-lg-3'>
                                    <div onClick={() => handle(data)}>
                                        <Button text="Yes" type="button" />
                                    </div>
                                    </div>

                                
                                  
                                </div>

                            </Form>
                        )
                    }}
                </Formik>


            </div>
        </div>
    )
}

export default CoomonDelete