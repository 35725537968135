import React from 'react';
import { Field, ErrorMessage } from 'formik';
import TextError from "../../Errros/index"

function index(props) {
    const { name, defaultValue, placeholder, type, value, ...rest } = props;

  
    return (
        <div>
            <Field name={name}>
                {({ field, form }) => (
                    <div className='row'>
                        <div >
                            <input name={name} type={type} placeholder={placeholder}       {...rest}
                                {...field} />
                            <ErrorMessage component={TextError} name={name} />
                        </div>



                    </div>
                )}
            </Field>
        </div>
    )
}

export default index