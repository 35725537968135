import React, { useContext, useEffect, useMemo, useState } from 'react';
import CustomTable from "components/Table"
import Loader from 'components/loader';
import FallBack from "components/fallBack"
import { VIEWCREATECHANNELPARTNERAPI, VIEWCREATECHANNELPARTNERAPIChannel } from 'utils/Services';


function ViewInformation() {

    const [loading, setLoading] = useState(false)
    const [allData, setallData] = useState([])


    const FeedsColumns = useMemo(
        () => [
            {
                Header: "Sno.",
                accessor: (row, index) => {
                    return (
                        <>
                            <span className='text-sm'>{index + 1}</span>
                        </>
                    );
                }
            },
            // {
            //     Header: "Channel Partner Name",
            //     accessor: (Customer) => Customer?.channel_partner_name || "hgiii" 
            // },
            {
                Header: "Address",
                accessor: (Customer) => Customer?.address1 || ""
            },
            {
                Header: "City",
                accessor: (Customer) => Customer?.city || ""
            },
            {
                Header: "State", 
                accessor: (Customer) => Customer?.state || ""
            },
            // {
            //     Header: "Postal/Zip Code",
            //     accessor: (Customer) => Customer?.zip_code || "" 
            // },

            // {
            //     Header: "GST No.", 
            //     accessor: (Customer) => Customer?.gst_no || ""
            // },
     
        ],
        []
    );


    const resultrole = localStorage.getItem('role');
    const channelid = localStorage.getItem('channelId');

    const fecthData = async () => {
        try {
            setLoading(true);
            let response;

            if (resultrole === "3" || resultrole === "2" && channelid) {
                response = await VIEWCREATECHANNELPARTNERAPIChannel(`?id=${channelid}`);
            } else {
                response = await VIEWCREATECHANNELPARTNERAPI();
            }
            if (response?.res?.status === 200) {
                setallData(response.res.data);

            }
        } catch (err) {
            console.log(err);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fecthData();
    }, []);


    return (
        <div className="container-fluid pb-5">
            <div className='row'>
                <Loader loading={loading} />

                <div className='d-flex justify-content-between  my-3 '>
                    <div className='col-lg-6'>
                        <h2>User Name</h2>
                    </div>



                </div>

                {
                    allData.length > 0 ? (<div className='default_table'>
                        <CustomTable data={allData} columns={FeedsColumns} />


                    </div>) : (<FallBack />)
                }

            </div>
        </div>
    )
}

export default ViewInformation