import React, { useState } from 'react';

export const Viewbutton = ({ size, onSelectView, typelist }) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(typelist ? 'Monthly' : 'Daily');

  const toggleDropdown = () => setDropdownOpen(!dropdownOpen);

  const handleItemClick = (item) => {
    setSelectedItem(item);
    onSelectView(item);
    setDropdownOpen(false); // close dropdown on selection
  };

  return (
    <div className="d-flex" style={{ position: 'relative' }}>
      <button
        onClick={toggleDropdown}
        style={{
          backgroundColor: 'white',
          color: 'black',
          padding: '9px',
          fontSize: '14px',
          border: '1px solid black',
          cursor: 'pointer',
        }}
      >
        {selectedItem} <span>&#9662;</span>
      </button>
      {dropdownOpen && (
        <ul
          style={{
            position: 'absolute',
            top: '100%',
            left: 0,
            backgroundColor: 'white',
            border: '1px solid black',
            listStyle: 'none',
            margin: 0,
            padding: 0,
            width: '100%',
            zIndex: 1,
          }}
        >
          <li
            style={{ padding: '8px', cursor: 'pointer' }}
            onClick={() => handleItemClick('Daily')}
          >
            Daily
          </li>
          <li
            style={{ padding: '8px', cursor: 'pointer' }}
            onClick={() => handleItemClick('Weekly')}
          >
            Weekly
          </li>
          <li
            style={{ padding: '8px', cursor: 'pointer' }}
            onClick={() => handleItemClick('Monthly')}
          >
            Monthly
          </li>
        </ul>
      )}
    </div>
  );
};
