import React, { useContext, useEffect, useMemo, useState } from 'react';
import FormControl from "components/controls/FormControl";
import CustomTable from "components/Table";
import Button from "components/buttons/ADD";
import EditDelete from "components/buttons/EditDelete";
import { ModalContext } from 'utils/Contexts/modalContext';
import CreateUser from 'components/modals/CreateUser';
import EditUser from 'components/modals/EditUser';
import CoomonDelete from 'components/modals/CoomonDelete';
import Loader from 'components/loader';
import { DELETEDOCKYARDAPI, VIEWCHANNELFLEETSTATUS, VIEWCREATECHANNELPARTNERAPI } from 'utils/Services';
import { toast } from 'react-toastify';
import ViewUserStatus from 'components/modals/ViewUserStatus';
import { useNavigate } from 'react-router-dom';
import  search  from "../../../assets/img/Icons/Dashboard/search.svg"


function FleetStatus() {
    const modalContext = useContext(ModalContext);
    const { closeModal, handleModalData } = modalContext;
    const [loading, setLoading] = useState(false); 
    const [allData, setallData] = useState([]);
    const [options, setOptions] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [filterStatus, setFilterStatus] = useState('');
    const [showDropdown, setShowDropdown] = useState(false);
    const [filterStatuscountry, setFilterStatuscountry] = useState('');
    const [showDropdowncountry, setShowDropdowncountry] = useState(false);
    const [searchVal,setsearchVal]=useState([])
    
    
    // const handleSearch = (event) => {
    //     const searchValue = event.target.value.toLowerCase();  
    //     const filterArray = allData.filter((e) => {
    //         const fullName = e?.customer?.full_name?.toLowerCase() || ""; 
    //         return fullName.includes(searchValue);

    //         const Registerid = e?.customer ?.registrationNo?.toLowerCase() || "";
    //         return Registerid.includes(searchValue);
    //     });
        
    //     setsearchVal(searchValue);  
        
    //     if (searchValue === "") {
    //         setFilteredData(allData);  
    //     } else {
    //         setFilteredData(filterArray);  
    //     }
    // };

    const handleSearch = (event) => {
        const searchValue = event.target.value.toLowerCase();  
        const filterArray = allData.filter((e) => {
            const fullName = e?.customer?.full_name?.toLowerCase() || ""; 
            const registerId = e?.customer?.registrationNo?.toLowerCase() || ""; 
            return fullName.includes(searchValue) || registerId.includes(searchValue);
        });
        
        setsearchVal(searchValue);  
        
        if (searchValue === "") {
            setFilteredData(allData);  
        } else {
            setFilteredData(filterArray);  
        }
    };


    const handleDetails = (info) => {
        const EditDocumnet = <ViewUserStatus closeModal={closeModal} data={info} reload={fecthData} />;

        handleModalData(EditDocumnet, "md");

    };


    const handlechangebtn = () => {
        setShowDropdown(!showDropdown);
    };

    const handlechangebtncountry = () => {
        // setShowDropdowncountry(!showDropdowncountry);
    };

    const handleFilterChange = (e) => {
        setFilterStatus(e.target.value);
        setShowDropdown(false);
    };

    const handleFilterChangecontry = (e) => {
        // setFilterStatuscountry(e.target.value);
        // setShowDropdowncountry(false);
    };

    const FeedsColumns = useMemo(
        () => [
            {
                Header: "Sno.",
                accessor: (row, index) => (
                    <span className='text-sm'>{index + 1}</span>
                ),
            },
            {
                Header: "Registration ID",
                accessor: (row) => row?.customer?.registrationNo || "N/A",
            },
            {
                Header: "Rider Name",
                accessor: (row) => row?.customer?.full_name || "Unknown",
            },
            {
                Header: "Start Date",
                accessor: (row) => {
                    const createdAt = row?.rideDetail?.createdAt;
                    return createdAt ? createdAt.split('T')[0] : "--";
                }
            },

            {
                id: "Live Status",
                Header: () => (
                    <div className="user-status-container" style={{ position: 'relative' }} onClick={handlechangebtn}>
                        <div className="d-flex align-items-center">
                            <span>User Status</span>
                            <span className="arrow-icon ml-2" style={{ cursor: 'pointer' }}>▼</span>
                        </div>
                        {showDropdown && (
                            <div className="dropdown-container" style={{
                                position: 'absolute', top: '100%', left: 0, zIndex: 1000,
                                background: 'white', borderRadius: '4px',   overflow: 'visible'
                            }}>
                                <select
                                    className="form-control"
                                     value={filterStatus}
                                     onClick={(e) => e.stopPropagation()}
                                    onChange={handleFilterChange}
                                     style={{ width: '100%' }}
                               >
                                    <option value="">All</option>
                                    <option value="Active">Active</option>
                                    <option value="Inactive">InActive</option>
                                </select>
                            </div>
                        )}
                    </div>


                ),
                accessor: (row) => (
                    <div className='d-flex align-items-center'>
                        {row?.rideDetail?.Is_complete === false ? (
                            <span className='Verified'>Active</span>
                        ) : (
                            <span className='error'>InActive</span>
                        )}
                    </div>
                ),
            },
            {
                id: "City",
                Header: () => (
                    <div className="user-status-container" style={{ position: 'relative' }} onClick={handlechangebtncountry}>
                        <div className="d-flex align-items-center">
                            <span>City</span>
                            {/* <span className="arrow-icon ml-2" style={{ cursor: 'pointer' }}>▼</span> */}
                        </div>
                        {/* {showDropdowncountry && (
                            <div className="dropdown-container" style={{
                                position: 'absolute', top: '100%', left: 0, zIndex: 1,
                                background: 'white', borderRadius: '4px'
                            }}>
                                <select className="form-control" value={filterStatuscountry} onChange={handleFilterChangecontry}>
                                    <option value="">All</option>
                                    <option value="Noida">Noida</option>
                                    <option value="Lucknow">Lucknow</option>
                                </select>
                            </div>
                        )} */}
                    </div>
                ),
                accessor: (row) => row?.customer?.city || "Unknown",
            },



            {
                Header: "Actions",
                accessor: (row) => (
                    <div className='d-flex justify-content-between'>
                        <div className='w-100 mx-2' onClick={() => handleDetails(row?.rideDetail?.customerId)}>
                            <EditDelete text="View all Status" type="Button" />
                        </div>
                    </div>
                ),
            },
        ],
        [showDropdown, showDropdowncountry]
    );


    const channelid = localStorage.getItem('channelId');

    const fecthData = async () => {
        try {
            setLoading(true);
            const result = await VIEWCHANNELFLEETSTATUS(`?channel_partner=${channelid}`);
            if (result?.res?.status === 200) {
                const apiData = result?.res?.data;
                console.log(apiData, 'Fetched API Data');
                setallData(apiData);
                filterData(apiData, filterStatus, filterStatuscountry);
            }
            setLoading(false);
        } catch (err) {
            console.log(err);
            setLoading(false);
        }
    };

    useEffect(() => {
        fecthData();
    }, []);


    // const filterData = (data, status, statusCountry) => {
    //     let filtered = data;


    //     if (status) {
    //         filtered = filtered.filter((user) => {
    //             const isActive = user?.rideDetail?.Is_complete === false;
    //             return status === "Active" ? isActive : !isActive;
    //         });
    //     }


    //     if (statusCountry) {
    //         filtered = filtered.filter((user) => user?.customer?.city === statusCountry);
    //     }

    //     setFilteredData(filtered);
    // };  


    const filterData = (data, status, statusCountry) => {
        let filtered = data;

        // Filter by status if a specific filter is selected
        if (status) {
            filtered = filtered.filter((user) => {
                const isActive = user?.rideDetail?.Is_complete === false;
                return status === "Active" ? isActive : !isActive;
            });
        }

        // Filter by country if a specific country is selected
        if (statusCountry) {
            filtered = filtered.filter((user) => user?.customer?.city === statusCountry);
        }

        // Ensure all "Active" entries are at the top, and "Inactive" entries are at the bottom
        filtered = filtered.sort((a, b) => {
            const isAActive = a?.rideDetail?.Is_complete === false;
            const isBActive = b?.rideDetail?.Is_complete === false;

            if (isAActive && !isBActive) {
                return -1; // Active should come before Inactive
            } else if (!isAActive && isBActive) {
                return 1; // Inactive should come after Active
            } else {
                return 0; // No change if both are Active or both are Inactive
            }
        });

        setFilteredData(filtered);
    };


    useEffect(() => {
        setFilteredData(allData);
    }, [allData]);

    useEffect(() => {
        filterData(allData, filterStatus, filterStatuscountry);
    }, [filterStatus, filterStatuscountry, allData]);

    // useEffect(() => {
    //     fetchChannel();
    // }, []);

    // const fetchChannel = async () => {
    //     setLoading(true);
    //     try {
    //         const result = await VIEWCREATECHANNELPARTNERAPI();
    //         if (result?.res?.status === 200) {
    //             const data = result?.res?.data?.map((res) => ({
    //                 id: res?.id,
    //                 label: res?.channel_partner_name,
    //                 value: res?.channel_partner_name,
    //             }));
    //             setOptions(data);
    //         }
    //         setLoading(false);
    //     } catch (err) {
    //         console.log(err);
    //         setLoading(false);
    //     }
    // };



    return (
        <div className="container-fluid pb-5">
            <div className='row'>
                <Loader loading={loading} />
                <div className='d-flex align-items-center justify-content-between my-3'>
                    <div className='col-lg-4'>
                        {/* <FormControl control={"Search"} /> */}
                        <div className="search">
            <div className="">
                <button id="button-addon2" type="submit" className="search_btn">
                <img src={search} />
                </button>
            </div>
            <input
                type="search"
                value={searchVal} 
                onChange={handleSearch}
                // placeholder={placeholder}
                aria-describedby="button-addon2"
                className="serch_input"
            />
        </div>
                    </div>
                    <div>Total Running Rider :{filteredData.length}</div>
                </div>
                {filteredData.length > 0 && (
                    <div className='default_table'>
                        <CustomTable data={filteredData} columns={FeedsColumns} />
                    </div>
                )}
            </div>
        </div>
    );
}

export default FleetStatus;
