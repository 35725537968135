// Chakra imports

// Layout components
import Navbar from "components/navbar/NavbarAdmin.js";
import Header from "components/header/Header";
import Sidebar from "components/sidebar/Sidebar";
import MainWrapper from "components/wrapper";
import { SidebarContext } from "contexts/SidebarContext";
import React, { useState, useEffect, useMemo, useContext } from "react";
import { ModalContext } from "utils/Contexts/modalContext";
import CommonModal from "../../components/commonmodal";
import {
  AbilityContext,
  createAppAbility,
} from "utils/Contexts/AbilityContext";
import { useSelector } from "react-redux";
// Custom Chakra theme
export default function Dashboard(props) {
  const { ...rest } = props;
  const [toggle, setToggle] = useState(true);
  const [toggle2, setToggle2] = useState(true);
  // states and functions
  const [fixed] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalData, setModalData] = useState("");
  const [modalSize, setModalSize] = useState("");
  const [data, setData] = useState({});
  const [toggleSidebar, setToggleSidebar] = useState(false);
  // functions for changing the states from components

  const closeModal = () => {
    setIsModalOpen(false);
  };
  const handleModalData = (data, size) => {
    setModalData(data);
    setModalSize(size);
    setIsModalOpen(true);
  };

  const handletoggle = () => {
    if (toggle2) {
      setToggle(!toggle);
    }
  };

  const perMission = useSelector((state) => state.auth.access);
  const isAdmin = useSelector((state) => state.auth.isAdmin);

  const appAbility = useMemo(
    () => createAppAbility(isAdmin, perMission),
    [isAdmin, perMission]
  );

  
  // Code to get the current screen size and change the toggle of the sidebar
  const [windowSize, setWindowSize] = useState(getWindowSize());

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  function getWindowSize() {
    const { innerWidth, innerHeight } = window;
    return { innerWidth, innerHeight };
  }

  useEffect(() => {
    if (windowSize.innerWidth < 963) {
      setToggle(false);
      setToggle2(true);
    } else if (windowSize.innerWidth > 963) {
      setToggle(true);
      setToggle2(false);
    }
  }, [windowSize.innerWidth]);
  return (
    <div>
      <div>
        <SidebarContext.Provider
          value={{
            toggleSidebar,
            setToggleSidebar,
          }}
        >
       
            <ModalContext.Provider
              value={{
                handleModalData,
                closeModal,
                setData,
                data,
                isModalOpen,
                modalData,
              }}
            >
                 <AbilityContext.Provider value={appAbility}>
              <div className="d-flex">
                <div className={toggle ? "side-nav" : "side-nav1"}>
                  <Sidebar toggle={toggle} handletoggle={handletoggle} />
                </div>
                <div className="page-container">
                  <Header toggle={toggle} handletoggle={handletoggle} />
                  <div className="mainWrapper pt-5">
                    <MainWrapper />
                  </div>
                </div>
              </div>
              </AbilityContext.Provider>
            </ModalContext.Provider>
         
        </SidebarContext.Provider>
        
        <CommonModal
          handleModalData={handleModalData}
          isModalOpen={isModalOpen}
          modalData={modalData}
          modalSize={modalSize}
          closeModal={closeModal}
        />
      </div>
    </div>
  );
}
