import React from 'react'

function FormHead({ title }) {
    return (

        <div className='subtitle'>

            {
                <h5>{title}</h5>
            }
        </div>
    )
}

export default FormHead