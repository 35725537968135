import React, { useContext, useEffect, useMemo, useState } from 'react';
import FormControl from "components/controls/FormControl";
import CustomTable from "components/Table"
import Buttond from "components/buttons/ADD";
import EditDelete from "components/buttons/EditDelete";
import { ModalContext } from 'utils/Contexts/modalContext';
import CreateUser from 'components/modals/CreateUser';
import EditUser from 'components/modals/EditUser';
import CoomonDelete from 'components/modals/CoomonDelete';
import ViewUserData from 'components/modals/ViewUserData'
import Verification from 'assets/img/dashboards/check_circle.svg';
import error from 'assets/img/dashboards/error.svg';
import Loader from 'components/loader';
import { DELETEDOCKYARDAPI, VIEWUSEREAPI, VIEWCREATECHANNELPARTNERAPI } from 'utils/Services';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';


function Users() {
    const modalContext = useContext(ModalContext);
    const { closeModal, handleModalData } = modalContext;
    const [loading, setLoading] = useState(false)
    const [allData, setallData] = useState([])
    const [options, setOptions] = useState([])
    const [filteredData, setFilteredData] = useState([]);
    const [filterStatus, setFilterStatus] = useState('');
    const [showDropdown, setShowDropdown] = useState(false);
    const navigate = useNavigate();
    const handlechangebtn = () => {
        setShowDropdown(!showDropdown);
    }
    const handleFilterChange = (e) => {
        setFilterStatus(e.target.value);
        setShowDropdown(false);
    };


    const handleShowDetails = (id) => {
        navigate(`/user_information`)
    }

    // const handleShowDetails = (id) => {
    //     navigate(`/user_information/${id}`)
    // }
    const filteredDatas = allData.filter((item) => {
        if (!filterStatus || filterStatus === "All") return true; // Show all data
        return item.customerRideStatus === filterStatus; // Filter by status
    });
    const FeedsColumns = useMemo(
        () => [
            {
                Header: "Sno.",
                accessor: (row, index) => {
                    return (
                        <>
                            <span className='text-sm'>{index + 1}</span>
                        </>
                    );
                }
            },
            {
                Header: "User Full Name",
                accessor: (Customer) => Customer?.full_name || ""
            },

            {
                Header: "KYC Verification",
                accessor: (row, index) => {


                    return (
                        <div>
                            {row?.is_drivingLicense && row?.is_identificationCard && row?.is_aadhaarCard ? (
                                <div className='d-flex align-items-center'>
                                    <div className='sidenavimg mx-2'>
                                        <img src={Verification} />
                                    </div>
                                    <span className='Verified'>
                                        Verified
                                    </span>
                                </div>
                            ) : row?.is_identificationCard && row?.is_aadhaarCard ? (
                                <div className='d-flex align-items-center'>
                                    <div className='sidenavimg mx-2'>
                                        <img src={error} />
                                    </div>
                                    <span className='pending' >
                                        Partially Verified
                                    </span>
                                </div>
                            ) : (
                                <div className='d-flex align-items-center'>
                                    <div className='sidenavimg mx-2'>
                                        <img src={error} />
                                    </div>
                                    <span className='pending'>
                                        Pending
                                    </span>
                                </div>
                            )}
                        </div>

                    );
                }


            },

            {
                id: "Current Status",
                Header: () => (
                    <div
                        className="user-status-container"
                        style={{ position: 'relative' }}
                        onClick={handlechangebtn}
                    >
                        <div className="d-flex align-items-center" style={{ cursor: 'pointer' }}>
                            <h6 className="pt-2">Current Status</h6>
                            <span
                                className="arrow-icon ml-2"

                            >
                                ▼
                            </span>
                        </div>
                        {showDropdown && (
                            <div
                                className="dropdown-container"
                                style={{
                                    position: 'absolute',
                                    top: '100%',
                                    left: 0,
                                    zIndex: 1,
                                    background: 'white',
                                    borderRadius: '4px'
                                }}
                            >
                                <select
                                    className="form-control"
                                    value={filterStatus}
                                    onChange={handleFilterChange}
                                >
                                    <option value="All">All</option>
                                    <option value="Active">Active</option>
                                    <option value="Inactive">Inactive</option>
                                    <option value="Ideal">Ideal</option>
                                    <option value="Disable">Disable</option>
                                </select>
                            </div>
                        )}
                    </div>
                ),
                accessor: "customerRideStatus",
                Cell: ({ row }) => {
                    const status = row.original.customerRideStatus;
                    let tooltipTitle;
                    let textColor;

                    if (status === "Active") {
                        tooltipTitle = "Running";
                        textColor = "green";
                    } else if (status === "Inactive") {
                        tooltipTitle = "Not Running";
                        textColor = "red";
                    } else if (status === "Ideal") {
                        tooltipTitle = "Only security amount paid";
                        textColor = "#D5A254";
                    } else if (status === "Disable") {
                        tooltipTitle = "Rider had handed over the vehicle";
                        textColor = "black";
                    } else {
                        tooltipTitle = "No status available";
                        textColor = "gray";
                    }

                    return (
                        <Tooltip title={tooltipTitle}>
                            <div style={{ cursor: 'pointer', color: textColor }}>
                                {status || "No Status"}
                            </div>
                        </Tooltip>
                    );
                }
            },

            {
                Header: "Associated With",
                accessor: (Customer) => {
                    if (Customer?.channel_partner == 1) {
                        return "Other";
                    } else if (Customer?.channel_partner == 2) {
                        return "Zeway Rider";
                    } else {
                        return "Channel Partner";
                    }
                }
            },

            {
                Header: "Channel Partner Name",
                accessor: (Customer) => {

                    if ((Customer?.channel_partner != 1) && (Customer?.channel_partner != 2)) {

                        const filte_data = options?.filter((res) => res?.id == Customer?.channel_partner);

                        return filte_data[0]?.label;
                    } else {
                        return "NA";
                    }
                }

            },
            {
                Header: "Contact Number",
                accessor: (Customer) => Customer?.contact_no || ""
            },

            {
                Header: "Email ID",
                accessor: (Customer) => Customer?.email || ""
            },
            {
                Header: "Pincode",
                accessor: (Customer) => Customer?.pincode || ""
            },
            {
                Header: "User Request",
                accessor: (row, index) => {
                    return (
                        <div className='d-flex justify-content-between '>
                            <div className='w-100 mx-2' onClick={() => handle("view", row?.id)}>
                                <EditDelete text="Request" type="Button" />
                            </div>
                        </div>
                    );
                }
            },


            // {
            //     Header: "Payment Due Date",
            //     accessor: (Customer) => Customer?.status || "2024-08-12" 
            // },

            {
                Header: "Actions",
                accessor: (row, index) => {
                    return (
                        <div className='d-flex justify-content-between '>

                            <div className='w-100 mx-2' onClick={() => handleDetails(row?.id)}>
                                <EditDelete text="View" type="Button" />
                            </div>
                            <div className='w-100' onClick={() => handle("edit", row?.id)}>
                                <EditDelete text="Edit" type="Button" />
                            </div>
                        </div>
                    );
                }
            },
        ],

        [options?.length > 0, showDropdown]
    );
    const fecthData = async () => {
        try {
            setLoading(true)
            const result = await VIEWUSEREAPI();
            if (result?.res?.status == 200) {

                var Activearr = []
                var Inactivearr = []
                var idealarr = []
                var Disablearr = []
                var remainingitem = []



                result?.res?.data.map(item => {
                    if (item.customerRideStatus === "Active") {
                        Activearr.push(item)
                    } else if (item.customerRideStatus === "Inactive") {
                        Inactivearr.push(item)
                    } else if (item.customerRideStatus === "Ideal") {
                        idealarr.push(item)
                    } else if (item.customerRideStatus === "Disable") {
                        Disablearr.push(item)
                    } else {
                        remainingitem.push(item)
                    }
                })

                var finalArr = [...Activearr, ...idealarr, ...Inactivearr, ...Disablearr, ...remainingitem]


                setallData(finalArr)
                filterData(finalArr, filterStatus)
                setLoading(false)
            }
            else {
                setLoading(false)
            }

        }
        catch (err) {
            console.log(err)
            setLoading(false)
        }

    }

    const filterData = (data, status) => {
        let filtered = data;
        if (status === "Active") {
            filtered = data.filter((user) => user?.is_identificationCard && user?.is_aadhaarCard);
        } else if (status === "Inactive") {
            filtered = data.filter((user) => !(user?.is_identificationCard && user?.is_aadhaarCard));
        }
        setFilteredData(filtered);
    };
    useEffect(() => {
        setFilteredData(allData);
    }, [allData]);
    useEffect(() => {
        const filterData = () => {
            if (!filterStatus) {
                setFilteredData(allData);
            } else {
                const filtered = allData.filter((user) => {
                    const isActive = user?.is_identificationCard && user?.is_aadhaarCard;
                    return filterStatus === "Active" ? isActive : !isActive;
                });
                setFilteredData(filtered);
            }
        };
        filterData();
    }, [filterStatus, allData]);


    useEffect(() => {
        if (options && options.length > 0) {
            fecthData()

        }

    }, [options]);
    useEffect(() => {

        fetchChannel()
    }, []);

    useEffect(() => {
        filterData(allData, filterStatus);
    }, [filterStatus, allData]);

    const handleStatusChange = (e) => {
        setFilterStatus(e.target.value);
    };

    const handleDelete = async (data) => {
        setLoading(true)
        try {
            const result = await DELETEDOCKYARDAPI(data);

            if (result?.res?.status == 200) {
                setLoading(false)
                toast.success(result?.res?.message)
                fecthData(); closeModal();
            }
        }
        catch (err) {
            toast.error(err?.message || err)
            setLoading(false)
            closeModal();
        }


    }
    const fetchChannel = async () => {
        setLoading(true);
        try {
            const result = await VIEWCREATECHANNELPARTNERAPI();

            if (result?.res?.status === 200) {
                const data = result?.res?.data?.map((res) => ({
                    id: res?.id,
                    label: res?.channel_partner_name,
                    value: res?.channel_partner_name
                }));

                setOptions(data);
                setLoading(false);
            } else {
                setLoading(false);
            }
        } catch (err) {
            console.log(err);
            setLoading(false);
        }
    }

    const handle = (data, info) => {
        const CreateDocumnet = <CreateUser closeModal={closeModal} reload={fecthData} />;
        const EditDocumnet = <EditUser closeModal={closeModal} data={info} reload={fecthData} />;
        const DelteDocumnet = <CoomonDelete closeModal={closeModal} data={info} handle={handleDelete} reload={fecthData} />
        const ViewData = <ViewUserData closeModal={closeModal} data={info} reload={fecthData} />
        switch (data) {
            case "create":
                handleModalData(CreateDocumnet, "md");
                break;
            case "edit":
                handleModalData(EditDocumnet, "md");
                break;
            case "delete":
                handleModalData(DelteDocumnet, "md");
                break;
            case "view":
                handleModalData(ViewData, 'md')
        }

    }

    
    const handleDetails = (id) => {

        navigate(`/rider_details/${id}`)
    }

    return (
        <>


            <div className="container-fluid pb-5">
                <div className='row'>
                    <Loader loading={loading} />
                    <div className='d-flex justify-content-end my-3'>

                        <div onClick={() => handle("create")}>
                            <Buttond text="Create Users" />
                        </div>
                    </div>

                    {filteredData.length > 0 && (
                        <div className='default_table'>
                            <CustomTable data={filteredDatas} columns={FeedsColumns} />
                        </div>
                    )}
                </div>
            </div>
        </>
    )
}

export default Users