import React, { useEffect, useState } from 'react';
import { Formik, Form, FieldArray } from 'formik';  // Import Formik and Form
import FormControl from 'components/controls/FormControl';
import Loader from "components/loader"
import Button from 'components/buttons/ADD';
import ADD from "assets/img/dashboards/add_circle.svg";
import Delete from "assets/img/dashboards/delete.svg"
import { GETSTATEAPI, GETCITYAPI, VIEWCREATECHANNELPARTNERAPI, CREATEBRANCHAPI } from 'utils/Services';
import * as Yup from 'yup';
import { toast } from 'react-toastify';



function CreateBranch({ closeModal, reload }) {
    const [options, setOptions] = useState({
        state: [], city: [], status: [{
            id: true,
            label: 'Active',
            value: true
        }, {
            id: false,
            label: 'InActive',
            value: false
        }]
    });
    const linksGroup = [

        { label: 'Contact Person ', type: 'text', name: 'title' },
        { label: 'Contact Number', type: 'text', name: 'quantity' },
    ]
    const [loading, setLoading] = useState(false)
    const [selectState, setSelectState] = useState()
    const fetchState = async () => {

        try {
            setLoading(true)
            const result = await GETSTATEAPI();
            if (result?.res?.status == 200) {
                const data = result?.res?.data?.map((res) => ({
                    id: res?.id,
                    label: res?.name,
                    value: res?.name
                }));

                setOptions((res) =>
                ({
                    ...res,
                    state: data
                }))
                setLoading(false)
            }
            else {
                setLoading(false)
            }
        }
        catch (err) {
            console.log(err);
            setLoading(false)

        }



    }
    const fetchCity = async () => {


        if (selectState !== '' && selectState !== null && selectState !== undefined) {

            const data = {
                "state_id": selectState
            };

            try {
                setLoading(true);
                const result = await GETCITYAPI(data);

                if (result?.status === 200) {
                    const data = result?.data?.map((res) => ({
                        id: res?.id,
                        label: res?.name,
                        value: res?.name
                    }));

                    setOptions((res) => ({
                        ...res,
                        city: data
                    }));
                    setLoading(false);
                } else {
                    setLoading(false);
                }
            } catch (err) {
                console.log(err);
                setLoading(false);
            }
        }
        else {
            setOptions((res) => ({
                ...res,
                city: []
            }));
        }


    }


    const fetchData = async () => {
        try {
            setLoading(true)
            const result = await VIEWCREATECHANNELPARTNERAPI();
            if (result?.res?.status == 200) {
                const data = result?.res?.data?.map((res) => ({
                    id: res?.id,
                    label: res?.channel_partner_name, // Assuming channel_partner_name is a property of the 'res' object
                    value: res?.channel_partner_name,
                }));

                setLoading(false)
                setOptions((res) =>
                ({
                    ...res,
                    "partners": data
                }))
            }
        }
        catch (err) {
            setLoading(false);
            toast.error(err)

        }




    }


    const validationSchema = Yup.object().shape({
        formData: Yup.object().shape({
            channelId: Yup.string().required('Channel Partner is required'),
            stateId: Yup.string().required('State is required'),
            cityId: Yup.string().required('City is required'),
            address1: Yup.string().required('Address1 is required'),
            zip_code: Yup.string()
                .matches(/^\d{6}$/, 'Zip Code must be exactly 6 digits')
                .required('Zip Code is required'),
            is_active: Yup.string().required('Status is required'),
            contactPerson: Yup.array().of(
                Yup.object().shape({
                    contact_name: Yup.string().required('Contact Name is required'),
                    contact_no: Yup.string()
                    .required('Contact number is required.')
                    .matches(/^\d{10}$/, 'Contact number must be exactly 10 digits.'),
                })
            ).required('At least one contact person is required'),
            gst_no: Yup.string()
                .matches(
                    /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}[Z]{1}[0-9A-Z]{1}$/,
                    'Invalid GST Number'
                )
                .required('GST Number is required')
        }),
    });
    const handle = async (values) => {
        setLoading(true)
        try {
            const { contactPerson, label, ...formDataWithoutcontactPerson } = values.formData;
            const data =
            {
                branchLocation: formDataWithoutcontactPerson, contactPerson: values.formData.contactPerson
            }
            const result = await CREATEBRANCHAPI(data);
            if (result.status == 201) {
                toast.success(result?.message)
                setLoading(false)
                { reload && reload() }
                closeModal()
            }
            else {
                toast.error(result?.message)
                closeModal()
            }

        }
        catch (err) {
            setLoading(false)
            toast.error(err?.message)
        }
    }
    useEffect(() => {
        fetchState();
        fetchData()
    }, [])
    useEffect(() => {
        fetchCity()
    }, [selectState])
    return (
        <div className='row'>
            <Loader loading={loading} />
            <div className='com_modal'>
                {/* Use Formik wrapper */}
                <Formik
                    initialValues={{
                        formData: {
                            "stateId": "",
                            'channelId': "",
                            "cityId": "",
                            "address1": "",
                            "address2": "",
                            "zip_code": "",
                            "is_active": '',
                            "gst_no": '',
                            'contactPerson': [{ contact_name: '', contact_no: '' }]

                        },
                    }}
                    validationSchema={validationSchema}
                    onSubmit={handle}

                >
                    {({ values, handleSubmit, setFieldValue }) => (
                        // Wrap your content in the Form component
                        <Form className='form_default text_left'>
                            <div className='mb-3'>
                                <FormControl control="FormHead" title="Create New Branch Location" />
                            </div>
                            <div className='mb-3'>
                                <div className='row g-3'>
                                    <div className='col-lg-6'>
                                        <FormControl
                                            control="SelectBox"
                                            name={"formData.is_active"}
                                            label={"New Branch Status"}
                                            options={options.status}

                                            required={true}


                                        />
                                    </div>
                                    <div className='col-lg-6'>
                                        <FormControl
                                            control="SelectBox"
                                            name={"formData.channelId"}
                                            label={" Channel Partner"}
                                            options={options.partners}
                                            required={true}

                                        />
                                    </div>


                                    <div className='col-lg-6'>
                                        <FormControl
                                            control="InputLabel"
                                            name={"formData.label"}
                                            type={"hidden"}
                                            label={"Contacts"}

                                        />
                                    </div>
                                    <div className='col-lg-12'>

                                        <FieldArray name="formData.contactPerson">
                                            {({ push, remove }) => (
                                                <div>
                                                    <div className='d-flex justify-content-end align-items-center'>
                                                        <div
                                                            className="icon_dash"
                                                            onClick={() => {
                                                                push({ contact_name: '', contact_no: '' }); // push an empty object when adding new contactPerson
                                                            }}
                                                        >
                                                            <img src={ADD} alt="Add" />
                                                        </div>
                                                    </div>
                                                    {values?.formData?.contactPerson?.map((contactPerson, index) => (
                                                        <div key={index} className="d-flex justify-content-between align-items-center mb-2">
                                                            <div className='col-lg-5'>
                                                                <FormControl
                                                                    control="InputLabel"
                                                                    label="Name"
                                                                    type="text"
                                                                    name={`formData.contactPerson.${index}.contact_name`}
                                                                    required={true}

                                                                />
                                                            </div>
                                                            <div className='col-lg-5'>
                                                                <FormControl
                                                                    control="InputLabel"
                                                                    label="No"
                                                                    type="text"
                                                                    required={true}

                                                                    name={`formData.contactPerson.${index}.contact_no`}
                                                                />
                                                            </div>

                                                            {
                                                                index > 0 && <div
                                                                    className="icon_dash cursor-pointer"
                                                                    onClick={() => remove(index)}
                                                                >
                                                                    <img src={Delete} alt="Delete" />
                                                                </div>
                                                            }

                                                            {
                                                                index == 0 &&
                                                                <div

                                                                >

                                                                </div>
                                                            }

                                                        </div>
                                                    ))}
                                                </div>
                                            )}
                                        </FieldArray>
                                    </div>
                                    <div className='col-lg-6'>
                                        <FormControl
                                            control="InputLabel"
                                            name={"formData.label"}
                                            type={"hidden"}
                                            label={"Billing Address"}

                                        />
                                    </div>
                                    <div className='col-lg-6'>

                                    </div>
                                    <div className='col-lg-6'>
                                        <FormControl
                                            control="InputLabel"
                                            name={"formData.address1"}
                                            type={"text"}
                                            label={"Address Line 1"}
                                            required={true}

                                        />
                                    </div>      <div className='col-lg-6'>
                                        <FormControl
                                            control="InputLabel"
                                            name={"formData.address2"}
                                            type={"text"}
                                            label={"Address Line 2"}
                                        />
                                    </div>


                                    <div className='col-lg-6'>
                                        <FormControl
                                            control="SelectBox"
                                            name={"formData.stateId"}
                                            options={options.state}
                                            label={"State"}
                                            setfield={setSelectState}
                                            required={true}

                                        />
                                    </div>
                                    <div className='col-lg-6'>
                                        <FormControl
                                            control="SelectBox"
                                            name={"formData.cityId"}
                                            options={options.city}
                                            label={"City"}
                                            required={true}

                                        />
                                    </div>
                                    <div className='col-lg-6'>
                                        <FormControl
                                            control="InputLabel"
                                            name={"formData.zip_code"}
                                            type={"number"}
                                            label={"Postal/Zip Code"}
                                            required={true}

                                        />
                                    </div>

                                    <div className='col-lg-6'>
                                        <FormControl
                                            control="InputLabel"
                                            name={"formData.gst_no"}
                                            type={"text"}
                                            label={"GST No."}
                                            required={true}

                                        />
                                    </div>
                                </div>

                            </div>

                            <div className='d-flex justify-content-end'>
                                <div className='col-lg-2 mx-5' onClick={closeModal}>
                                    <Button text="Cancel" type="button" isCancel={true} />
                                </div>

                                <div className='col-lg-2'>
                                    <Button text="Save" type="submit" />
                                </div>

                            </div>

                            <div>
                                <p>
                                    all asterisk (*) field are required
                                </p>
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
        </div >
    );
}

export default CreateBranch;
