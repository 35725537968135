import React, { useEffect, useState } from 'react';
import { Formik, Form } from 'formik';
import FormControl from 'components/controls/FormControl';
import Loader from "components/loader";
import Button from 'components/buttons/ADD';
import { toast } from 'react-toastify';
import * as Yup from 'yup';

function Identification({ nextStep, setDetails, closeModal, details,prevStep }) {
  const [loading, setLoading] = useState(false);

  const validationSchema = Yup.object().shape({
    formData: Yup.object().shape({
      motorID: Yup.string().required('Motor Number/ID is required'),
      charger_no: Yup.string().nullable(),
      controller_no: Yup.string().nullable(),
      lock_status: Yup.string().nullable(),
      fleet_no: Yup.string().nullable(),
      availability_status: Yup.string().nullable(),
    }),
  });

  const handleFormSubmit = async (values) => {
    setLoading(true);
    try {
      console.log(details,"details");
      const data = { ...values.formData };
      setDetails((prevDetails) => ({
        ...prevDetails,
        step1: data,
      }));
      nextStep();
    } catch (error) {
      toast.error(error.message);
    } finally {
      setLoading(false);
    }
  };
  
  return (
    <div className='row'>
      <Loader loading={loading} />
      <div className='com_modal'>
        <Formik
          initialValues={{
            formData: {
              motorID: details?.motorID || '',
              charger_no: details?.charger_no || '',
              controller_no: details?.controller_no || '',
              fleet_no: details?.fleet_no || '',
              lock_status: details?.lock_status?.toString() || '',
              availability_status: details?.availability_status?.toString() || '',
            },
          }}
          validationSchema={validationSchema}
          onSubmit={handleFormSubmit}
        >
          {(formik) => (
            <Form className='form_default text_left'>
              <div className='mb-3'>
                <div className='row g-3'>
                  <div className='col-lg-6'>
                    <FormControl
                      control="InputLabel"
                      name="formData.motorID"
                      type="text"
                      label="Motor Number/ID"
                      required={true}

                    />
                  </div>
                  <div className='col-lg-6'>
                    <FormControl
                      control="InputLabel"
                      name="formData.charger_no"
                      type="text"
                      label="Charger Number"
                  
                    />
                  </div>
                  <div className='col-lg-6'>
                    <FormControl
                      control="InputLabel"
                      name="formData.controller_no"
                      type="text"
                      label="Controller Number/ID"

                    />
                  </div>
                  {/* <div className='col-lg-6'>
                    <FormControl
                      control="InputLabel"
                      name="formData.fleet_no"
                      type="text"
                      label="Fleet No"
                   

                    />
                  </div> */}
                  <div className='col-lg-12'>  
                  <FormControl
                    control="InputLabel"
                    name="formData.LABEL"
                    type="hidden"
                    label="Lock Status"
                    value="locked"
                  /></div>
                  <div className='col-lg-6'>
                    <FormControl
                      control="Radio"
                      name="formData.lock_status"
                      label="Locked"
                      value="locked"
                    />
                  </div>
                  <div className='col-lg-6'>
                    <FormControl
                      control="Radio"
                      name="formData.lock_status"
                      label="Unlocked"
                      value="unlocked"
                    />
                  </div>
                  <div className='col-lg-12'>  
                  <FormControl
                    control="InputLabel"
                    name="formData.LABEL"
                    type="hidden"
                    label="Availability Status"
                    value="locked"
                  /></div>
                  <div className='col-lg-6'>
                    <FormControl
                      control="Radio"
                      name="formData.availability_status"
                      label="Available"
                      value="available"
                    />
                  </div>
                  <div className='col-lg-6'>
                    <FormControl
                      control="Radio"
                      name="formData.availability_status"
                      label="Unavailable"
                      value="unavailable"
                    />
                  </div>
                </div>
              </div>
              <div className='d-flex justify-content-between'>
                                <div className='col-lg-2' onClick={prevStep}>
                                    <Button text="Back" type="button" isCancel={true} />
                                </div>
                                <div className='col-lg-9 d-flex justify-content-end '>
                                    <div className='col-lg-2 mx-5' onClick={closeModal}>
                                        <Button text="Cancel" type="button" isCancel={true} />
                                    </div>

                                    <div className='col-lg-2'>
                                        <Button text="Next" type="submit" />
                                    </div>
                                </div>


                            </div>
                            <div>
              <p>
                all asterisk (*) field are required 
              </p>
            </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
}

export default Identification;
