import React, { useEffect, useState } from 'react';
import { Formik, Form } from 'formik';  // Import Formik and Form
import FormControl from 'components/controls/FormControl';
import Loader from "components/loader"
import Button from 'components/buttons/ADD';
import { GETSTATEAPI, GETCITYAPI, CREATECHANNELPARTNERAPI } from 'utils/Services';
import * as Yup from 'yup';
import { toast } from 'react-toastify';



function BatteryDetails({ nextStep, setDetails, closeModal, details,prevStep }) {

    const [loading, setLoading] = useState(false)
    const [selectState, setSelectState] = useState()

    const validationSchema = Yup.object().shape({
        formData: Yup.object().shape({
            batteryID: Yup.string().nullable(),
            battery_make: Yup.string().nullable(),
            charge_state: Yup.string().nullable(),
            Charge_percentage: Yup.number()
             
                .min(0, 'Charge Percentage must be at least 0')
                .max(100, 'Charge Percentage cannot exceed 100').nullable(),
            voltage_level: Yup.number()
               
                .min(1, 'Voltage Level must be at least 1').nullable()
        }),
    });
    const handle = async (values) => {
        
        setLoading(true)
        try {
            const data = { ...values.formData };
            setDetails((pre) =>
            ({
                ...pre,
                step2: data,

            }))
            nextStep()
        }
        catch (err) {
            setLoading(false)
            toast.error(err?.message)
        }
    }
 
    return (
        <div className='row'>
            <Loader loading={loading} />
            <div className='com_modal'>
                {/* Use Formik wrapper */}
                <Formik

                    validationSchema={validationSchema}
                    onSubmit={handle}
                    enableReinitialize={true}
                    initialValues={{
                        formData: {
                            batteryID: details?.batteryID,
                            battery_make: details?.battery_make,
                            charge_state: details?.charge_state,
                            Charge_percentage: details?.Charge_percentage,
                            voltage_level: details?.voltage_level,


                        },
                    }}

                >
                    {(formik) => (
                        // Wrap your content in the Form component
                        <Form className='form_default text_left'>

                            <div className='mb-3'>
                                <div className='row g-3'>

                                    <div className='col-lg-6'>
                                        <FormControl
                                            control="InputLabel"
                                            name={"formData.batteryID"}
                                            type={"text"}
                                            label={"Battery Number/ ID"}
                                           
                                        />
                                    </div>
{/* 
                                    <div className='col-lg-6'>
                                        <FormControl
                                            control="InputLabel"
                                            name={"formData.battery_make"}
                                            type={"text"}
                                            label={"Battery Make"}
                                            required={true}
                                        />
                                    </div> */}
                                    <div className='col-lg-6'>
                                        <FormControl
                                            control="InputLabel"
                                            name={"formData.charge_state"}
                                            type={"text"}
                                            label={"State of Charge (SOC)"}
                                          
                                        />
                                    </div>
                                    <div className='col-lg-6'>
                                                <FormControl
                                                    control="InputLabel"
                                                    name={"formData.geo_fencingID"}
                                                    type={"text"}
                                                    label={"Geo-fencing ID"}
                                                    disabled={true}
                                                  
                                                />
                                            </div>
                                    <div className='col-lg-6'>
                                        <FormControl
                                            control="InputLabel"
                                            name={"formData.Charge_percentage"}
                                            type={"number"}
                                            label={"Charge Percentage"}
                                            
                                        />
                                    </div>
                                    <div className='col-lg-6'>
                                        <FormControl
                                            control="InputLabel"
                                            name={"formData.voltage_level"}
                                            type={"number"}
                                            label={"Voltage Level"}
                                         
                                        />
                                    </div>
                                    <div className='col-lg-6'>
                                        <FormControl
                                            control="InputLabel"
                                            name={"formData.label"}
                                            type={"hidden"}
                                            label={"Notification Preferences"}
                                        />
                                        <p>
                                            Select who should receive the  Notifications
                                        </p>
                                    </div>
                                    <div className='col-lg-6'>

                                    </div>
                                    <div className=''>
                                        <FormControl
                                            control="Checkbox"
                                            name={"formData.Admin"}
                                            label={"Admin"}

                                        />
                                        <FormControl
                                            control="Checkbox"
                                            name={"formData.Super"}
                                            label={"Super Admin"}
                                        />
                                        <FormControl
                                            control="Checkbox"
                                            name={"formData.Riders"}
                                            label={"Riders"}
                                        />
                                    </div>
                                </div>

                            </div>

                            <div className='d-flex justify-content-between'>
                                <div className='col-lg-2' onClick={prevStep}>
                                    <Button text="Back" type="button" isCancel={true} />
                                </div>
                                <div className='col-lg-9 d-flex justify-content-end '>
                                    <div className='col-lg-2 mx-5' onClick={closeModal}>
                                        <Button text="Cancel" type="button" isCancel={true} />
                                    </div>

                                    <div className='col-lg-2'>
                                        <Button text="Next" type="submit" />
                                    </div>
                                </div>


                            </div>
                            <div>
              <p>
                all asterisk (*) field are required 
              </p>
            </div>

                        </Form>
                    )}
                </Formik>
            </div>
        </div >
    );
}

export default BatteryDetails;
