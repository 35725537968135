import React, { useContext, useEffect, useMemo, useState } from 'react';
import FormControl from "components/controls/FormControl";
import CustomTable from "components/Table"
import Button from "components/buttons/ADD";
import EditDelete from "components/buttons/EditDelete";
import { ModalContext } from 'utils/Contexts/modalContext';
import CreatChannelPartner from 'components/modals/CreatChannelPartner';
import EditChannelPartner from 'components/modals/EditChannelPartner';
import CoomonDelete from 'components/modals/CoomonDelete';
import Loader from 'components/loader';
import FallBack from "components/fallBack"
import { USERViEWAPI, VIEWCREATECHANNELPARTNERAPI, GETSTATEAPI, GETCITYAPI, USERViEWAPIV2 } from 'utils/Services';
import { toast } from 'react-toastify';


function Genral(data) {
    const modalContext = useContext(ModalContext);
    const { closeModal, handleModalData } = modalContext;
    const [loading, setLoading] = useState(false)
    const [allData, setallData] = useState([])
    const [options, setOptions] = useState([])
    const fecthData = async () => {
        try {
            setLoading(true)
            const result = await USERViEWAPIV2(data.data);
            if (result?.res?.status == 200) {
                setallData(result?.res?.data)
                setLoading(false)
            }
            else {
                setLoading(false)
            }

        }
        catch (err) {
            console.log(err)
            setLoading(false)
        }

    }
    useEffect(() => {
        fecthData()
    }, [data]);
    const fetchChannel = async () => {
        setLoading(true);
        try {
            
            const result = await VIEWCREATECHANNELPARTNERAPI();

            if (result?.res?.status === 200) {
                const data = result?.res?.data?.map((res) => ({
                    id: res?.id,
                    label: res?.channel_partner_name,
                    value: res?.channel_partner_name
                }));

                setOptions((res) => ({
                    ...res,
                    channel: data
                }));
                setLoading(false);
            } else {
                setLoading(false);
            }
        } catch (err) {
            console.log(err);
            setLoading(false);
        }
    }
    const fetchState = async () => {
        setLoading(true)
        try {
            const result = await GETSTATEAPI();
            if (result?.res?.status == 200) {
                const data = result?.res?.data?.map((res) => ({
                    id: res?.id,
                    label: res?.name,
                    value: res?.name
                }));

                setOptions((res) =>
                ({
                    ...res,
                    state: data
                }))
                setLoading(false)
            }
            else {
                setLoading(false)
            }
        }
        catch (err) {
            console.log(err);
            setLoading(false)

        }



    }
    const fetchCity = async () => {

        if (allData?.state !== '' && allData?.state !== null && allData?.state !== undefined) {
            const data = {
                "state_id": allData?.state
            };
            setOptions((res) => ({
                ...res,
                city: []
            }));
            try {
                setLoading(true);
                const result = await GETCITYAPI(data);

                if (result?.status === 200) {
                    const data = result?.data?.map((res) => ({
                        id: res?.id,
                        label: res?.name,
                        value: res?.name
                    }));

                    setOptions((res) => ({
                        ...res,
                        city: data
                    }));
                    setLoading(false);
                } else {
                    setLoading(false);
                }
            } catch (err) {
                console.log(err);
                setLoading(false);
            }
        }


    }
    useEffect(() => {
        fetchState()
    }, [])
    useEffect(() => {

        fetchChannel()
    }, []);
    useEffect(() => {
        fetchCity()
    }, [allData?.state])

    return (
        <div className="container-fluid pb-5">
            <div className='row'>
                <Loader loading={loading} />
                {options && options.channel?.length > 0 && (
                    <div className='my-5'>

                        {Object.keys(allData).map((key) => {
                            return (
                                <div className="details_tile" key={key}>
                                    <div className="d-flex justify-content-between">
                                        <div className="col-lg-6 text-center">
                                            {key}
                                        </div>
                                        <div className="col-lg-6 text-center">
                                            {allData[key]}
                                        </div>
                                    </div>
                                </div>
                            );
                        })}

                    </div>
                )}






            </div>
        </div>
    )
}

export default Genral