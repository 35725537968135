import React, { useContext, useEffect, useMemo, useState } from 'react';
import FormControl from "components/controls/FormControl";
import Icon from "assets/img/dashboards/add_circle.svg";
import ButtonIcon from "components/buttons/ButtonIcon";
import EditDelete from "components/buttons/EditDelete";
import { ModalContext } from 'utils/Contexts/modalContext';
import PerUse from 'components/modals/PerUse';
import EditPayPer from 'components/modals/EditPayPer';
import Subscription from 'components/modals/Subscription';
import EditSubscription from 'components/modals/EditSubscription'
import Loader from 'components/loader';
import { VIEWDOCKYARDAPI, PLANDELETEAPI, PLANDETAILSVIEWAPI } from 'utils/Services';
import FallBack from "components/fallBack"
import CustomTileTable from "components/controls/CustomTileTable"
import { toast } from 'react-toastify';
import CoomonDelete from 'components/modals/CoomonDelete';


function Plans() {
    const modalContext = useContext(ModalContext);
    const { closeModal, handleModalData } = modalContext;
    const [loading, setLoading] = useState(false)
    const [allData, setallData] = useState([])
    const [allSecondData, setallSecondData] = useState([])

    const FeedsColumns = useMemo(
        () => [

            {

                accessor: (Customer) => <span className='til_s_black tile'>{Customer?.plan_name}</span> || "" // Use conditional rendering
            },
            {
                accessor: (Customer) => (
                    <span className='tile'>
                        Max Speed : {Customer?.max_speed}Km/h
                    </span>
                ) || "" // Use conditional rendering if needed
            },

            {

                accessor: (Customer) => <span className='tile'>
                    Range: {Customer?.range} Km
                </span> || "" // Use conditional rendering
            },
            {

                accessor: (Customer) => <span className='tile'>
                    Security Deposit : Rs {Customer?.securityDeposit}
                </span> || "" // Use conditional rendering
            },
            {
                accessor: (Customer) => (
                    Customer ? (
                        <span className='tile'>
                            {Customer.amount && `Amount: Rs ${Customer.amount}/min`}
                        </span>
                    ) : (
                        ""
                    )
                )
            },
            

            {

                accessor: (row, index) => {
                    return (
                        <div className='tile '>
                            <div className='col-lg-6 ' onClick={() => handle("edit", row?.id, row?.planType)}>
                                <EditDelete text="Edit" type="Button" />
                            </div>


                        </div>


                    );
                }
            },
        ],
        []
    );
    const fecthData = async () => {
        try {
            setLoading(true)
            const result = await PLANDETAILSVIEWAPI();
            
            if (result?.res?.status == 200) {
                
                const filteredData_1 = result?.res?.data?.filter(item => item.planType == 'Pay Per Use');
                const filteredData_2 = result?.res?.data?.filter(item => item.planType == 'Subscription');
                setallData(filteredData_1);
                setallSecondData(filteredData_2);
                setLoading(false)
            }
            else {
                setLoading(false)
            }

        }
        catch (err) {
            console.log(err)
            setLoading(false)
        }

    }
    useEffect(() => {
        fecthData()
    }, []);

    const handleDelete = async (data) => {
        setLoading(true)
        try {
            const result = await PLANDELETEAPI(data);

            if (result?.res?.status == 200) {
                setLoading(false)
                toast.success(result?.res?.message)
                fecthData(); closeModal();
            }
        }
        catch (err) {
            toast.error(err?.message || err)
            setLoading(false)
            closeModal();
        }


    }
    const handle = (data, info, info2) => {


        const CreateDocumnet = <PerUse closeModal={closeModal} reload={fecthData} />;
        const CreateSubscription = <Subscription closeModal={closeModal} data={info} reload={fecthData} />;
        const DelteDocumnet = <CoomonDelete closeModal={closeModal} data={info} handle={handleDelete} reload={fecthData} />
        const EditDocumnet = <EditPayPer closeModal={closeModal} data={info} handle={handleDelete} reload={fecthData} />
        const EditSubscriptionData = <EditSubscription closeModal={closeModal} data={info} handle={handleDelete} reload={fecthData} />
        switch (data) {
            case "create":
                handleModalData(CreateDocumnet, "lg");
                break;
            case "createsubs":
                handleModalData(CreateSubscription, "md");
                break;
            case "delete":
                handleModalData(DelteDocumnet, "md");
                break;
            case "edit":
                if (info2 == 'Pay Per Use') {
                    handleModalData(EditDocumnet, "md");
                    break;

                }
                else {
                    handleModalData(EditSubscriptionData, "md");
                    break;

                }


        }

    }
    
    return (
        <div className="container-fluid pb-5">
            <div className='row'>
                <Loader loading={loading} />

                <div className='d-flex justify-content-between align-items-center'>
                    <div>
                    <h5>
                        Pay Per Use
                    </h5>
                    </div>
               
                    <div onClick={() => handle("create")} className='my-2'>
                    <ButtonIcon text="Add New Pay Per Use Category" source={Icon}  />

                </div>
                </div>
             {
                    allData.length > 0 ? (<div className='default_table'>
                        <CustomTileTable data={allData} columns={FeedsColumns} />

 
                    </div>) : (<FallBack />)
                }
              
                <div className='d-flex justify-content-between  align-items-center'>
                    <div >
                    <h5>
                        Subscription
                    </h5>
                    </div>
                 

                    <div onClick={() => handle("createsubs")} className='my-3'>
                    <ButtonIcon text="Add New Subscription Category" source={Icon} />

                </div>
                </div>  

                {
                    allSecondData.length > 0 ? (<div className='default_table'>
                        <CustomTileTable data={allSecondData} columns={FeedsColumns} />
    

                    </div>) : (<FallBack />)
                }

           
            </div>
        </div>
    )
}

export default Plans