import React, { useEffect, useState } from 'react';
import { Formik, Form } from 'formik';  // Import Formik and Form
import FormControl from 'components/controls/FormControl';
import Loader from "components/loader"
import Button from 'components/buttons/ADD';
import { GETSTATEAPI, GETCITYAPI, DOCKYARDCREATEAPI, EDITDOCKYARDAPI } from 'utils/Services';
import * as Yup from 'yup';
import { UPDATEDOCKYARDAPI } from 'utils/Services';
import { toast } from 'react-toastify';
import { VIEWCREATECHANNELPARTNERAPI } from 'utils/Services';


function EditDockayard({ closeModal, data, reload }) {
    const [options, setOptions] = useState({ state: [], city: [] });
    const [loading, setLoading] = useState(false)
    const [selectState, setSelectState] = useState();
    const [allData, setAllData] = useState({})
    const fetchState = async () => {
        setLoading(true)
        try {
            const result = await GETSTATEAPI();
            if (result?.res?.status == 200) {
                const data = result?.res?.data?.map((res) => ({
                    id: res?.id,
                    label: res?.name,
                    value: res?.name
                }));

                setOptions((res) =>
                ({
                    ...res,
                    state: data
                }))
                setLoading(false)
            }
            else {
                setLoading(false)
            }
        }
        catch (err) {
            console.log(err);
            setLoading(false)

        }



    }
    const fetchCity = async () => {

        if (selectState !== '' && selectState !== null && selectState !== undefined) {
            const data = {
                "state_id": selectState
            };
            setOptions((res) => ({
                ...res,
                city: []
            }));

            try {
                setLoading(true);
                const result = await GETCITYAPI(data);

                if (result?.status === 200) {
                    const data = result?.data?.map((res) => ({
                        id: res?.id,
                        label: res?.name,
                        value: res?.name
                    }));

                    setOptions((res) => ({
                        ...res,
                        city: data
                    }));
                    setLoading(false);
                } else {
                    setLoading(false);
                }
            } catch (err) {
                console.log(err);
                setLoading(false);
            }
        }
        else {
            setOptions((res) => ({
                ...res,
                city: []
            }));
        }


    }

    const fetchData = async () => {
        try {
            setLoading(true)
            const result = await EDITDOCKYARDAPI(data);
            if (result?.res?.status == 200) {
                
                setAllData(result?.res?.data)
                setSelectState(result?.res?.data?.stateId)
                setLoading(false)
            }
            else {
                setLoading(false)
            }
        }
        catch (err) {
            console.log(err);
            setLoading(false)

        }
    }
    const fetchChannel = async () => {
        setLoading(true);
        try {
            const result = await VIEWCREATECHANNELPARTNERAPI();

            if (result?.res?.status === 200) {
                const data = result?.res?.data?.map((res) => ({
                    id: res?.id,
                    label: res?.channel_partner_name,
                    value: res?.channel_partner_name
                }));

                setOptions((res) => ({
                    ...res,
                    channel: data
                }));
                setLoading(false);
            } else {
                setLoading(false);
            }
        } catch (err) {
            console.log(err);
            setLoading(false);
        }
    }
    useEffect(() => {
        fetchState();

    }, [])
    useEffect(() => {
        fetchChannel();

    }, [])

    useEffect(() => {
        fetchCity()
    }, [selectState]);
    useEffect(() => {
        fetchData();
    }, [])
    

    const validationSchema = Yup.object().shape({
        formData: Yup.object().shape({
            incharge_name: Yup.string().required('InCharge Name is required'),
            incharge_no: Yup.number()
                .required('InCharge Number is required')
                .test('len', 'InCharge Number must be exactly 10 digits', val => val && val.toString().length === 10),
            address1: Yup.string().required('Address1 is required'),
            address2: Yup.string(),
            dockyard_name: Yup.string().required('Dockyard Name is required'),
            cityId: Yup.string().required('City is required'),
            stateId: Yup.string().required('State is required'),
            district: Yup.string().required('District is required'),
            channelId:Yup.string().required('Channel Partner is required'),
            zip_code: Yup.number()
                .required('Zip Code is required')
                .test(
                    'is-six-digits',
                    'Zip Code must be exactly 6 digits',
                    value => /^\d{6}$/.test(String(value))
                ),
            latitude: Yup.number().required('Latitude is required'),
            longitude: Yup.number().required('Longitude is required'),
            is_active: Yup.boolean().required('Is Active status is required'),
        }),
    });
    const handle = async (values) => {
        setLoading(true)
        try {
            const _data = { ...values.formData, "zip_code": parseInt(values.formData.zip_code), "incharge_no": parseInt(values.formData.incharge_no) }
            const result = await UPDATEDOCKYARDAPI(data, _data);

            if (result?.res?.status == 200) {
                toast.success(result?.res?.message)
                setLoading(false)
                closeModal();
                {
                    reload && reload()
                }
            }
            else {
                toast.error(result?.message)
                setLoading(false)
            }

        }
        catch (err) {
            setLoading(false)
            toast.error(err?.message)
        }
    }

    return (
        <div className='row'>
            <Loader loading={loading} />
            <div className='com_modal'>
                {/* Use Formik wrapper */}
            
                <Formik
                    enableReinitialize={true}
                    initialValues={{
                        formData: {
                            "incharge_name": allData?.incharge_name,
                            "incharge_no": allData?.incharge_no,
                            "address1": allData?.address1,
                            "address2": allData?.address2,
                            "dockyard_name": allData?.dockyard_name,
                            "cityId": allData?.cityId,
                            "stateId": allData?.stateId,
                            "district": allData?.district,
                            "zip_code": allData?.zip_code,
                            "latitude": allData?.latitude,
                            "longitude": allData?.longitude,
                            "channelId": allData?.channelId,
                            "is_active": true
                        },
                    }}
                    validationSchema={validationSchema}
                    onSubmit={handle}

                >
                    {(formik) => (
                        <>

                            { allData?.dockyard_name && 
                        <Form className='form_default text_left'>
                                    <div className='mb-3'>
                                        <FormControl control="FormHead" title="Update Dockyard" />
                                    </div>
                                    <div className='mb-3'>
                                        <div className='row g-3'>
                                            <div className='col-lg-6'>
                                                <FormControl
                                                    control="InputLabel"
                                                    name={"formData.dockyard_name"}
                                                    type={"text"}
                                                    label={"Dockyard Name"}
                                                    required={true}

                                                   
                                                />
                                            </div>
                                            <div className='col-lg-6'>
                                                <FormControl
                                                    control="InputLabel"
                                                    name={"formData.incharge_name"}
                                                    type={"text"}
                                                    label={"Person InCharge"}
                                                    required={true}

                                                />
                                            </div>
                                            <div className='col-lg-6'>
                                                <FormControl
                                                    control="InputLabel"
                                                    name={"formData.incharge_no"}
                                                    type={"number"}
                                                    label={"Person InCharge No"}
                                                    required={true}

                                                />
                                            </div>
                                            <div className='col-lg-6'>
                                                <FormControl
                                                    control="SelectBox"
                                                    name={"formData.channelId"}
                                                    options={options.channel}
                                                    label={"Channel Partner"}
                                                    prevalue={formik.values.formData.channelId}
                                                    required={true}


                                                />
                                            </div>

                                            <div className='col-lg-6'>
                                                <FormControl
                                                    control="InputLabel"
                                                    name={"formData.address1"}
                                                    type={"text"}
                                                    label={"Address Line"}
                                                    required={true}

                                                />
                                            </div>

                                            <div className='col-lg-6'>
                                                <FormControl
                                                    control="InputLabel"
                                                    name={"formData.district"}
                                                    type={"text"}
                                                    label={"District"}
                                                    required={true}

                                                />
                                            </div>

                                            <div className='col-lg-6'>
                                                <FormControl
                                                    control="SelectBox"
                                                    name={"formData.stateId"}
                                                    options={options.state}
                                                    setfield={setSelectState}
                                                    label={"State"}
                                                    prevalue={formik.values.formData.stateId}
                                                    required={true}


                                                />
                                            </div>
                                            <div className='col-lg-6'>
                                                <FormControl
                                                    control="SelectBox"
                                                    name={"formData.cityId"}
                                                    options={options.city}
                                                    label={"City"}
                                                    prevalue={formik.values.formData.cityId}
                                                    required={true}

                                                />
                                            </div>
                                            <div className='col-lg-6'>
                                                <FormControl
                                                    control="InputLabel"
                                                    name={"formData.zip_code"}
                                                    type={"number"}
                                                    label={"Postal/Zip Code"}
                                                    required={true}

                                                />
                                            </div>
                                            <div className='col-lg-6'>
                                                <FormControl
                                                    control="InputLabel"
                                                    name={"formData.longitude"}
                                                    type={"number"}
                                                    label={"Longitude"}
                                                    required={true}

                                                />
                                            </div>
                                            <div className='col-lg-6'>
                                                <FormControl
                                                    control="InputLabel"
                                                    name={"formData.latitude"}
                                                    type={"number"}
                                                    label={"Latitude"}
                                                    required={true}

                                                />
                                            </div>
                                        </div>

                                    </div>

                                    <div className='d-flex justify-content-end'>
                                        <div className='col-lg-2 mx-5' onClick={closeModal}>
                                            <Button text="Cancel" type="button" isCancel={true} />
                                        </div>

                                        <div className='col-lg-2'>
                                            <Button text="Save" type="submit" />
                                        </div>

                                    </div>

                                    <div>
              <p>
                all asterisk (*) field are required 
              </p>
            </div>
                                </Form>
                            }
                        </>

                    )}
                </Formik>
            </div>
        </div >
    );
}

export default EditDockayard;