import React, { useEffect, useState } from 'react';
import Loader from "components/loader";
import Button from "components/buttons/ADD";
import { USERSTATUSDATA } from 'utils/Services';

function ViewUserStatus({ closeModal, data, reload }) {

    const [loading, setLoading] = useState(false);
    const [allData, setAllData] = useState({});
    const [location, setLocation] = useState(''); // For displaying address

    // Geolocation
    const codeLatLng = (lat, lng) => {
        // Use a reverse geocoding API like Google Maps to get address from lat & lng
        fetch(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${'AIzaSyCBz4jdpZfM5QaAgCjG2M0CProFLg90SNg'}`)
            .then(response => response.json())
            .then(data => {
                if (data.status === 'OK') {
                    setLocation(data.results[0]?.formatted_address || "Location not found");
                } else {
                    setLocation("Location not found");
                }
            })
            .catch(error => {
                console.error("Error fetching location:", error);
                setLocation("Location not found");
            });
    };

  const cache = new Map();

const fetchData = async () => {
    setLoading(true);

    // Check if data is in cache
    if (cache.has(data)) {
        const cachedResult = cache.get(data);
        setAllData(cachedResult);
        console.log(cachedResult, 'Cached data');
        
        // Trigger reverse geocoding if latitude and longitude exist
        if (cachedResult.latitude && cachedResult.longitude) {
            codeLatLng(cachedResult.latitude, cachedResult.longitude);
        }
        setLoading(false);
        return;
    }

    try {
        const result = await USERSTATUSDATA(`?customerId=${data}`);
        const fetchedData = result?.data;

        // Cache the fetched data
        cache.set(data, fetchedData);
        setAllData(fetchedData);
        console.log(fetchedData, 'Fetched data');

        // Trigger reverse geocoding if latitude and longitude exist
        if (fetchedData?.latitude && fetchedData?.longitude) {
            codeLatLng(fetchedData.latitude, fetchedData.longitude);
        }
    } catch (error) {
        console.error('Failed to fetch data:', error);
        // Handle errors (e.g., show an error message to the user)
    } finally {
        setLoading(false);
    }
};


    useEffect(() => {
        fetchData();
    }, []);

    return (
        <div className="container-fluid pb-2">
            <div className='row'>
                <Loader loading={loading} />
                <div className='d-flex align-items-center justify-content-between mb-3'>
                    <h5>User Status</h5>

                    <div onClick={() => fetchData()} >
                        <Button text="Refresh" />

                    </div>

                </div>
                <div className='my-3'>
                    <div className='row d-flex border_box'>
                        <div className='col-lg-6' style={{ padding: "24px 18px", borderRight: " 1px solid rgba(155, 155, 155, 1)" }}>
                            <div className=''>
                            <div className='vehicalno'>
                                    <h6 className='vehicalid'>Rider Name: </h6>
                                    <div>{allData?.riderName}</div>
                                </div>
                                {/* <div className='vehicalno d-flex align-items-center'>
                                    <h6 className='vehicalid'>Vehicle ID: </h6>
                                    <div>{allData?.vehicleID}</div>
                                </div> */}
                                   <div className='vehicalno'>
                                    <h6 className='vehicalid'>Contact Number:</h6>
                                    <div>{allData?.riderContactNo}</div>
                                </div>
                                <div className='vehicalno'>
                                    <h6 className='vehicalid'>Zeway Registration Number: </h6>
                                    <div>{allData?.registrationNo}</div>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-6' style={{ padding: "24px 18px" }}>
                            <div className=''>
                                <div className='vehicalno'>
                                    <h6 className='vehicalid'>Location: </h6>
                                    <div style={{width:'65%'}}>{location || `${allData?.latitude}, ${allData?.longitude}`}</div>
                                </div>
                             
                                <div className='vehicalno'>
                                    <h6 className='vehicalid'>Live status:</h6>
                                    <div>{allData?.status}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ViewUserStatus;
