import React, { useState } from "react";
import AuthIllustration from "./Default";
import SignIn from "views/auth/signIn";
import OtpVerify from "../../views/auth/OtpVerify";
import { Provider } from "contexts/LoginContext";
// Custom Chakra theme
export default function Auth() {
  const [toggleSidebar, setToggleSidebar] = useState(false);
  const [step, setStep] = useState(0);
  const [details, setDetails] = useState({});

  const nextStep = () => {
    setStep(step + 1);
  };

  const prevStep = () => {
    setStep(step - 1);
  };
  const renderStep = () => {
    switch (step) {
      case 0:
        return (
          <SignIn
            nextStep={nextStep}
            details={details}
            setDetails={setDetails}
          />
        );

      case 1:
        return (
          <OtpVerify
            nextStep={nextStep}
            prevStep={prevStep}
            setDetails={setDetails}
            details={details}
          />
        );

      default:
        return null;
    }
  };

  
  return (
    <div>
      <AuthIllustration>
        <Provider value={{ details, setDetails, step, setStep }}>
          {renderStep(details, setDetails, step, setStep)}
        </Provider>
      </AuthIllustration>
    </div>
  );
}
