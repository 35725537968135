import React from 'react';
import { Route, Routes, Navigate } from "react-router-dom";
import AuthLayout from "layouts/auth";
import AdminLayout from "layouts/admin";
import { ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import { useSelector } from 'react-redux';
import ResetPassword from "views/auth/ResetPassword"
import ConfirmPassword from "views/auth/ConfirmPassword"
import VerifyForget from "views/auth/VerifyForget"

function App() {
    const isAuthenticated = useSelector((state) => state.auth.isLoggedIn);

    return (
        <div>
            <ToastContainer
                position="top-right"
                autoClose={3000}
                theme="light"
            />
            <Routes>
                <Route exact path="/login" element={<AuthLayout />} />
                <Route exact path="/reset-password" element={<ResetPassword />} />
                <Route exact path="/reset-verification" element={<VerifyForget />} />
                <Route exact path="/confirm-password" element={<ConfirmPassword />} />
                {isAuthenticated ? (
                    <Route path="/*" element={<AdminLayout />} />
                ) : (
                    <Route path="/login" element={<AuthLayout />} />
                )}
                <Route
                    path="*"
                    element={<Navigate to={isAuthenticated ? "/" : "/login"} />}
                />
            </Routes>

        </div>
    )
}

export default App