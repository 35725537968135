import React from "react";
import { Editor } from "react-draft-wysiwyg";
import {
    EditorState,
    convertToRaw,
    ContentState,
    convertFromHTML,
} from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from "draftjs-to-html";
import { Field, ErrorMessage } from "formik";
import TextError from "../../Errros/index"


function CustomEditor({ name, placeholder, value, customStyle,label,required }) {
    const prepareDraft = (value) => {
        const blocksFromHTML = convertFromHTML(value);
        const contentState = ContentState.createFromBlockArray(
            blocksFromHTML.contentBlocks,
            blocksFromHTML.entityMap
        );
        return EditorState.createWithContent(contentState);
    };

    const [editorState, setEditorState] = React.useState(
        value ? prepareDraft(value) : EditorState.createEmpty()
    );

    const onEditorStateChange = (newEditorState) => {
        setEditorState(newEditorState);
    };

    return (
        <div>
            <Field name={name}>
                {({ field, form }) => (
                    <div className="row">
                        <div>
                        {
            label && (
              <label>
                {label}
                {required && <span className="gl-form-asterisk"></span>}
              </label>
            )
          }
                            <Editor
                                toolbarHidden={false}
                                editorState={editorState}
                                editorStyle={customStyle}
                         
                                onEditorStateChange={onEditorStateChange}
                                toolbar={{
                                    options: ['inline', 'list'], // Corrected toolbar options
                                    inline: { inDropdown: true },
                                    list: { inDropdown: true },
                                }}
                                onBlur={() => {
                                    const rawContentState = convertToRaw(
                                        editorState.getCurrentContent()
                                    );
                                    const html = draftToHtml(rawContentState);
                                    form.setFieldValue(name, html);
                                }}
                                placeholder={placeholder}
                            />
                            <ErrorMessage component={TextError} name={name} />
                        </div>
                    </div>
                )}
            </Field>
        </div>
    );
}

export default CustomEditor;