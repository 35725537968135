import React, { useContext, useEffect, useMemo, useState } from 'react';
import FormControl from "components/controls/FormControl";
import CustomTable from "components/Table"
import Button from "components/buttons/ADD";
import EditDelete from "components/buttons/EditDelete";
import { ModalContext } from 'utils/Contexts/modalContext';
import CreateDockyard from 'components/modals/CreateDockyard';
import EditDockayard from 'components/modals/EditDockayard';
import CoomonDelete from 'components/modals/CoomonDelete';
import Loader from 'components/loader';
import { VIEWDOCKYARDAPI, DELETEDOCKYARDAPI } from 'utils/Services';
import { toast } from 'react-toastify';
import { ThirdPartyAPI } from 'utils/Services';

function Dockyard() {
    const modalContext = useContext(ModalContext);
    const { closeModal, handleModalData } = modalContext;
    const [loading, setLoading] = useState(false)
    const [allData, setallData] = useState([])


    const FeedsColumns = useMemo(       
        () => [
            {
                Header: "Sno.",
                accessor: (row, index) => {
                    return (
                        <>
                            <span className='text-sm'>{index + 1}</span>
                        </>
                    );
                }
            },
            {
                Header: "Registration",
                accessor: (Customer) => Customer?.nickname || "" // Use conditional rendering
            },
            {
                Header: "Start Time", // The header text for this column is "Service"
                accessor: (Customer) => Customer?.location?.stateTime || "" // Use conditional rendering
            },
            {
                Header: "Address", // The header text for this column is "Service"
                accessor: (Customer) => Customer?.location?.address || "" // Use conditional rendering
            },
            {
                Header: "Voltage", // The header text for this column is "Service"
                accessor: (Customer) => Customer?.location.voltage || "" // Use conditional rendering
            },
            {
                Header: "KM", // The header text for this column is "Service"
                accessor: (Customer) => Customer?.location.lat || "" // Use conditional rendering
            },
            {
                Header: "Latitude", // The header text for this column is "Service"
                accessor: (Customer) => Customer?.location.bdLat || "" // Use conditional rendering
            },
            {
                Header: "Longitude", // The header text for this column is "Service"
                accessor: (Customer) => Customer?.location.bdLon || "" // Use conditional rendering
            },
           
        ],
        []
    );
    const fecthData = async () => {
        try {
            setLoading(true)
            const result = await ThirdPartyAPI();
    
            if (result?.res?.status == 200) {
                setallData(result?.res?.data)
                setLoading(false)
            }
            else {
                setLoading(false)
            }

        }
        catch (err) {
            console.log(err)
            setLoading(false)
        }

    }
    useEffect(() => {
        fecthData()
    }, []);

    const handleDelete = async (data) => {
        setLoading(true)
        try {
            const result = await DELETEDOCKYARDAPI(data);

            if (result?.res?.status == 200) {
                setLoading(false)
                toast.success(result?.res?.message)
                fecthData(); closeModal();
            }
        }
        catch (err) {
            toast.error(err?.message || err)
            setLoading(false)
            closeModal();
        }


    }
    const handle = (data, info) => {
        const CreateDocumnet = <CreateDockyard closeModal={closeModal} reload={fecthData} />;
        const EditDocumnet = <EditDockayard closeModal={closeModal} data={info} reload={fecthData} />;
        const DelteDocumnet = <CoomonDelete closeModal={closeModal} data={info} handle={handleDelete} reload={fecthData} />
        switch (data) {
            case "create":
                handleModalData(CreateDocumnet, "md");
                break;
            case "edit":
                handleModalData(EditDocumnet, "md");
                break;
            case "delete":
                handleModalData(DelteDocumnet, "md");
                break;

        }

    }
    return (
        <div className="container-fluid pb-5">
            <div className='row'>
                <Loader loading={loading} />

                <div className='d-flex justify-content-between  my-3 '>
                 


                </div>

                {
                    allData.length > 0  && <div className='default_table'>
                        <CustomTable data={allData} columns={FeedsColumns} />


                    </div>
                }

            </div>
        </div>
    )
}

export default Dockyard