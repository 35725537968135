import Loader from 'components/loader';
import React, { useState, useEffect } from 'react';
import { Accordion } from "react-bootstrap";
import { USERPALNSAPI } from 'utils/Services';
function Subscription({ id }) {
    const [loading, setLoading] = useState(false)
    const [allData, setallData] = useState([])

    console.log(allData,'rathi');

    const fecthData = async () => {
        const data = {
            "planType": "subscription",
            "customerId": id?.data
        }

        try {
            setLoading(true)
            const result = await USERPALNSAPI(data);
            if (result?.res?.status == 200) {
                setallData(result?.res?.data)
                setLoading(false)
            }
            else {
                setLoading(false)
            }
        }
        catch (err) {
            console.log(err)
            setLoading(false)
        }
    }
    useEffect(() => {
        fecthData()
    }, []);
    const formatDate = (dateString) => {
        const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
        const date = new Date(dateString);
        return date.toLocaleDateString('en-GB', options).replace(/\//g, '-');
    };
    return (
        <div className="container-fluid pb-5 mt-5">
                 <Loader loading={loading} />
                 <div className='mb-3'>
                 <div className='px-5 d-flex justify-content-end'>
                Active Subscription: {allData[0]?.StartedDate ? formatDate(allData[0]?.StartedDate) : "N/A"}
            </div>
            </div>

        {allData && allData.length > 0 ? (
            <div className="d-flex justify-content-center flex-column px-5">    
                {/* {allData[0]?.planDetail?.length > 0  && allData[0]?.planDetail?.map((tab,i) => ( */}
                    <Accordion key={allData[0]?.planDetail?.id} defaultActiveKey={allData[0]?.planDetail?.id}>
                        <Accordion.Item eventKey="0">
                            <Accordion.Header>{allData[0]?.planName?.plan_name}</Accordion.Header>
                            <Accordion.Body>
                                <div>
                                    <div className="row">
                                        <div className="d-flex justify-content-between">
                                            <div>
                                                <div className="d-flex justify-content-between">
                                                    <div>
                                                        <label>Plan Type:</label>
                                                    </div>
                                                    <div className="mx-2">
                                                        <label>{allData[0]?.planType}</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <div className="d-flex justify-content-between">
                                                    <div>
                                                        <label>Booking Type:</label>
                                                    </div>
                                                    <div className="mx-2">
                                                        <label>{"Confirm"}</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="d-flex justify-content-between">
                                            <div>
                                                <div className="d-flex justify-content-between">
                                                    <div>
                                                        <label>Security Deposit:</label>
                                                    </div>
                                                    <div className="mx-2">
                                                        <label>{allData[0]?.planName?.securityDeposit}</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <div className="d-flex justify-content-between">
                                                    <div>
                                                        <label>Amount:</label>
                                                    </div>
                                                    <div className="mx-2">
                                                        <label>{allData[0]?.amount}</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
            {/* ))} */}
            </div>
            
        ) : (
            <div className='d-flex justify-content-center'>
                <label>No content available</label>
            </div>
        )}
    </div>
    

    )
}   

export default Subscription