import React, { useEffect, useState } from 'react';
import { Formik, Form } from 'formik';
import FormControl from 'components/controls/FormControl';
import Loader from "components/loader";
import Button from 'components/buttons/ADD';
import { GETSTATEAPI, GETCITYAPI, UPDATECREATECHANNELPARTNERAPI, EDITCREATECHANNELPARTNERAPI } from 'utils/Services';
import * as Yup from 'yup';
import { toast } from 'react-toastify';

function EditDockayard({ closeModal, data, reload }) {
    const [options, setOptions] = useState({
        state: [], city: [], status: [{
            id: true,
            label: 'Active',
            value: true
        }, {
            id: false,
            label: 'Inactive',
            value: false
        }]
    });
    const [selectCity, setSelectCity] = useState();
    const [loading, setLoading] = useState(false);
    const [selectState, setSelectState] = useState();
    const [allData, setAllData] = useState({});

    const fetchState = async () => {
        try {
            setLoading(true);
            const result = await GETSTATEAPI();
            if (result?.res?.status === 200) {
                const data = result?.res?.data?.map((res) => ({
                    id: res?.id,
                    label: res?.name,
                    value: res?.id
                }));
                setOptions(prev => ({ ...prev, state: data }));
            }
            setLoading(false);
        } catch (err) {
            console.log(err);
            setLoading(false);
        }
    };

    const fetchCity = async () => {
        if (selectState) {
            try {
                setLoading(true);
                const result = await GETCITYAPI({ state_id: selectState });
                if (result?.status === 200) {
                    const data = result?.data?.map((res) => ({
                        id: res?.id,
                        label: res?.name,
                        value: res?.id
                    }));
                    setOptions(prev => ({ ...prev, city: data }));
                }
                setLoading(false);
            } catch (err) {
                console.log(err);
                setLoading(false);
            }
        } else {
            setOptions(prev => ({ ...prev, city: [] }));
        }
    };

    const fetchData = async () => {
        try {
            setLoading(true);
            const result = await EDITCREATECHANNELPARTNERAPI(data);
            if (result?.res?.status === 200) {
                setSelectState(result?.res?.data?.stateId);
                setSelectCity(result?.res?.data?.cityId);
                setAllData(result?.res?.data);
            }
            setLoading(false);
        } catch (err) {
            console.log(err);
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchState();
        fetchData();
    }, []);

    useEffect(() => {
        fetchCity();
    }, [selectState]);

    const validationSchema = Yup.object().shape({
        formData: Yup.object().shape({
            channel_partner_name: Yup.string().required('Channel Partner Name is required'),
            stateId: Yup.string().required('State is required'),
            cityId: Yup.string().required('City is required'),
            address1: Yup.string().required('Address1 is required'),
            zip_code: Yup.number()
                .required('Zip Code is required')
                .typeError('Zip Code must be a number')
                .integer('Zip Code must be an integer')
                .min(100000, 'Zip Code must be exactly 6 digits')
                .max(999999, 'Zip Code must be exactly 6 digits'),
            is_active: Yup.string().required('Status is required'),
            gst_no: Yup.string()
                .matches(
                    /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}[Z]{1}[0-9A-Z]{1}$/,
                    'Invalid GST Number'
                )
                .required('GST Number is required')
        }),
    });

    const handle = async (values) => {
        setLoading(true);
        try {
            const _data = { ...values.formData, zip_code: parseInt(values.formData.zip_code) };
            const result = await UPDATECREATECHANNELPARTNERAPI(data, _data);
            if (result?.res?.status === 200) {
                toast.success(result?.res?.message);
                closeModal();
                reload && reload();
            } else {
                toast.error(result?.message);
            }
            setLoading(false);
        } catch (err) {
            setLoading(false);
            toast.error(err?.message);
        }
    };

    return (
        <div className='row'>
            <Loader loading={loading} />
            <div className='com_modal'>
                {
                    allData.channel_partner_name  &&   <Formik
                    enableReinitialize={true}
                    initialValues={{
                        formData: {
                            channel_partner_name: allData.channel_partner_name,
                            address1: allData.address1,
                            address2: allData.address2,
                            cityId: allData.cityId,
                            stateId: allData.stateId,
                            gst_no: allData.gst_no,
                            zip_code: allData.zip_code,
                            is_active: allData.is_active,
                        },
                    }}
                    validationSchema={validationSchema}
                    onSubmit={handle}
                >
                    {(formik) => (
                        allData.address1  && (
                            <Form className='form_default text_left'>
                                <div className='mb-3'>
                                    <FormControl control="FormHead" title="Update Channel Partner" />
                                </div>
                           
                                <div className='mb-3'>
                                    <div className='row g-3'>
                                        <div className='col-lg-6'>
                                            <FormControl
                                                control="SelectBox"
                                                name={"formData.is_active"}
                                                label={"Channel Partner Status"}
                                                options={options.status}
                                                prevalue={formik.values.formData.is_active}
                                                required={true}

                                            />
                                        </div>
                                        <div className='col-lg-6'>
                                            <FormControl
                                                control="InputLabel"
                                                name={"formData.channel_partner_name"}
                                                type={"text"}
                                                label={"Channel Partner Name"}
                                                required={true}

                                            />
                                        </div>
                                        <div className='col-lg-6'>
                                            <FormControl
                                                control="InputLabel"
                                                name={"formData.address1"}
                                                type={"text"}
                                                label={"Address Line 1"}
                                                required={true}

                                            />
                                        </div>
                                        <div className='col-lg-6'>
                                            <FormControl
                                                control="InputLabel"
                                                name={"formData.address2"}
                                                type={"text"}
                                                label={"Address Line 2"}
                                            />
                                        </div>
                                        <div className='col-lg-6'>
                                            <FormControl
                                                control="SelectBox"
                                                name={"formData.stateId"}
                                                options={options.state}
                                                label={"State"}
                                                setfield={setSelectState}
                                                prevalue={formik.values.formData.stateId}
                                                required={true}

                                            />
                                        </div>
                                        <div className='col-lg-6'>
                                            <FormControl
                                                control="SelectBox"
                                                name={"formData.cityId"}
                                                options={options.city}
                                                label={"City"}
                                                setfield={setSelectCity}
                                                prevalue={selectCity}
                                                required={true}

                                            />
                                        </div>
                                        <div className='col-lg-6'>
                                            <FormControl
                                                control="InputLabel"
                                                name={"formData.zip_code"}
                                                type={"number"}
                                                label={"Postal/Zip Code"}
                                                required={true}

                                            />
                                        </div>
                                        <div className='col-lg-6'>
                                            <FormControl
                                                control="InputLabel"
                                                name={"formData.gst_no"}
                                                type={"text"}
                                                label={"GST No."}
                                                required={true}

                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className='d-flex justify-content-end'>
                                    <div className='col-lg-2 mx-5' onClick={closeModal}>
                                        <Button text="Cancel" type="button" isCancel={true} />
                                    </div>
                                    <div className='col-lg-2'>
                                        <Button text="Save" type="submit" />
                                    </div>
                                </div>
                                <div>
              <p>
                all asterisk (*) field are required 
              </p>
            </div>
                            </Form>
                        )
                    )}
                </Formik>
                }
     
            </div>
        </div>
    );
}

export default EditDockayard;
