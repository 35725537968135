

import React from "react";
import FormControl from "../../../components/controls/FormControl";
import Button from "../../../components/buttons/Default"
import Loader from "components/loader"
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { toast } from "react-toastify";
import { CHANGEPASSWORDACCAPI } from "utils/Services";
import { useLocation, useNavigate } from "react-router-dom";
// Custom components        
// Assets

function SettingEdit(props) {
    const { nextStep, details, setDetails } = props
    const navigate = useNavigate();
    const location = useLocation();
    const [loading, setloading] = React.useState(false);
    const [show, setShow] = React.useState(false);
    const handleClick = () => setShow(!show);
    const { data } = location.state || {};
    const validationSchema = Yup.object().shape({
        formData: Yup.object().shape({
            password: Yup.string()
            .required('Password is required')
            .min(8, 'Password must be at least 8 characters long'),
        confirmPassword: Yup.string()
            .oneOf([Yup.ref('password'), null], 'Password must match')
            .required('Confirm password is required')
        }),
    });
    // const handle = async (values) => {
    //     const data =
    //     {
    //         ...values.formData
    //     }
    //     try {
    //         setloading(true);
    //         const result = await CHANGEPASSWORDACCAPI(data);
    //         if (result?.status == 201) {
    //             setloading(false)
    //             toast.success(result.message)
           
    //             navigate('/login')
              
    //         }
    //         else {
    //             setloading(false)
    //             toast.error(result.message)
    //         }
    //     }
    //     catch (err) {

    //         setloading(false)
    //         toast.error(err && err?.message)
    //     }
    // }
    const handleForgot = () => {
   
    }
   
    return (


        <div className="login_wrapper">
            <Loader loading={loading} />
            <div className="login_block col-10">
                <Formik
                    initialValues={{
                        formData: {
                            "contact_no":data?.contact_no,
                            'password': "",
                            'confirmPassword':""

                        },
                    }}
                    validationSchema={validationSchema}
                    // onSubmit={handle}
                >
                    {formik => (
                        <div className="row">
                            <Form className="form_default ">
                                <div className='mb-4'>
                                    <FormControl
                                        control='heading'
                                        title='Edit Invoice'
                                    />
                                </div>
                                <div className='mb-4 row'>
                                    <div className='col-lg-6'>
                                        <div className='mb-3'>
                                            <FormControl
                                                label="Full Name"
                                                control='InputLabel'
                                                type="text"
                                                name='formData.firstname'

                                            />
                                        </div>
                                        </div>
                                        <div className='col-lg-6'>

                                        <div className='mb-3'>
                                            <FormControl
                                                label="Company Name"
                                                control='InputLabel'
                                                type="text"
                                                name='formData.companyname'

                                            />
                                        </div>


                                    </div>
                                    <div className='col-lg-6'>
                                        <div className='mb-3'>
                                            <FormControl
                                                label="Password"
                                                control='InputLabel'
                                                type="password"
                                                name='formData.password'

                                            />
                                        </div>
                                        </div>
                                        <div className='col-lg-6'>

                                        <div className='mb-3'>
                                            <FormControl
                                                label="Confirm New Password"
                                                control='InputLabel'
                                                type="password"
                                                name='formData.confirmPassword'

                                            />
                                        </div>


                                    </div>
                                </div>
                                

                                <div className='d-flex justify-content-end'>
                                <div className='col-lg-4 mx-3' >
                                    <Button text="Cancel" type="button" isCancel={true} />
                                </div>

                                <div className='col-lg-4'>
                                    <Button text="Save" type="submit" />
                                </div>

                            </div>
                                <div className="mt-4">
                                    <p>

                                        {/* <span className="tetxdark_under mx-1">
                      Sign Up
                    </span> */}
                                    </p>
                                </div>

                            </Form>
                        </div>
                    )}
                </Formik>
            </div>


        </div>

    );
}

export default SettingEdit;
