import axios from "axios";
import { REFRESHTOKEN } from "utils/CustomRoutes";

const NetworkServices = () => {
  // Add a request interceptor
  axios.interceptors.request.use(
    (config) => {
      // Exclude certain URLs from authentication headers
      if (
        !config.url.includes("/refreshToken") &&
        !config.url.includes("adminManage/login") &&
        !config.url.includes("adminManage/otp_verification")
      ) {
        const access = localStorage.getItem("_authData");
        if (access) {
          config.headers["Authorization"] = "Bearer " + access;
        }
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  // Add a response interceptor
  axios.interceptors.response.use(
    function (response) {
      return response;
    },

    async function (error) {
        
      const originalRequest = error.config;
      const refresh = localStorage.getItem("refreshToken");
      if (error.response && error.response.status == 401) {
      //  If unauthorized, attempt to refresh token
       if (!originalRequest._retry) {
         originalRequest._retry = true;
         try {
           if (refresh) {
             const response = await axios.post(REFRESHTOKEN, {
               refresh_token: refresh,
             });
             localStorage.setItem("_authData", response.data.data);
             axios.defaults.headers.common[
               "Authorization"
             ] = `Bearer ${response.data.data}`;
            // Retry original request with new token
             return axios(originalRequest);
           }
         } catch (err) {
            
          // Handle token refresh failure
           if (err?.response?.data?.data == "forbidden") {
             localStorage.removeItem("_authData");
             localStorage.clear();
             window.location.replace("/login");
           } 
         }
       }
      }
   if(error.response && error.response.status == 403)
       {
         if (error?.response?.data?.message == "Unauthorized: Invalid token.") {
           localStorage.removeItem("_authData");
           localStorage.clear();
           window.location.replace("/login");
         }
       }
      return Promise.reject(error);
    }
  );
};

export default NetworkServices;
