import React, { useState, useEffect } from 'react';
import { CustomTabs, CustomTabPanel } from 'components/Tabs';
import ChannelPartner from 'views/admin/ChannelPartner';
import BranchLocation from 'views/admin/BranchLocation';

function Raci_Role() {
    // Get the active tab from local storage or default to the first tab
    const initialTab = parseInt(localStorage.getItem('activeTab')) || 0;

    // State to manage the active tab
    const [activeTab, setActiveTab] = useState(initialTab);

    // Update local storage when the active tab changes
    useEffect(() => {
        localStorage.setItem('activeTabservice', parseInt(activeTab));
    }, [activeTab]);

    return (
        <div>
            <div className="container-fluid pb-5">

                <CustomTabs activeTab={activeTab} onChange={setActiveTab} setActiveTab={setActiveTab} >
                    <CustomTabPanel tabLabel="Channel Partner" key="1">
                        <ChannelPartner />  
                    </CustomTabPanel>
                    <CustomTabPanel tabLabel="Branch Location" key="2">
                        <BranchLocation />
                    </CustomTabPanel>

                </CustomTabs>
            </div>
        </div>
    );
}

export default Raci_Role;