import React, { useEffect, useState } from 'react';
import notification from "../../assets/img/Icons/Dashboard/notifications.svg";
import { useSelector } from 'react-redux';

function UserProfile() {
    const data = useSelector((state) => state.auth.userData);
    const result = localStorage.getItem('role');
    const [role, setRole] = useState('')





    useEffect(() => {
        switch (result) {
            case "1":
                setRole('Zeway Super Admin')
                break;
            case "2":
                setRole('Admin (Channel Partner)')
                break;
            case "3":
                setRole('Manager (Channel Partner)')
                break;
            default:
                setRole('Zeway Super Admin)')
        }
    }, [result])

    return (
        <div className='user_profile'>
            <div className='row' >
                <div className='d-flex justify-content-between align-items-center'>
                    <div className='icon_dash'>
                        <img src={notification} alt="noti" />
                    </div>

                    <div className='icon_text'>
                        <p >
                            {
                                `${data}`
                            }  <span className='mx-1 colored_text'>
                                {
                                    `(${role})`
                                }
                            </span>
                        </p>
                    </div>

                </div>

            </div>


        </div>
    )
}

export default UserProfile