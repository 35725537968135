import React from 'react'

function ButtonIcon({ text, source }) {
    return (
        <div className='cursor-pointer'>
            <div className='icon_btn'>
            <div className='icon_dash'>
                    <img src={source} />    

                </div>

                <div className='icon_text mx-2' >
                    <p>
                        {text}
                    </p>
                </div>
               


            </div>

        </div>
    )
}

export default ButtonIcon