import React, { useContext, useEffect, useMemo, useState } from 'react';
import FormControl from "components/controls/FormControl";
import CustomTable from "components/Table"
import Button from "components/buttons/ADD";
import EditDelete from "components/buttons/EditDelete";
import { ModalContext } from 'utils/Contexts/modalContext';
import CreateUsergenerate from 'components/modals/CreateUsergenerate';
import Loader from 'components/loader';
import { VIEWCHANNELFLEETSTATUS, VIEWCREATECHANNELPARTNERAPI } from 'utils/Services';
import  search  from "../../../assets/img/Icons/Dashboard/search.svg"


function Reportsstatus() {
    const modalContext = useContext(ModalContext);
    const { closeModal, handleModalData } = modalContext;
    const [loading, setLoading] = useState(false)
    const [allData, setallData] = useState([])
    const [options, setOptions] = useState([])
    const [filteredData, setFilteredData] = useState([]);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [createat, setcreateat] = useState()
    const [searchVal,setsearchVal]=useState([])


    const handleSearch = (event) => {
        const searchValue = event.target.value.toLowerCase();  
        const filterArray = allData.filter((e) => {
            const fullName = e?.customer?.full_name?.toLowerCase() || ""; 
            return fullName.includes(searchValue);
        });
        
        setsearchVal(searchValue);  
        if (searchValue === "") {
            setFilteredData(allData);  
        } else {
            setFilteredData(filterArray);  
        }
    };

    const FeedsColumns = useMemo(
        () => [
            {
                Header: "Sno.",
                accessor: (row, index) => {
                    return (
                        <>
                            <span className='text-sm'>{index + 1}</span>
                        </>
                    );
                }
            },
            {
                Header: "Report Name",
                accessor: (Customer) => Customer?.customer?.full_name || ""
            },

            // {
            //     Header: "Start Date",
            //     accessor: (Customer) => {
            //         if (Customer?.channel_partner == 1) {
            //             return "Other";
            //         } else if (Customer?.channel_partner == 2) {
            //             return "Zeway Rider";
            //         } else {
            //             return "--";
            //         }
            //     }
            // },
            
            {
                Header: "Start Date",
                accessor: (row) => {
                    const createdAt = row?.rideDetail?.createdAt;
                    return createdAt ? createdAt.split('T')[0] : "--";
                }
            },

            // {
            //     Header: "End Date",
            //     accessor: (Customer) => {
            //         if (Customer?.channel_partner == 1) {
            //             return "Other";
            //         } else if (Customer?.channel_partner == 2) {
            //             return "Zeway Rider";
            //         } else {
            //             return "--";
            //         }
            //     }
            // },


            {
                Header: "Actions",
                accessor: (row, index) => {
                    return (
                        <div className='d-flex justify-content-start '>
                            <div className='flex'>
                                {/* onClick={() => handleDetails(row?.rideDetail?.customerId)} */}
                                <div
                                    className='w-100 mx-2'
                                    onClick={() => handle(row?.rideDetail?.customerId, row?.rideDetail?.createdAt, row?.customer?.full_name)}
                                >
                                    <EditDelete text="Export Excel" type="Button" />

                                </div>
                            </div>
                        </div>

                    );
                }
            },
        ],
        [options?.length > 0]
    );
    const channelid = localStorage.getItem('channelId');

    const fecthData = async () => {
        try {
            setLoading(true);
            const result = await VIEWCHANNELFLEETSTATUS(`?channel_partner=${channelid}`);
            if (result?.res?.status === 200) {
                const apiData = result?.res?.data;

                // if (Array.isArray(apiData?.rideDetail) && apiData.rideDetail.length > 0) {
                //     const startDateFormatted = new Date(apiData.rideDetail[0]?.createdAt).toISOString();

                //     const endDateFormatted = new Date(apiData.rideDetail[apiData.rideDetail.length - 1]?.createdAt).toISOString();

                //     setStartDate(startDateFormatted);
                //     setEndDate(endDateFormatted);
                // } else {
                //     setStartDate(null);
                //     setEndDate(null);
                // }

                setallData(apiData);
                console.log(apiData, 'Fetched API Data');
            }
            setLoading(false);
        } catch (err) {
            console.log(err);
            setLoading(false);
        }
    };


    useEffect(() => {
        fecthData();
    }, []);


    useEffect(() => {
        setFilteredData(allData);
    }, [allData]);




    useEffect(() => {
        if (options && options.length > 0) {
            fecthData()

        }

    }, [options]);
    useEffect(() => {

        fetchChannel()
    }, []);

    const handle = (customerId, createdAt,full_name) => {
        const CreateDocumnet = <CreateUsergenerate closeModal={closeModal} customerId={customerId} createdAt={createdAt} fullname={full_name}/>;


        handleModalData(CreateDocumnet, "md");
    };

    const fetchChannel = async () => {
        setLoading(true);
        try {
            const result = await VIEWCREATECHANNELPARTNERAPI();

            if (result?.res?.status === 200) {
                const data = result?.res?.data?.map((res) => ({
                    id: res?.id,
                    label: res?.channel_partner_name,
                    value: res?.channel_partner_name
                }));

                setOptions(data);
                setLoading(false);
            } else {
                setLoading(false);
            }
        } catch (err) {
            console.log(err);
            setLoading(false);
        }
    }

    const start = startDate;
    const end = endDate;

    // console.log(start, 'startdate1');
    // console.log(end, 'enddate1');







    return (
        <div className="container-fluid pb-5">
            <div className='row'>
                <Loader loading={loading} />
                <div className='d-flex justify-content-between my-3'>
                    <div className='col-lg-4'>
                        {/* <FormControl control={"Search"} /> */}
                        <div className="search">
            <div className="">
                <button id="button-addon2" type="submit" className="search_btn">
                <img src={search} />
                </button>
            </div>
            <input
                type="search"
                value={searchVal} 
                onChange={handleSearch}
                // placeholder={placeholder}
                aria-describedby="button-addon2"
                className="serch_input"
            />
        </div>
                    </div>

                    {/* <div>
                        <Button text="Generate New Report" />
                    </div> */}
                </div>

                {filteredData.length > 0 && (
                    <div className='default_table'>
                        <CustomTable data={filteredData} columns={FeedsColumns} />
                    </div>
                )}
            </div>
        </div>
    )

}

export default Reportsstatus