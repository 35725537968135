import React from 'react'

function BoderButton({ type, text, disabled }) {
    return (
        <button className='border_btn' type={type} disabled={disabled}>
            {text}
        </button>
    )
}

export default BoderButton