import React, { useContext, useEffect, useMemo, useState } from 'react';
import FormControl from "components/controls/FormControl";
import CustomTable from "components/Table"
import Button from "components/buttons/ADD";
import EditDelete from "components/buttons/EditDelete";
import { ModalContext } from 'utils/Contexts/modalContext';
import CreateDockyard from 'components/modals/CreateDockyard';
import EditDockayard from 'components/modals/EditDockayard';
import CoomonDelete from 'components/modals/CoomonDelete';
import Loader from 'components/loader';
import { VIEWDOCKYARDAPI, TranscationlistAPI } from 'utils/Services';
import { toast } from 'react-toastify';
import { ThirdPartyAPI } from 'utils/Services';
import { useNavigate } from 'react-router-dom';

function Transaction() {
    const modalContext = useContext(ModalContext);
    const { closeModal, handleModalData } = modalContext;
    const [loading, setLoading] = useState(false)
    const [allData, setallData] = useState([])
    const navigate = useNavigate();
    
    const formatDate = (dateString) => {
        const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
        const date = new Date(dateString);
        return date.toLocaleDateString('en-GB', options).replace(/\//g, '-');
    };

    const FeedsColumns = useMemo(
        () => [
            {
                Header: "Sno.",
                accessor: (row, index) => {
                    return (
                        <>
                            <span className='text-sm'>{index + 1}</span>
                        </>
                    );
                }
            },
            {
                Header: "Customer Name",
                accessor: (Customer) => Customer?.customerId || "" // Use conditional rendering
            },
            {
                Header: "Transaction No.", // The header text for this column is "Service"
                accessor: (Customer) => Customer?.trans_ref ? Customer?.trans_ref : "--" // Use conditional rendering
            },
          
            {
                Header: "Amount", // The header text for this column is "Service"
                accessor: (Customer) => Customer?.amount || "" // Use conditional rendering
            },
          
            {
                Header: "Date", // The header text for this column is "Date"
                accessor: (Customer) => Customer?.transaction_date ? formatDate(Customer.transaction_date) : "" // Use conditional rendering
            },
            {
                Header: "Status",
                accessor: (row, index) => {
                    return (
                        <>
                            {
                           row?.flag == "in" ? (
                            <span className='text-sm Verified'>Credit</span>
                          ) : (
                            <span className='text-sm error'>Debit</span>
                          )
                            }

                        </>
                    );
                }

            },


        ],
        []
    );
    const fecthData = async () => {
        try {
            setLoading(true)
            const result = await TranscationlistAPI();


            if (result?.res?.status == 200) {
                setallData(result?.res?.data)
                setLoading(false)
            }
            else {
                setLoading(false)
            }

        }
        catch (err) {
            console.log(err)
            setLoading(false)
        }

    }
    useEffect(() => {
        fecthData()
    }, []);


    const handle = (data, info) => {
        navigate('/tesrt')

    }
    return (
        <div className="container-fluid pb-5">
            <div className='row'>
                <Loader loading={loading} />

                <div className='d-flex justify-content-between  my-3 '>
                    <div className='col-lg-6'>

                    </div>
                    <div onClick={() => handle("create")}>
                        <Button text="Create New Transaction" />

                    </div>


                </div>


                {
                    allData.length > 0 && <div className='default_table'>
                        <CustomTable data={allData} columns={FeedsColumns} />


                    </div>
                }

            </div>
        </div>
    )
}

export default Transaction