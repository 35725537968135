import React from "react";
import { Field, ErrorMessage } from "formik";
import TextError from "../../Errros";

function index(props) {
  const { name, defaultValue, placeholder, type, value, label, required, ...rest } =
    props;

  return (
    <Field name={name}>
      {({ field, form }) => (
        <div className="block">
          {
            label && (
              <label>
                {label}
                {required && <span className="gl-form-asterisk"></span>}
              </label>
            )
          }


          <input
            name={name}
            type={type}
            placeholder={placeholder}
            value={value}
            {...rest}
            
          
            
          />
          <ErrorMessage component={TextError} name={name} />
        </div>
      )}
    </Field>
  );
}

export default index;