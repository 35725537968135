import Loader from 'components/loader';
import React, { useEffect, useState } from 'react'
import { GETHOMEAPI } from 'utils/Services';

function Index() {
  // const [loading, setLoading] = useState(false);
  // const [allData, setAllData] = useState("");

  // const fetchData = async () => {
  //   setLoading(true);
    
  //   try {
  //     const result = await GETHOMEAPI();
  //     if (result?.res?.status === 200) {
  //       setAllData(result?.res?.data);
  //       console.log(result?.res?.data, 'rathi');
  //     }
  //     setLoading(false);
  //   } catch (err) {
  //     console.log(err);
  //     setLoading(false);
  //   }
  // };

  // useEffect(() => {
  //   fetchData();
  // }, []); 

  return (
    <div>
      {/* <Loader loading={loading} /> */}
      <div className="row g-5 mb-6">
        {/* {allData && allData.map((item, index) => {
          return (
          
          );
        })} */}
  <div className="col-xl-3 col-sm-6 col-12" >
              <div className="card shadow border-0">
                <div className="">
                  <div className="row custom_card">
                    <div className="d-flex justify-content-evenly align-items-center">
                      <div className="counter">
              <span>0</span>
                      </div>
                      <div>
                           <span>Total Vehicles</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
        <div className="col-xl-3 col-sm-6 col-12">
          <div className="card shadow border-0">
            <div className="">
              <div className="row custom_card">
                <div className="d-flex justify-content-evenly align-items-center">
                  <div className="counter">
                    <span>0</span>
                  </div>
                  <div>
                    <span>Vehicles Deployed</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-xl-3 col-sm-6 col-12">
          <div className="card shadow border-0">
            <div className="">
              <div className="row custom_card">
                <div className="d-flex justify-content-evenly align-items-center">
                  <div className="counter">
                    <span>0</span>
                  </div>
                  <div>
                    <span>Vehicles Available</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-xl-3 col-sm-6 col-12">
          <div className="card shadow border-0">
            <div className="">
              <div className="row custom_card">
                <div className="d-flex justify-content-evenly align-items-center">
                  <div className="counter">
                    <span>0</span>
                  </div>
                  <div>
                    <span>Average Trips in a month</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  );
}

export default Index;
