import React, { useContext, useEffect, useMemo, useState } from 'react';
import { ModalContext } from 'utils/Contexts/modalContext';
import PayPer from "./PayPer"
import Subscription from "./Subscription"
import { CustomTabs, CustomTabPanel } from 'components/Tabs';
import { useParams } from 'react-router-dom';

function Plan(data) {
    const modalContext = useContext(ModalContext);
    const { closeModal, handleModalData } = modalContext;
    const [loading, setLoading] = useState(false)
    const [allData, setallData] = useState([])

    const initialTab = parseInt(localStorage.getItem('activeTabsubservice')) || 0;
    const params = useParams();
    // State to manage the active tab
    const [activeTab, setActiveTab] = useState(initialTab);

    // Update local storage when the active tab changes
    useEffect(() => {
        localStorage.setItem('activeTabsubservice', parseInt(activeTab));
    }, [activeTab]);




    const FeedsColumns = useMemo(
        () => [



            {
                Header: "x", // The header text for this column is "Service"
                accessor: (Customer) => Customer?.zip_code || "" // Use conditional rendering
            },


        ],
        []
    );
    // const fecthData = async () => {
    //     try {
    //         setLoading(true)
    //         const result = await VIEWCREATECHANNELPARTNERAPI();
    //         if (result?.res?.status == 200) {
    //             setallData(result?.res?.data)
    //             setLoading(false)
    //         }
    //         else {
    //             setLoading(false)
    //         }

    //     }
    //     catch (err) {
    //         console.log(err)
    //         setLoading(false)
    //     }

    // }
    // useEffect(() => {
    //     fecthData()
    // }, []);


   
    return (
        // <div className="container-fluid pb-5">
        //     <div className='row'>
        //         <Loader loading={loading} />
        //         <div className='my-5'>
        //         <div className='details_tile'>
        //         <div className='d-flex justify-content-between'>
        //             <div className='col-lg-6 text-center'>
        //                 ss
        //             </div>
        //             <div className='col-lg-6 text-center'>
        //                 vxcvv
        //             </div>

        //             </div>
                 


        //         </div>
        //         </div>

            



        //     </div>
        // </div>
        <div>
        <div className="container-fluid pb-5 mt-3">

            <CustomTabs activeTab={activeTab} onChange={setActiveTab} setActiveTab={setActiveTab} >
                <CustomTabPanel tabLabel="PayPerUSe" key="1">
                    <PayPer  id={data}/>
                </CustomTabPanel>
                <CustomTabPanel tabLabel=" Subscription" key="2">
                    <Subscription  id={data}/>
                </CustomTabPanel>
            </CustomTabs>
        </div>
    </div>
    )
}

export default Plan