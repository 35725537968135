import React, { Suspense, useEffect, useState } from "react";

// chakra imports
import SidebarList from "./SidebarList";
import { Link, NavLink } from "react-router-dom";
import Logo from "../../assets/img/Logo/logo_client.svg";
import cancel from "assets/img/Icons/Dashboard/close.svg";
import { useSelector } from "react-redux";
import { useAbility } from "utils/Contexts/AbilityContext";
import Loader from "components/loader";

function Sidebar(props) {
  const toggle = props.toggle;
  const handletoggle = props.handletoggle;
  const [activeLink, setActiveLink] = useState({ 0: true });
  const [listShow, setListShow] = useState({ 0: true });
  const [buttonid, setButtonId] = useState(0);
  const ability = useAbility();
  const perMission = useSelector((state) => state.auth.access);
  const isAdmin = useSelector((state) => state.auth.isAdmin);
  const listHandler = (id, sublist, listRef) => {
    setActiveLink({ [id]: true });

    if (sublist !== undefined && !listShow[listRef]) {
      setListShow({ [listRef]: true });
    } else {
      setListShow({ [listRef]: false });
    }
  };
  return (
    <>
      <div>
        <div className="d-flex justify-content-end mx-3">
          <div className={`cancel_icon ${toggle ? 'hamburger-1' : ''}`} id={toggle ? 'hamburger-1' : ''} onClick={handletoggle}>
            <img src={cancel} alt="Cancel Icon" />
          </div>
        </div>
        <div className="d-flex justify-content-center">
          <div className="image_logo">
            <img src={Logo} alt="Logo" />
          </div>
        </div>
        {isAdmin === "false" || isAdmin === false ? (
          <ul className="side-menu">
            {SidebarList &&
              SidebarList?.map((list, id) => {
                let listRef = list.ref;
                let sublist = list.sublist;
                let labelText = list.label;
                let path = list.path;
                let src = list.src;
                let src1 = list.src1;
                
                const matchingAbility = ability?.m?.find((a) => a?.subject == list?.check);
                return (
                  <React.Fragment key={list.id}>
                    {(matchingAbility || list.id == 10 || list.id == 1) && (
                      <li
                        key={list.id}
                        id={id}
                        onClick={() => {
                          sublist === undefined && listHandler(id, sublist, listRef);
                          handletoggle();
                        }}
                      >
                        <Link
                          to={`${path}`}
                          onClick={() => {
                            listHandler(id, sublist, listRef);
                            setButtonId(parseInt(id));
                          }}
                          className={activeLink[id] ? "active" : ""}
                        >
                          <div className="navIcon d-flex align-items-baseline">
                            <div className="sidenavimg">
                              <img src={id === buttonid ? src1 : src} alt="" />
                            </div>
                            <p className={id === buttonid ? "side_menutext mx-3" : "mx-3"}>{labelText}</p>
                          </div>
                          {/* {matchingAbility && sublist && <img src={downArrow} className={`downArrow ${listShow[listRef] ? "upArrow" : ""}`} alt="logo" />} */}
                        </Link>
                      </li>
                    )}

                    {sublist && matchingAbility && (
                      <li className="p-0">
                        <ul className={listShow[listRef] ? "submenu" : ""}>
                          {list.sublist.map((sublistItem, subId) => (
                            <li key={sublistItem.id}>
                              <NavLink to={`${sublistItem.path}`} activeClassName="active" className="subnavlist">
                                <div className="subnavIcon d-flex">
                                  <div className="me-3">
                                    <img src={window.location.pathname === sublistItem.path ? sublistItem.src : sublistItem.src1} alt="" />
                                  </div>
                                  <p className={window.location.pathname === sublistItem.path ? "side_menutext" : ""} onClick={handletoggle}>
                                    {sublistItem.label}
                                  </p>
                                </div>
                              </NavLink>
                            </li>
                          ))}
                        </ul>
                      </li>
                    )}
                  </React.Fragment>
                );
              })}
          </ul>
        ) : (
          <div>
            <Suspense fallback={<Loader loading={true} />}>
              <ul className="side-menu">
                {SidebarList &&
                  SidebarList?.map((list, id) => {
                    let listRef = list.ref;
                    let sublist = list.sublist;
                    let labelText = list.label;
                    let path = list.path;
                    let src = list.src;
                    let src1 = list.src1;

                    return (
                      <React.Fragment key={list.id}>
                        {true && (
                          <li
                            key={list.id}
                            id={id}
                            onClick={() => {
                              sublist === undefined ? handletoggle() : listHandler(id, sublist, listRef);
                            }}
                          >
                            <Link
                              to={`${path}`}
                              onClick={() => {
                                listHandler(id, sublist, listRef);
                                setButtonId(parseInt(id));
                              }}
                              className={activeLink[id] ? "active" : ""}
                            >
                              <div className="navIcon d-flex">
                                <div className="sidenavimg">
                                  <img src={id === buttonid ? src : src1} alt="" />
                                </div>
                                <p className={id === buttonid ? "side_menutext mx-2" : "mx-2"}>{labelText}</p>
                              </div>
                              {/* {sublist && <img src={downArrow} className={`downArrow ${listShow[listRef] ? "upArrow" : ""}`} alt="logo" />} */}
                            </Link>
                          </li>
                        )}

                        {sublist && (
                          <li className="p-0">
                            <ul className={listShow[listRef] ? "submenu" : ""}>
                              {list.sublist.map((sublistItem, subId) => (
                                <li key={sublistItem.id}>
                                  <NavLink to={`${sublistItem.path}`} activeClassName="active" className="subnavlist">
                                    <div className="subnavIcon d-flex">
                                      <div className="me-3">
                                        <img src={window.location.pathname === sublistItem.path ? sublistItem.src : sublistItem.src1} alt="" />
                                      </div>
                                      <p
                                        className={window.location.pathname === sublistItem.path ? "side_menutext" : ""}
                                        onClick={handletoggle}
                                      >
                                        {sublistItem.label}
                                      </p>
                                    </div>
                                  </NavLink>
                                </li>
                              ))}
                            </ul>
                          </li>
                        )}
                      </React.Fragment>
                    );
                  })}
              </ul>
            </Suspense>
          </div>
        )}
      </div>
    </>
  );

}

// FUNCTIONS
export function SidebarResponsive(props) {
  const btnRef = React.useRef();

  return <></>;
}
// PROPS

export default Sidebar;
