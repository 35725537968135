import React, { useEffect, useState } from 'react';
import Loader from "components/loader";
import Button from "components/buttons/ADD";
import { USERSTATUSDATA, ViewReturnFleetData, VechiceStatusData } from 'utils/Services';
import moment from 'moment';
import nodata from "../../../assets/img/nodata.png"

function ViewUserData({ closeModal, data, reload }) {

    const [loading, setLoading] = useState(false);
    const [allData, setAllData] = useState({});
    const [location, setLocation] = useState('');
    
    
    // Geolocation
    //     const codeLatLng = (lat, lng) => {
    //         // Use a reverse geocoding API like Google Maps to get address from lat & lng
    //         fetch(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${'AIzaSyCBz4jdpZfM5QaAgCjG2M0CProFLg90SNg'}`)
    //             .then(response => response.json())
    //             .then(data => {
    //                 if (data.status === 'OK') {
    //                     setLocation(data.results[0]?.formatted_address || "Location not found");
    //                 } else {
    //                     setLocation("Location not found");
    //                 } 
    //             })
    //             .catch(error => {
    //                 console.error("Error fetching location:", error);
    //                 setLocation("Location not found");
    //             });
    //     };

    //   const cache = new Map();

    // const fetchData = async () => {
    //     setLoading(true);

    //     // Check if data is in cache
    //     if (cache.has(data)) {
    //         const cachedResult = cache.get(data);
    //         setAllData(cachedResult);
    //         console.log(cachedResult, 'Cached data');

    //         // Trigger reverse geocoding if latitude and longitude exist
    //         if (cachedResult.latitude && cachedResult.longitude) {
    //             codeLatLng(cachedResult.latitude, cachedResult.longitude);
    //         }
    //         setLoading(false);
    //         return;
    //     }

    //     try {
    //         const result = await USERSTATUSDATA(`?customerId=${data}`);
    //         const fetchedData = result?.data;

    //         // Cache the fetched data
    //         cache.set(data, fetchedData);
    //         setAllData(fetchedData);
    //         console.log(fetchedData, 'Fetched data');

    //         // Trigger reverse geocoding if latitude and longitude exist
    //         if (fetchedData?.latitude && fetchedData?.longitude) {
    //             codeLatLng(fetchedData.latitude, fetchedData.longitude);
    //         }
    //     } catch (error) {
    //         console.error('Failed to fetch data:', error);
    //         // Handle errors (e.g., show an error message to the user)
    //     } finally {
    //         setLoading(false);
    //     }
    // };


    const fetchData = async () => {
        try {
            const result = await ViewReturnFleetData(data);

            if (result?.res?.status === 200) {
                setAllData(result?.res?.data);
            }
        } catch (err) {
            console.log(err);
        }
    };



    useEffect(() => {
        fetchData();
    }, []);


    const togglefunction = async (data) => {
        try {
            const response = await VechiceStatusData({
                customerId: data.customerId,
            });
            if (response.res.status === 200) {
                // Handle success logic here
                alert("Accepted")
                closeModal()
            }
        } catch (error) {
            console.error("Error updating status:", error);
        }
        closeModal()
    };


    const handleclose = () => {
        closeModal()
    }
    return (
        <div className="container-fluid">
            <div className='row'>
                <Loader loading={loading} />

                {
                    allData === null ?
                        <>
                            <div style={{display:"flex",justifyContent:"center" , alignItems:"center",flexDirection:"column"}}>
                                    
                                    <img src={nodata} height={"100px"} className='nodataimage' width={"100px"}></img>
                                    No Data Found
                            </div>
                        </>
                        :
                        <>
                            <div className='d-flex align-items-center justify-content-between mb-3'>
                            <h5>Vehicle Status Request</h5>
                            </div>
                            <div className='my-3'>
                                <div className='row d-flex border_box'>
                                    <div className='col-lg-12' >
                                        <div className=''>
                                            <div className='vehicalno'>
                                                <h6 className='vehicalid'>Registration Number : </h6>
                                                <div>{allData?.FleetNo}</div>
                                            </div>

                                        </div>
                                    </div>
                                    <div className='col-lg-12' >
                                        <div className=''>
                                            <div className='vehicalno'>
                                                <h6 className='vehicalid'>Description : </h6>
                                                <div>Surrender</div>
                                                {/* <div style={{width:'65%'}}>{location || `${allData?.latitude}, ${allData?.longitude}`}</div> */}
                                            </div>
                                        </div>
                                    </div>



                                    <div className='col-lg-12' >
                                        <div className=''>
                                            <div className='vehicalno'>
                                                <h6 className='vehicalid'>Status : </h6>
                                                <div>{allData?.status}</div>
                                            </div>

                                        </div>
                                    </div>


                                    <div className='col-lg-12' >
                                        <div className=''>
                                            <div className='vehicalno'>
                                                <h6 className='vehicalid'>DateTime : </h6>
                                                <div>{moment(allData?.updatedAt).format("D MMMM YYYY h:mm a")}</div>

                                            </div>

                                        </div>
                                    </div>


                                    <div className='d-flex justify-content-end gap-3 pb-0 vehicalno'>
                                        <div onClick={() => togglefunction(allData)}>
                                            <Button text="Accept" />

                                        </div>
                                        <div>
                                            <button className='add_btn rejectcolor' onClick={handleclose} >Reject</button>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </>


                }



            </div>
        </div>
    );
}

export default ViewUserData;