import React from 'react';

const DefaultTable = ({ columns, data }) => {
    return (
        <div className="w-100">
            <div className="">
                {data.map((row, rowIndex) => (
                    <div key={rowIndex} className="custom_tile_table">
                        {columns.map((column, columnIndex) => (
                            <div key={columnIndex}>
                                {column.accessor(row)}
                            </div>
                        ))}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default DefaultTable;
