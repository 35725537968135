import React from 'react'

function FallBack() {
    return (
        <div className='d-flex justify-content-center align-items-center mb-5'><h6 className='text-center'>

            No Content Available
        </h6></div>
    )
}

export default FallBack