import React, { useState, useEffect } from 'react';
import { CustomTabs, CustomTabPanel } from 'components/Tabs';
import Genral from 'components/settingDetails/Notification';
import Customizations from 'components/settingDetails/Customizations';
import Profile from 'components/settingDetails/Profile';


import { useParams } from "react-router-dom";

function Raci_Role() {
    // Get the active tab from local storage or default to the first tab
    const initialTab = parseInt(localStorage.getItem('activeTabservice')) || 0;
    const params = useParams();
    // State to manage the active tab
    const [activeTab, setActiveTab] = useState(initialTab);

    // Update local storage when the active tab changes
    useEffect(() => {
        localStorage.setItem('activeTabservice', parseInt(activeTab));
    }, [activeTab]);

    return (
        <div>
            <div className="container-fluid pb-5">

                <CustomTabs activeTab={activeTab} onChange={setActiveTab} setActiveTab={setActiveTab} >
                    <CustomTabPanel tabLabel="Notification" key="1">
                        <Genral data={params.id} />
                    </CustomTabPanel>
                    <CustomTabPanel tabLabel="Customizations" key="2">
                        <Customizations data={params.id} />
                    </CustomTabPanel>
                    <CustomTabPanel tabLabel="Profile" key="3">
                        <Profile data={params.id} />
                    </CustomTabPanel>
                </CustomTabs>
            </div>
        </div>
    );
}

export default Raci_Role;