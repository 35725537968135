// SelectBox.js
import React from "react";
import { Field, ErrorMessage } from "formik";
import Select from 'react-select';
import TextError from "../Errros";

function SelectBox(props) {
    const { name, prevalue, placeholder, options, disabled, setfield, label, required } = props;
    const selectedOption = options?.find(option => option?.id == prevalue);

    return (
        <div>
            <Field name={name}>
                {({ field, form }) => (
                    <div className='row'>
                        <div className="text_left">
                            {
                                label && (
                                    <label>
                                        {label}
                                        {required && <span className="gl-form-asterisk"></span>}
                                    </label>
                                )
                            }
                            <Select
                                styles={{
                                    control: (baseStyles, state) => ({
                                        ...baseStyles,
                                        borderColor: state.isFocused ? '' : '',
                                        borderWidth: '1px',
                                        boxShadow: state.isFocused ? '0 0 0 1px black' : 'none',
                                    }),
                                }}
                                onChange={res => {

                                    const selectedValue = res ? res.id : null;
                                    form.setFieldValue(name, selectedValue);
                                    { setfield && setfield(selectedValue) }
                                }}
                                placeholder={placeholder}
                                options={options}
                                value={selectedOption}
                                isDisabled={disabled}
                                isClearable={true}
                            />

                            <ErrorMessage component={TextError} name={name} />
                        </div>
                    </div>
                )}
            </Field>
        </div>
    );
}

export default SelectBox;