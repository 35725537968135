import React from "react";
import ReactDOM from "react-dom";
import "assets/css/global.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { Provider } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
import App from "layouts/app";
import store from "utils/store";
import NetworkServices from "utils/NetworkServices";
NetworkServices();
ReactDOM.render(
  <Provider store={store}>
    <React.StrictMode>
      <Router>
        <App />
      </Router>
    </React.StrictMode>
  </Provider>,

  document.getElementById("root")
);
