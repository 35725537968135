import React, { useEffect, useState } from "react";
import { Formik, Form } from "formik"; // Import Formik and Form
import FormControl from "components/controls/FormControl";
import Loader from "components/loader";
import Button from "components/buttons/Default";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { VIEWDOCKYARDAPI } from "utils/Services";
import { PLANDETAILSVIEWAPI, PaymentsAPI } from "utils/Services";
import { VIEWUSEREAPI } from "utils/Services";

export default function AddTransaction() {
  const [loading, setLoading] = useState(false);
  const [allData, setAllData] = useState([]);
  const [data, setData] = useState([]);
  const [dockyard, setDockyard] = useState([]);
  const [selectState, setSelectState] = useState(null);
  const [options, setOptions] = useState({
    type: [
      {
        id: "1",
        label: "Pay Per Use",
        value: "Pay Per Use",
      },
      {
        id: "2",
        label: "Subscription",
        value: "Subscription",
      },
    ],
  });
  const fetchDockyard = async () => {
    try {
      setLoading(true);
      const result = await VIEWDOCKYARDAPI();
      if (result?.res?.status === 200) {
        const _data = result?.res?.data.map((res) => ({
          id: res?.id,
          label: res?.dockyard_name,
          value: res?.dockyard_name,
        }));
        setDockyard(_data);
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.error(err);
    }
  };

  const fetchPlans = async () => {
    try {
      setLoading(true);
      const result = await PLANDETAILSVIEWAPI();
      if (result?.res?.status === 200) {
        const _data = result?.res?.data.map((res) => ({
          id: res?.id,
          label: res?.plan_name,
          value: res?.plan_name,
          type: res?.planType,
        }));
        setOptions((prev) => ({
          ...prev,
          plans: _data,
        }));
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.error(err);
    }
  };

  const filterPlans = async () => {
    if (selectState) {
      try {
        setLoading(true);

        if (selectState == "1") {
          const result =
            options &&
            options?.plans?.filter((res) => res?.type == "Pay Per Use");

          setOptions((prev) => ({
            ...prev,
            plans: result,
          }));
          setLoading(false);
        } else {
          const result =
            options &&
            options?.plans?.filter((res) => res?.type == "Subscription");

          setOptions((prev) => ({
            ...prev,
            plans: result,
          }));
          setLoading(false);
        }
      } catch (err) {
        setLoading(false);
        toast.error("Plan Type not Selected");
      }
    } else {
      setTimeout(() => {
        setLoading(false);
      }, "5000");
    }
  };
  useEffect(() => {
    fetchDockyard();
    fetchPlans();
    fecthData_1();
  }, []);

  useEffect(() => {
    filterPlans();
  }, [selectState, options?.plans]);
  const fecthData_1 = async () => {
    try {
      setLoading(true);
      const result = await VIEWUSEREAPI();
      if (result?.res?.status == 200) {
        const _data = result?.res?.data.map((res) => ({
          id: res?.id,
          label: res?.full_name,
          value: res?.id,
        }));
        setData(_data);
        setLoading(false);
      } else {
        setLoading(false);
      }
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  const handle = async (values) => {
    try {
      setLoading(true);
      const result = await PaymentsAPI(values.formData);
      setLoading(false);
    } catch (err) {}
  };
  return (
    <div className="container pb-5">
      <Formik
        initialValues={{
          formData: {
            order_no: "",
            billing_no:"",
            transaction_id: "",
            amount: "",
            planType: "",
            pickupLocation: "",
            timeDuration: "",
            startDateTime: "",
            is_booking: true,
            no_days: "",
            comment:""
          },
        }}
        onSubmit={handle}
        // validationSchema={validationSchema}
      >
        {(formik) => (
          <Form className="form_default text_left">
            <div className="mb-3">
              <FormControl control="FormHead" title="Create New Transaction" />
            </div>
            <div className="mb-3">
              <div className="row g-3">
                <div className="col-lg-6">
                  <FormControl
                    control="InputLabel"
                    name="formData.billing_no"
                    type="text"
                    label="Billing To"
                    required={true}
                  />
                </div>
                <div className="col-lg-6">
                  <FormControl
                    control="SelectBox"
                    name={"formData.customerId"}
                    label={"Customer"}
                    options={data}
                    prevalue={formik.values.formData.customerId}
                  />
                </div>
                <div className="col-lg-6">
                  <FormControl
                    control="InputLabel"
                    name="formData.order_no"
                    type="text"
                    label="Order No"
                    required={true}
                  />
                </div>
                <div className="col-lg-6">
                  <FormControl
                    control="InputLabel"
                    name="formData.transaction_id"
                    type="text"
                    label="Transaction No"
                    required={true}
                  />
                </div>
                <div className="col-lg-6">
                  <FormControl
                    control="InputLabel"
                    name="formData.transaction_date"
                    type="date"
                    label="Transaction Date"
                    required={true}
                  />
                </div>
                <div className="col-lg-6">
                  <FormControl
                    control="InputLabel"
                    name="formData.security_deposit"
                    type="number"
                    label="Security Deposit"
                  />
                </div>
                <div className="col-lg-6">
                  <FormControl
                    control="InputLabel"
                    name="formData.amount"
                    type="number"
                    label="Wallet Amount"
                  />
                </div>
                <div className="col-lg-6">
                  <FormControl
                    control="SelectBox"
                    name={"formData.pickupLocation"}
                    label={"Dockyard Location"}
                    options={dockyard}
                    prevalue={formik.values.formData.pickupLocation}
                  />
                </div>
                <div className="col-lg-6">
                  <FormControl
                    control="InputLabel"
                    name="formData.timeDuration"
                    type="number"
                    label="Duration"
                  />
                </div>
                <div className="col-lg-6">
                  <FormControl
                    control="SelectBox"
                    name={"formData.planType"}
                    label={"Plan Type"}
                    options={options.type}
                    prevalue={formik.values.formData.planType}
                    setfield={setSelectState}
                  />
                </div>
                <div className="col-lg-6">
                  <FormControl
                    control="SelectBox"
                    name={"formData.PlanDetailId"}
                    label={"Plans"}
                    options={options.plans}
                    prevalue={formik.values.formData.planDetailId}
                  />
                </div>
                <div className="col-lg-6">
                  <FormControl
                    control="InputLabel"
                    name="formData.no_days"
                    type="number"
                    label="Total Days"
                  />
                </div>
                <div className="col-lg-6">
                  <FormControl
                    control="InputLabel"
                    name="formData.startDateTime"
                    type="datetime-local"
                    label="Date & Time"
                  />
                </div>
                <div className="col-lg-6">
                  <FormControl
                    control="TextArea"
                    name="formData.comment"
                    type="textarea"
                    label="Comment"
                  />
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-end">
              <div className="col-lg-2 mx-5">
                <Button text="Cancel" type="button" isCancel={true} />
              </div>
              <div className="col-lg-2">
                <Button text="Save" type="submit" />
              </div>
            </div>
            <div>
              <p>all asterisk (*) field are required</p>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}
