import React, { useEffect, useState } from 'react';
import { Formik, Form } from 'formik';  // Import Formik and Form
import FormControl from 'components/controls/FormControl';
import Loader from "components/loader"
import Button from 'components/buttons/ADD';
import { GETSTATEAPI, GETCITYAPI, PLANDETAILSCREATEAPI } from 'utils/Services';
import * as yup from 'yup';
import { toast } from 'react-toastify';




function PerUse({ closeModal, reload }) {
    const [options, setOptions] = useState({
        state: [], city: [], status: [{
            id: true,
            label: 'Active',
            value: true
        }, {
            id: false,
            label: 'InActive',
            value: false
        }]
    });
    const [loading, setLoading] = useState(false)
    const [selectState, setSelectState] = useState()

    const validationSchema = yup.object().shape({
        formData: yup.object().shape({
            plan_name: yup.string().required('Plan name is required'),
            feature: yup.string().required('Feature  is required'),
            amount: yup.number().required('Amount is required').positive('Amount must be a positive number').integer('Amount must be an integer'),
            range: yup.number()
                .typeError('Range must be a number')
                .required('Range is required')
                .min(1, 'Range must be at least 1'),

            max_speed: yup.number()
                .typeError('Max Speed must be a number')
                .required('Max Speed is required')
                .min(1, 'Max Speed must be at least 1'),

            is_active: yup.boolean().required(' Status is required'),
        }),
    });
    const handle = async (values) => {
        setLoading(true)

        try {
            const data = { ...values.formData, 'securityDeposit': parseInt(3 * values.formData.amount), amount: parseInt(values.formData.amount) }
            const result = await PLANDETAILSCREATEAPI(data);

            if (result?.status == 201) {
                toast.success(result?.message)
                setLoading(false)
                { reload && reload() }
                closeModal()

            }


        }
        catch (err) {
            setLoading(false)
            toast.error(err?.message)
        }
    }

    return (
        <div className='row'>
            <Loader loading={loading} />
            <div className='com_modal'>
                {/* Use Formik wrapper */}
                <Formik
                    enableReinitialize={true}
                    initialValues={{
                        formData: {
                            "planType": "Pay Per Use",
                            "plan_name": "",
                            "feature": "",
                            "securityDeposit": "",
                            "amount": "",
                            "range": "",
                            "max_speed": "",
                            "is_active": ''

                        },
                    }}
                    validationSchema={validationSchema}
                    onSubmit={handle}

                >
                    {(formik) => (
                        // Wrap your content in the Form component

                        <Form className='form_default text_left'>
                            <div className='mb-3'>
                                <FormControl control="FormHead" title="Add New Vehicle Category" />
                            </div>
                            <div className='mb-3'>
                                <div className='row g-3'>
                                    <div className='col-lg-6'>
                                        <FormControl
                                            control="SelectBox"
                                            name={"formData.is_active"}
                                            label={"Vehicle Category Status"}
                                            options={options.status}
                                            required={true}



                                        />
                                    </div>
                                    <div className='col-lg-6'>
                                        <FormControl
                                            control="InputLabel"
                                            name={"formData.plan_name"}
                                            type={"text"}
                                            label={"Vehicle Category Name"}
                                            required={true}

                                        />
                                    </div>

                                    <div className='col-lg-6'>
                                        <FormControl
                                            control="InputLabel"
                                            name={"formData.range"}
                                            type={"text"}
                                            label={"Range (Km)"}
                                            required={true}

                                        />
                                    </div>

                                    <div className='col-lg-6'>
                                        <FormControl
                                            control="InputLabel"
                                            name={"formData.max_speed"}
                                            type={"text"}
                                            label={"Max. Speed (kmph)"}
                                            required={true}

                                        />
                                    </div>
                                    <div className='col-lg-12   '>
                                        <FormControl
                                            control="Editor"
                                            name={"formData.feature"}
                                            type={"text"}
                                            label={"Feature (Tag field)"}
                                            required={true}

                                        />
                                    </div>



                                    {/* <div className='col-lg-6'></div> */}

                                    {/* <div className='col-lg-6'>
                                        <FormControl
                                            control="InputLabel"
                                            name={"formData.label"}
                                            type={"hidden"}
                                            label={"Amount"}
                                            
                                        />
                                    </div> */}
                                    {/* <div className='col-lg-6'>
                                    </div> */}
                                    <div className='col-lg-6'>
                                        <FormControl
                                            control="InputLabel"
                                            name={"formData.amount"}
                                            type={"number"}
                                            label={"Amount (In Rupees)"}
                                            required={true}

                                        />
                                    </div>
                                    {/* <div className='col-lg-6'>
                                    </div> */}
                                    {/* <div className='col-lg-6'>
                                        <FormControl
                                            control="Checkbox"
                                            name={"formData.discount"}
                                            label={"Enable Discount Code"}
                                        />
                                    </div> */}
                                    {/* <div className='col-lg-6'>
                                    </div> */}
                                    {/* <div className='col-lg-6'>
                                        <FormControl
                                            control="InputLabel"
                                            name={"formData.label"}
                                            type={"text"}
                                            label={"Discount Code"}
                                        />
                                    </div> */}

                                    <div className='col-lg-6'>
                                        <FormControl
                                            control="CalInput"
                                            name={"formData.securityDeposit"}
                                            type={"number"}
                                            value={formik?.values?.formData?.amount * 3}
                                            disabled
                                            label={"Security Deposit (In Rupees)"}
                                        />
                                    </div>
                                    {/* <div className='col-lg-6'>
                                        <FormControl
                                            control="InputLabel"
                                            name={"formData.label"}
                                            type={"hidden"}
                                            label={"Notification Threshold"}
                                        />
                                    </div>
                                    <div className='col-lg-6'>
                                    </div>  

                                    <div className='col-lg-6'>
                                        <FormControl
                                            control="InputLabel"
                                            name={"formData.label8"}
                                            type={"text"}
                                            label={"Balance"}
                                        />
                                    </div>
                                    <div className='col-lg-6'>
                                        <FormControl
                                            control="InputLabel"
                                            name={"formData.label78"}
                                            type={"text"}
                                            label={"Kilometers Left"}
                                        />
                                    </div>
                                    <div className='col-lg-6'>
                                        <FormControl
                                            control="InputLabel"
                                            name={"formData.label5"}
                                            type={"text"}
                                            label={"Time Left"}
                                        />
                                    </div>

                                    <div className='col-lg-6'>
                                    </div> */}
                                </div>

                            </div>

                            <div className='d-flex justify-content-end'>
                                <div className='col-lg-2 mx-5' onClick={closeModal}>
                                    <Button text="Cancel" type="button" isCancel={true} />
                                </div>

                                <div className='col-lg-2'>
                                    <Button text="Save" type="submit" />
                                </div>

                            </div>
                            <div>
                                <p>
                                    all asterisk (*) field are required
                                </p>
                            </div>

                        </Form>
                    )}
                </Formik>
            </div>
        </div >
    );
}

export default PerUse;
