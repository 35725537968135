import React from 'react'

function SubHeading({ title }) {
    return (
        <div className='row'>
            <h6 className='sub_head'>
                {title}
            </h6>
        </div>
    )
}

export default SubHeading