import React, { useEffect, useState } from 'react';
import { Formik, Form } from 'formik';  // Import Formik and Form
import FormControl from 'components/controls/FormControl';
import Loader from "components/loader"
import Button from 'components/buttons/ADD';
import { FLEETEDITAPI, VIEWDOCKYARDAPI, PLANDETAILSVIEWAPI } from 'utils/Services';
import * as Yup from 'yup';
import { toast } from 'react-toastify';



function GeneralVehicle({ nextStep, setDetails, closeModal, id, details, prevStep }) {



    const [loading, setLoading] = useState(false)
    const [selectState, setSelectState] = useState('')
    const [dockyard, setDockyard] = useState([]);
    const [plans, setPlans] = useState([])
    const [options, setOptions] = useState(
        {
            'type': [{
                id: '1', label: 'Pay Per Use', value: 'Pay Per Use',

            },
            {
                id: '2', label: 'Subscription', value: 'Subscription',
            }]
        }
    )

    const validationSchema = Yup.object().shape({
        formData: Yup.object().shape({


            vin_no: Yup.string().required('VIN is required'),
            vehicle_make: Yup.string().nullable(),
            dockyardId: Yup.string().nullable(),
            vehicle_color: Yup.string().nullable(),
            planDetailId: Yup.string().nullable(),
            planType: Yup.string().nullable(),
            kiloMeter_driven: Yup.string().nullable(),
            manufacture_date: Yup.date().nullable(),
            purchase_date: Yup.date().nullable(),
            vehicle_deployment_date: Yup.date().nullable(),
            insurance_expiry_date: Yup.date().nullable(),

            insurance_no: Yup.string().nullable(),
            // geo_fencingID: Yup.string()


        }),
    });
    const handle = async (values) => {
        const data_4 = { ...values.formData }
        setLoading(true)
        try {
            const data = { ...values.formData };
            setDetails((pre) =>
            ({
                ...pre,
                step0: data,

            }))
            nextStep()
        }
        catch (err) {
            setLoading(false)
            toast.error(err?.message)
        }
    }
    const fetchData = async () => {
        if (id) {
            setLoading(true)
            const result = await FLEETEDITAPI(id);

            if (result?.res?.status == 200) {
                setDetails(result?.res?.data);

                setSelectState(result?.res?.data?.planType)

                setLoading(false)

            }

            else {
                setLoading(false)
            }


        }
    }
    const fetchDockyard = async () => {

        try {

            setLoading(true)
            const result = await VIEWDOCKYARDAPI();

            if (result?.res?.status == 200) {
                const _data = result?.res?.data.map((res) => {
                    return ({
                        id: res?.id,
                        label: res?.dockyard_name,
                        value: res?.dockyard_name
                    })
                })


                setDockyard(_data);
                setLoading(false)

            }



        }
        catch (err) {

        }

    }

    const fetchPlans = async () => {

        try {

            setLoading(true)
            const result = await PLANDETAILSVIEWAPI();

            if (result?.res?.status == 200) {

                const _data = result?.res?.data.map((res) => {
                    return ({
                        id: res?.id,
                        label: res?.plan_name,
                        value: res?.plan_name,
                        type: res?.planType,
                    })
                })

                setOptions((pre) =>
                ({
                    ...pre,
                    plans: _data,

                }))
                setLoading(false)

            }



        }
        catch (err) {

        }
    }

    const filterPlans = async () => {

        if (selectState) {

            try {

                setLoading(true)

                if (selectState == '1') {

                    const result = options && options?.plans?.filter((res) => res?.type == 'Pay Per Use')

                    setPlans(result);
                    setLoading(false)
                }
                else {

                    const result = options && options?.plans?.filter((res) => res?.type == 'Subscription')

                    setPlans(result);
                    setLoading(false)
                }


            }




            catch (err) {
                setLoading(false)
                toast.error("Plan Type not Selected")
            }
        }
        else {
            setTimeout(() => {
                setLoading(false)
            }, "5000");

        }

    }
    useEffect(() => {
        fetchData()

    }, [id])

    useEffect(() => {
        fetchDockyard()
        fetchPlans()

    }, [])
    useEffect(() => {
        filterPlans()
    }, [selectState, options?.plans])

    return (
        <div className='row'>
            <Loader loading={loading} />
            <div className='com_modal'>
                {/* Use Formik wrapper */}
                <Formik
                    enableReinitialize={true}
                    initialValues={{
                        formData: {
                            "registration_no": details?.registration_no,
                            "rto_no": details?.rto_no,
                            "vin_no": details?.vin_no,
                            "vehicle_insurance_no": details?.vehicle_insurance_no,
                            "insurance_expiry_date": details?.insurance_expiry_date,
                            "vehicle_make": details?.vehicle_make,
                            "dockyardId": details?.dockyardId,
                            "vehicle_color": details?.vehicle_color,
                            "planType": details?.planType,
                            "planDetailId": details?.planDetailId ? details?.planDetailId : 0,
                            'vehicle_category': details?.vehicle_category,
                            "kiloMeter_driven": details?.kiloMeter_driven,
                            "manufacture_date": details?.manufacture_date,
                            "purchase_date": details?.purchase_date,
                            "insurance_no": details?.insurance_no,
                            "vehicle_deployment_date": details?.vehicle_deployment_date,
                            "geo_fencingID": details?.geo_fencingID,
                            'motorID': details?.motorID,
                            'charger_no': details?.charger_no,
                            'controller_no': details?.controller_no,
                            'fleet_no' : details?.fleet_no,
                            'lock_status': details?.lock_status,
                            'availability_status': details?.availability_status,



                        },
                    }}
                    validationSchema={validationSchema}
                    onSubmit={handle}

                >
                    {(formik) => (
                        // Wrap your content in the Form component
                        <Form className='form_default text_left'>
                            {
                                details && <div>
                                    <div className='mb-3'>
                                        <div className='row g-3'>

                                            <div className='col-lg-6'>
                                                <FormControl
                                                    control="InputLabel"
                                                    name={"formData.registration_no"}
                                                    type={"text"}
                                                    label={"Zeway Registration number"}

                                                />
                                            </div>

                                            <div className='col-lg-6'>
                                                <FormControl
                                                    control="InputLabel"
                                                    name={"formData.rto_no"}
                                                    type={"text"}
                                                    label={"RTO Registration Number"}
                                                />
                                            </div>
                                            <div className='col-lg-6'>
                                                <FormControl
                                                    control="InputLabel"
                                                    name={"formData.vin_no"}
                                                    type={"text"}
                                                    label={"VIN Number"}
                                                    required={true}

                                                />
                                            </div>
                                            <div className='col-lg-6'>
                                                <FormControl
                                                    control="InputLabel"
                                                    name={"formData.vehicle_insurance_no"}
                                                    type={"text"}
                                                    label={"Vehicle Insurance Number"}


                                                />
                                            </div>
                                            <div className='col-lg-6'>
                                                <FormControl
                                                    control="InputLabel"
                                                    name={"formData.manufacture_date"}
                                                    type={"Date"}
                                                    label={"Date of Manufacture"}

                                                />
                                            </div>
                                            <div className='col-lg-6'>
                                                <FormControl
                                                    control="InputLabel"
                                                    name={"formData.purchase_date"}
                                                    type={"Date"}
                                                    label={"Date of Purchase"}

                                                />
                                            </div>

                                            <div className='col-lg-6'>
                                                <FormControl
                                                    control="InputLabel"
                                                    name={"formData.insurance_expiry_date"}
                                                    type={"Date"}
                                                    label={"Insurance Expiry Date"}


                                                />
                                            </div>

                                            <div className='col-lg-6'>
                                                <FormControl
                                                    control="InputLabel"
                                                    name={"formData.vehicle_deployment_date"}
                                                    type="date"
                                                    label={"Deployed Date"}

                                                />
                                            </div>
                                            <div className='col-lg-6'>
                                                <FormControl
                                                    control="InputLabel"
                                                    name={"formData.vehicle_make"}
                                                    type={"text"}
                                                    label={"Vehicle Make"}


                                                />
                                            </div>
                                            <div className='col-lg-6'>
                                                <FormControl
                                                    control="SelectBox"
                                                    name={"formData.dockyardId"}
                                                    label={"Dockyard Location"}
                                                    options={dockyard}
                                                    prevalue={formik.values.formData.dockyardId}





                                                />
                                            </div>
                                            <div className='col-lg-6'>
                                                <FormControl


                                                    control="InputLabel"
                                                    name={"formData.vehicle_color"}
                                                    type={"text"}
                                                    label={"Vehicle Color"}

                                                />
                                            </div>
                                            <div className='col-lg-6'>


                                                <FormControl
                                                    control="SelectBox"
                                                    name={"formData.planType"}
                                                    label={"Plan Type"}
                                                    options={options.type}
                                                    prevalue={formik.values.formData.planType}
                                                    setfield={setSelectState}



                                                />
                                            </div>
                                            <div className='col-lg-6'>

                                                <FormControl
                                                    control="SelectBox"
                                                    name={"formData.planDetailId"}
                                                    label={"Plans"}
                                                    options={plans}
                                                    prevalue={formik.values.formData.planDetailId}


                                                />
                                            </div>
                                            <div className='col-lg-6'>
                                                <FormControl
                                                    control="InputLabel"
                                                    name={"formData.kiloMeter_driven"}
                                                    type={"text"}
                                                    label={"Kilometers Driven"}

                                                />
                                            </div>





                                        </div>

                                    </div>

                                    <div className='d-flex justify-content-end'>
                                        <div className='col-lg-2 mx-5' onClick={closeModal}>
                                            <Button text="Cancel" type="button" isCancel={true} />
                                        </div>

                                        <div className='col-lg-2'>
                                            <Button text="Next" type="submit" />
                                        </div>

                                    </div>
                                </div>
                            }



                            <div>
                                <p>
                                    all asterisk (*) field are required
                                </p>
                            </div>

                        </Form>
                    )}
                </Formik>
            </div>
        </div >
    );
}

export default GeneralVehicle;
