import React, { useEffect, useContext,useState } from 'react';
import { Formik, Form } from 'formik';  // Import Formik and Form
import FormControl from 'components/controls/FormControl';
import Loader from "components/loader"
import Button from 'components/buttons/ADD';
import { GETSTATEAPI, GETCITYAPI, CREATECHANNELPARTNERAPI } from 'utils/Services';
import { ModalContext } from 'utils/Contexts/modalContext';

import * as Yup from 'yup';
import { toast } from 'react-toastify';
import EditChannelPartner from 'components/modals/EditChannelPartner';
import SettingEdit from 'components/modals/SettingEdit'



function Profile({  reload }) {
    const [loading, setLoading] = useState(false)
    const modalContext = useContext(ModalContext);
    const { closeModal, handleModalData } = modalContext;

    const [selectState, setSelectState] = useState()
    // const fetchState = async () => {
    //     setLoading(true)
    //     try {
    //         const result = await GETSTATEAPI();
    //         if (result?.res?.status == 200) {
    //             const data = result?.res?.data?.map((res) => ({
    //                 id: res?.id,
    //                 label: res?.name,
    //                 value: res?.name
    //             }));

             
    //             setLoading(false)
    //         }
    //         else {
    //             setLoading(false)
    //         }
    //     }
    //     catch (err) {
    //         console.log(err);
    //         setLoading(false)

    //     }



    // }
    // const fetchCity = async () => {

    //     if (selectState !== '' && selectState !== null && selectState !== undefined) {
    //         const data = {
    //             "state_id": selectState
    //         };

    //         try {
    //             setLoading(true);
    //             const result = await GETCITYAPI(data);

    //             if (result?.status === 200) {
    //                 const data = result?.data?.map((res) => ({
    //                     id: res?.id,
    //                     label: res?.name,
    //                     value: res?.name
    //                 }));
    //                 setLoading(false);
    //             } else {
    //                 setLoading(false);
    //             }
    //         } catch (err) {
    //             console.log(err);
    //             setLoading(false);
    //         }
    //     }


    // }
    // useEffect(() => {
    //     fetchState()
    // }, [])
    // useEffect(() => {
    //     fetchCity()
    // }, [selectState])
    const validationSchema = Yup.object().shape({
        // formData: Yup.object().shape({
        //     channel_partner_name: Yup.string().required('Name is required'),
        //     stateId: Yup.string().required('State is required'),
        //     cityId: Yup.string().required('Password is required'),
        //     address1: Yup.string().required('Email is required'),
        //     zip_code: Yup.number()
        //         .required('Number is required')
        //         .typeError('Number must be a number')
        //         .integer('Number must be an integer')
        //         .min(1000000000, 'Number must be exactly 10 digits')
        //         .max(9999999999, 'Number must be exactly 10 digits'),
        //     is_active: Yup.string().required(' Status is required'),
        //     gst_no: Yup.string()
        //         .matches(
        //             /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}[Z]{1}[0-9A-Z]{1}$/,
        //             'Invalid GST Number'
        //         )
        //         .required('GST Number is required')
        // }),
    });
    const handle = async (values) => {
        setLoading(true)
        try {
            const data = { ...values.formData }
            const result = await CREATECHANNELPARTNERAPI(data);

            toast.success(result?.message)
            setLoading(false)
            { reload && reload() }
            closeModal()
        }
        catch (err) {
            setLoading(false)
            toast.error(err?.message)
        }
    }

    const handlemall = (data, info) => {
        const EditDocumnet = <SettingEdit closeModal={closeModal} data={info}  />;
          
                handleModalData(EditDocumnet, "md");
              
    }

    

    return (
        <div className='row mt-5'>
            <Loader loading={loading} />

            <div className='col-3'>
                <div className='custom_tile_tablesetting mb-3'>
                    <div>Email Alerts</div>
                    <div className="form-check form-switch">
                        <input
                            className="form-check-input"
                            type="checkbox"
                            role="switch"
                            id="emailAlerts"
                        />
                    </div>
                </div>
            </div>
            <div className='col-6'>

                <div className='com_modal custom_tile_tablesetting'>
                    <Formik
                        initialValues={{
                            formData: {
                                "channel_partner_name": "",
                                "stateId": "",
                                "cityId": "",
                                "address1": "",
                                "address2": "",
                                "zip_code": "",
                                "is_active": '',
                                "gst_no": ''
                            },
                        }}
                        validationSchema={validationSchema}
                        onSubmit={handle}

                    >
                        {(formik) => (
                            <Form className='form_default text_left py-2'>
                                <div className=''>
                                </div>
                                <div className='mb-3'>
                                    <div className='row g-3'>
                                     
                                        <div className='col-lg-6'>
                                            <FormControl
                                                control="InputLabel"
                                                name={"formData.channel_partner_name"}
                                                type={"text"}
                                                label={"Full Name"}
                                                required={true}

                                            />
                                        </div>

                                        <div className='col-lg-6'>
                                            <FormControl
                                                control="InputLabel"
                                                name="onjge"
                                                type={"text"}
                                                label={"Email Id"}
                                                required={true}

                                            />
                                        </div>
                                        <div className='col-lg-6'>
                                            <FormControl
                                                control="InputLabel"
                                                name={"formData.zip_code"}
                                                type={"number"}
                                                label={"Mobile Number"}
                                            />
                                        </div>

                                      
                                        <div className='col-lg-6'>
                                            <FormControl
                                                control="InputLabel"
                                                name={"formData.address2"}
                                                type={"text"}
                                                label={"Company Name"}
                                                required={true}

                                            />
                                        </div>

                                        <div className='col-lg-6'>
                                            <FormControl
                                                control="InputLabel"
                                                name="ewoirhiu"
                                                type={"password"}
                                                label={"Password"}
                                                required={true}

                                            />
                                        </div>
                                    </div>

                                </div>

                                <div className='d-flex justify-content-end'>
                                  
                                    <div className='col-lg-2'>
                                    <div onClick={() => handlemall("create")}>
                        <Button text="Edit" />

                    </div>
                                    </div>

                   


                                </div>
                               

                            </Form>
                        )}
                    </Formik>
                </div>
            </div>
        </div >
    );
}

export default Profile;
