// FleetMangement.tsx
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Provider } from "contexts/MultiStepContext";
import { withStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';
import GeneralVehicle from "./GeneralVehicle";
import IdentifiCation from "./IdentifiCation";
import BatteryDetails from "./BatteryDetails"
import SosDetails from "./SosDetails"
import FormControl from 'components/controls/FormControl';
const styles = theme => ({
    root: {
        width: '90%',
    },
    button: {
        marginTop: theme.spacing.unit,
        marginRight: theme.spacing.unit,
    },
    actionsContainer: {
        marginBottom: theme.spacing.unit * 2,
    },
    resetContainer: {
        padding: theme.spacing.unit * 3,
    },
    connector: {
        display: 'none',
    },
    step: {
        marginBottom: theme.spacing.unit * 5,
    },
    iconContainer: {
        transform: 'scale(2)',
        marginRight: theme.spacing.unit * 5,
    }
});
function getSteps() {
    return ['General Vehicle Information', 'Identification & Status', 'Battery Details & Notifications', 'IOT Device & Integrate SOS'];
}

const FleetMangement = ({ closeModal, reload, res }) => {



    const location = useLocation();
    const state = location.state;
    const [step, setStep] = useState(0);
    const [details, setDetails] = useState({
        // "registration_no": "",
        // "rto_no": "",
        // "vin_no": "",
        // "vehicle_insurance_no": "",
        // "insurance_expiry_date": "",
        // "vehicle_make": "",
        // "dockyardId": "",
        // "vehicle_color": "",
        // "vehicle_type": "",
        // "kiloMeter_driven": "",
        // "manufacture_date": "",
        // "purchase_date": "", 
        // "insurance_no": "",
        // "purchase_month": "",
        // "geo_fencingID": "",
        // "batteryID": "",
        // "battery_make": "",
        // "charge_state": "",
        // "Charge_percentage": "",
        // "voltage_level": "",
        availability_status: 'available',
        lock_status: 'locked',
        sos: 'no',

        
    });

    const nextStep = () => {
        setStep(step + 1);
    };

    const prevStep = () => {
        setStep(step - 1);
    };

    const renderStep = () => {
        switch (step) {
            case 0:

                return <GeneralVehicle nextStep={nextStep} details={details} setDetails={setDetails} closeModal={closeModal} id={res} />

            case 1:
                return <IdentifiCation nextStep={nextStep} prevStep={prevStep} setDetails={setDetails} details={details} closeModal={closeModal} />;
            case 2:
                return <BatteryDetails nextStep={nextStep} prevStep={prevStep} setDetails={setDetails} details={details} closeModal={closeModal} />;
            case 3:
                return <SosDetails nextStep={nextStep}   prevStep={prevStep} setDetails={setDetails} details={details} id={res} closeModal={closeModal} reload={reload} />

            default: return null;
        }
    };
    const steps = getSteps();
    return (
        <Provider value={{ details, setDetails, step, setStep }}>
            <div className='d-flex justify-content-between '>
                <div className='col-lg-3'>
                    <div className='mb-3 ml-5'>
                        <FormControl control="FormHead" title="Create\Update Fleet " />
                    </div>
                    {
                        res ? (<Stepper activeStep={4} orientation="vertical" connector={false}>
                            {steps.map((label) => (
                                <Step key={label} >
                                    <StepLabel>{label}</StepLabel>
                                </Step>
                            ))}
                        </Stepper>) : (<Stepper activeStep={step} orientation="vertical" connector={false}>
                            {steps.map((label) => (
                                <Step key={label} >
                                    <StepLabel>{label}</StepLabel>
                                </Step>
                            ))}
                        </Stepper>)
                    }

                </div>
                <div className='col-lg-8  mt-5'>
                    {renderStep(details, setDetails, step, setStep, res,prevStep)}

                </div>
            </div>

        </Provider>
    );
};

export default FleetMangement;
;