import React, { useEffect, useMemo, useState } from 'react';
import CustomTable from "components/Table"
import Loader from 'components/loader';
import { FleetHistoryData } from 'utils/Services';
import moment from 'moment';

function Vehiclehistory({ data }) {
    const [loading, setLoading] = useState(false)
    const [allData, setallData] = useState([])

    const FeedsColumns = useMemo(
        () => [
          
            {
                Header: "Fleet No.",
                accessor: (Customer) => Customer?.FleetNo ? Customer?.FleetNo : "--"
            },
            {
                Header: "Requested On",
                accessor: (Customer) => {
                    if (Customer?.updatedAt) {
                        const date = new Date(Customer.updatedAt);
                        return moment(Customer?.updatedAt).format("D MMMM YYYY h:mm a");
                    }
                    return "--";
                }
            },
            
            {
                Header: "Status",
                accessor: (Customer) => Customer?.status ? Customer?.status : "--"
            },
          
        ],
        []
    );

    const fetchData = async () => {
        try {
            setLoading(true);
            const result = await FleetHistoryData(data);
            console.log(result,'datshow');
            if (result?.res?.status === 200) {
                setallData(result?.res?.data);
            }
        } catch (err) {
            console.log(err);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, [data]);


 

    return (
        <div className="container-fluid pb-5">
            <div className='row'>
                <Loader loading={loading} />

                <div className='d-flex justify-content-between  my-4 '>
                    <div className='col-lg-6'>

                    </div>
                </div>

                {
                    allData.length > 0 && <div className='default_table'>
                        <CustomTable data={allData} columns={FeedsColumns} />
                    </div>
                }

            </div>
        </div>
    )
}

export default Vehiclehistory