import React, { useEffect, useState } from 'react';
import { Formik, Form } from 'formik';  // Import Formik and Form
import FormControl from 'components/controls/FormControl';
import Loader from "components/loader"
import Button from 'components/buttons/Default';
import {
    VIEWCREATECHANNELPARTNERAPI, CREATECHANNELPARTNERAPI,
    ADMINMANAGERAPI
} from 'utils/Services';
import * as Yup from 'yup';
import { toast } from 'react-toastify';



function CreateAdmin({ closeModal, reload }) {
    const [options, setOptions] = useState({
        status: [{
            id: true,
            label: 'Active',
            value: true
        }, {
            id: false,
            label: 'Inactive',
            value: false
        }], role: [{
            id: '1',
            label: 'Zeway Super Admin',
            value: 'super_admin'
        }, {
            id: '2',
            label: 'Admin (Channel Partner)',
            value: 'admin'
        },
        {
            id: '3',
            label: 'Manager (Channel Partner)',
            value: 'manager'
        }], "partners": []
    });
    const [loading, setLoading] = useState(false)
    const [selectAllNotification, setSelectAllNotification] = useState(false);
    const [selectAllaccess, setSelectAllaccess] = useState(false);

    const validationSchema = Yup.object().shape({
        formData: Yup.object().shape({
            admin_name: Yup.string().required('Admin name is required.'),
            email: Yup.string().email('Invalid email format.').required('Email is required.'),
            contact_no: Yup.string()
        .required('Contact number is required.')
        .matches(/^\d{10}$/, 'Contact number must be exactly 10 digits.'),
            role: Yup.string().required('Role is required.'),
            channelId: Yup.string().required('Channel Partner ID is required.'),

        }),
    });
    const handle = async (values) => {



        setLoading(true)
        try {
            const notificationData = Object.entries(values.formData.notification[0])
                .filter(([key, value]) => value)
                .map(([key]) => ({ name: key }));
            const accessData = Object.entries(values.formData.access[0])
                .filter(([key, value]) => value)
                .map(([key]) => ({ name: key }));
            const { access, notification, ...adminData } = values.formData;



            const data =
            {
                "admin": adminData, 'access': accessData, 'notification': notificationData
            }
            const result = await ADMINMANAGERAPI(data);

            toast.success(result?.message)
            setLoading(false)
            { reload && reload() }
            closeModal()
        }
        catch (err) {
            setLoading(false)
            toast.error(err?.message)
        }
    }

    const fetchData = async () => {
        try {
            setLoading(true)
            const result = await VIEWCREATECHANNELPARTNERAPI();
            if (result?.res?.status == 200) {
                const filteredData = result?.res?.data?.filter(item => item?.is_active == true)?.map(res => ({
                    id: res?.id,
                    label: res?.channel_partner_name,
                    value: res?.channel_partner_name,
                }));
                setLoading(false)
                setOptions((res) =>
                ({
                    ...res,
                    "partners": filteredData
                }))
            }
        }
        catch (err) {
            setLoading(false);
            toast.error(err)

        }




    }
    useEffect(() => {
        fetchData()
    }, [])

    return (
        <div className='row'>
            <Loader loading={loading} />
            <div className='com_modal'>
                {/* Use Formik wrapper */}
                <Formik
                    initialValues={{
                        formData: {
                            "admin_name": "",
                            "email": "",
                            "contact_no": "",
                            "role": "",
                            "channelId": '',
                            "is_active": false,
                            'access': [
                                {
                                    'Channel_Partners': false,
                                    'Plans': false,
                                    'Fleet_Management': false,
                                    'Dockyards': false,
                                    'Users': false,
                                    'Maintenance': false,
                                    'Reports': false,
                                    "Transaction": false,
                                    "payment_followUp":false,
                                    "FleetStatus":false,
                                    "FleetReports":false,
                                    "FleetBilling":false

                                }
                            ],
                            'notification': [
                                {
                                    'New_User_Registration': false,
                                    'Subscription_Renewal': false,
                                    'Low_Balance_Alert': false,
                                    'Vehicle_Maintenance_Reminder': false,
                                    'Critical_SOC/Voltage_Alerts': false,
                                    'New_Facility_Created': false,


                                }
                            ]

                        },
                    }}

                    onSubmit={handle}
                    validationSchema={validationSchema}

                >
                    {(formik) => (
                        // Wrap your content in the Form component
                        <Form className='form_default text_left'>
                            <div className='mb-3'>
                                <FormControl control="FormHead" title="Create New Admin  Manager" />
                            </div>
                            <div className='mb-3'>
                                <div className='row g-3'>
                                    <div className='col-lg-6'>
                                        <FormControl
                                            control="SelectBox"
                                            name={"formData.role"}
                                            label={"Role"}
                                            options={options.role}
                                            required={true}




                                        />
                                    </div>
                                    <div className='col-lg-6'>
                                    </div>
                                    <div className='col-lg-6'>
                                        <FormControl
                                            control="SelectBox"
                                            name={"formData.channelId"}
                                            label={" Channel Partner"}
                                            options={options.partners}
                                            required={true}

                                        />
                                    </div>

                                    <div className='col-lg-6'>
                                        <FormControl
                                            control="InputLabel"
                                            name={"formData.admin_name"}
                                            type={"text"}
                                            label={"Admin Name"}
                                            required={true}
                                            onKeyPress={(e) => {
                                                const charCode = e.which ? e.which : e.keyCode;
                                                // Allow only alphabetic characters and spaces
                                                if (!/[a-zA-Z\s]/.test(String.fromCharCode(charCode))) {
                                                    e.preventDefault();
                                                }
                                            }}
                                        
                                        />
                                    </div>
                                    <div className='col-lg-6'>
                                        <FormControl
                                            control="InputLabel"
                                            name={"formData.contact_no"}
                                            type={"text"}
                                            label={"Contact No"}
                                            required={true}

                                        />
                                    </div>

                                    <div className='col-lg-6'>
                                        <FormControl
                                            control="InputLabel"
                                            name={"formData.email"}
                                            type={"Email"}
                                            label={"Email"}
                                            required={true}

                                        />
                                    </div>

                                    <div className='col-lg-6'>
                                        <FormControl
                                            control="SelectBox"
                                            name={"formData.is_active"}
                                            options={options.status}
                                            label={"Status"}
                                            prevalue={formik.values.formData.is_active}

                                        />
                                    </div>
                                    <div className='col-lg-6'></div>


                                    <div className='col-lg-6 my-5'>
                                        <div className='d-flex justify-content-between  align-item-start'>
                                            <div>
                                                <FormControl
                                                    control="InputLabel"
                                                    name={"formData.label"}
                                                    type="hidden"
                                                    label={"Notification Preferences"}

                                                />
                                            </div>
                                            <div>
                                                <FormControl
                                                    control="Checkbox"
                                                    name={"formData.all"}
                                                    label={"Select All"}
                                                    checked={selectAllNotification}
                                                    onChange={(e) => {
                                                        setSelectAllNotification(e.target.checked);
                                                        formik.setFieldValue("formData.notification[0].New_User_Registration", e.target.checked);
                                                        formik.setFieldValue("formData.notification[0].Subscription_Renewal", e.target.checked);
                                                        formik.setFieldValue("formData.notification[0].Low_Balance_Alert", e.target.checked);
                                                        formik.setFieldValue("formData.notification[0].Vehicle_Maintenance_Reminder", e.target.checked);
                                                        formik.setFieldValue("formData.notification[0].Critical_SOC/Voltage_Alerts", e.target.checked);
                                                        formik.setFieldValue("formData.notification[0].New_Facility_Created", e.target.checked);
                                                    }}
                                                />
                                            </div>

                                            <div>

                                            </div>

                                        </div>
                                        <div>
                                            <FormControl
                                                control="Checkbox"
                                                name={"formData.notification[0].New_User_Registration"}
                                                label={"New User Registration"}
                                                onChange={(e) => {
                                                    formik.setFieldValue("formData.notification[0].New_User_Registration", e.target.checked);
                                                    if (!e.target.checked) {
                                                        setSelectAllNotification(false); // Uncheck "Select All" if any checkbox is unchecked
                                                    }
                                                }}
                                            />
                                        </div>
                                        <div>
                                            <FormControl
                                                control="Checkbox"
                                                name={"formData.notification[0].Subscription_Renewal"}
                                                label={"Subscription Renewal"}
                                                onChange={(e) => {
                                                    formik.setFieldValue("formData.notification[0].Subscription_Renewal", e.target.checked);
                                                    if (!e.target.checked) {
                                                        setSelectAllNotification(false); // Uncheck "Select All" if any checkbox is unchecked
                                                    }
                                                }}
                                            />
                                        </div>
                                        <div>
                                            <FormControl
                                                control="Checkbox"
                                                name={"formData.notification[0].Low_Balance_Alert"}
                                                label={"Low Balance Alert"}
                                                onChange={(e) => {
                                                    formik.setFieldValue("formData.notification[0].Low_Balance_Alert", e.target.checked);
                                                    if (!e.target.checked) {
                                                        setSelectAllNotification(false); // Uncheck "Select All" if any checkbox is unchecked
                                                    }
                                                }}
                                            />
                                        </div>
                                        <div>
                                            <FormControl
                                                control="Checkbox"
                                                name={"formData.notification[0].Vehicle_Maintenance_Reminder"}
                                                label={"Vehicle Maintenance Reminder"}
                                                onChange={(e) => {
                                                    formik.setFieldValue("formData.notification[0].Vehicle_Maintenance_Reminder", e.target.checked);
                                                    if (!e.target.checked) {
                                                        setSelectAllNotification(false); // Uncheck "Select All" if any checkbox is unchecked
                                                    }
                                                }}
                                            />
                                        </div>
                                        <div>
                                            <FormControl
                                                control="Checkbox"
                                                name={"formData.notification[0].Critical_SOC/Voltage_Alerts"}
                                                label={"Critical SOC/Voltage Alerts"}
                                                onChange={(e) => {
                                                    formik.setFieldValue("formData.notification[0].Critical_SOC/Voltage_Alerts", e.target.checked);
                                                    if (!e.target.checked) {
                                                        setSelectAllNotification(false); // Uncheck "Select All" if any checkbox is unchecked
                                                    }
                                                }}
                                            />
                                        </div>
                                        <div>
                                            <FormControl
                                                control="Checkbox"
                                                name={"formData.notification[0].New_Facility_Created"}
                                                label={"New Facility Created"}
                                                onChange={(e) => {
                                                    formik.setFieldValue("formData.notification[0].New_Facility_Created", e.target.checked);
                                                    if (!e.target.checked) {
                                                        setSelectAllNotification(false); // Uncheck "Select All" if any checkbox is unchecked
                                                    }
                                                }}
                                            />
                                        </div>

                                    </div>
                                    <div className='col-lg-6 my-5'>
                                        <div className='d-flex justify-content-between  align-item-start'>
                                            <div>
                                                <FormControl
                                                    control="InputLabel"
                                                    name={"formData.label"}
                                                    type="hidden"
                                                    label={" Access Preferences"}

                                                />
                                            </div>
                                            <div>
                                                <FormControl
                                                    control="Checkbox"
                                                    name={"formData.all"}
                                                    label={"Select All"}
                                                    checked={selectAllaccess}
                                                    onChange={(e) => {
                                                        setSelectAllaccess(e.target.checked);
                                                        formik.setFieldValue("formData.access[0].Admins_Managers", e.target.checked);
                                                        formik.setFieldValue("formData.access[0].Plans", e.target.checked);
                                                        formik.setFieldValue("formData.access[0].Fleet_Management", e.target.checked);
                                                        formik.setFieldValue("formData.access[0].Reports", e.target.checked);
                                                        formik.setFieldValue("formData.access[0].Channel_Partners", e.target.checked);
                                                        formik.setFieldValue("formData.access[0].Dockyards", e.target.checked);
                                                        formik.setFieldValue("formData.access[0].Users", e.target.checked);
                                                        formik.setFieldValue("formData.access[0].Maintenance", e.target.checked);
                                                        formik.setFieldValue("formData.access[0].Transaction", e.target.checked);
                                                        formik.setFieldValue("formData.access[0].payment_followUp", e.target.checked);
                                                        formik.setFieldValue("formData.access[0].FleetStatus", e.target.checked);
                                                        formik.setFieldValue("formData.access[0].FleetReports", e.target.checked);
                                                        formik.setFieldValue("formData.access[0].FleetBilling", e.target.checked);

                                                    }}
                                                />
                                            </div>

                                            <div>

                                            </div>

                                        </div>
                                        <div>
                                            <FormControl
                                                control="Checkbox"
                                                name={"formData.access[0].Admins_Managers"}
                                                label={"Admins Managers"}
                                                onChange={(e) => {
                                                    formik.setFieldValue("formData.access[0].Admins_Managers", e.target.checked);
                                                    if (!e.target.checked) {
                                                        setSelectAllaccess(false); // Uncheck "Select All" if any checkbox is unchecked
                                                    }
                                                }}
                                            />
                                        </div>
                                        <div>
                                            <FormControl
                                                control="Checkbox"
                                                name={"formData.access[0].Channel_Partners"}
                                                label={"Channel Partners"}
                                                onChange={(e) => {
                                                    formik.setFieldValue("formData.access[0].Channel_Partners", e.target.checked);
                                                    if (!e.target.checked) {
                                                        setSelectAllaccess(false); // Uncheck "Select All" if any checkbox is unchecked
                                                    }
                                                }}
                                            />
                                        </div>
                                        <div>
                                            <FormControl
                                                control="Checkbox"
                                                name={"formData.access[0].Dockyards"}
                                                label={"Dockyards"}
                                                onChange={(e) => {
                                                    formik.setFieldValue("formData.access[0].Dockyards", e.target.checked);
                                                    if (!e.target.checked) {
                                                        setSelectAllaccess(false); // Uncheck "Select All" if any checkbox is unchecked
                                                    }
                                                }}
                                            />
                                        </div>
                                     
                                        <div>
                                            <FormControl
                                                control="Checkbox"
                                                name={"formData.access[0].Fleet_Management"}
                                                label={"Fleet Management"}
                                                onChange={(e) => {
                                                    formik.setFieldValue("formData.access[0].Fleet_Management", e.target.checked);
                                                    if (!e.target.checked) {
                                                        setSelectAllaccess(false); // Uncheck "Select All" if any checkbox is unchecked
                                                    }
                                                }}
                                            />
                                        </div>

                                        <div>
                                            <FormControl
                                                control="Checkbox"
                                                name={"formData.access[0].FleetStatus"}
                                                label={"FleetStatus (Channel Partner)"}
                                                onChange={(e) => {
                                                    formik.setFieldValue("formData.access[0].FleetStatus", e.target.checked);
                                                    if (!e.target.checked) {
                                                        setSelectAllaccess(false); // Uncheck "Select All" if any checkbox is unchecked
                                                    }
                                                }}
                                            />
                                        </div>


                                        <div>
                                            <FormControl
                                                control="Checkbox"
                                                name={"formData.access[0].FleetReports"}
                                                label={"FleetReports (Channel Partner)"}
                                                onChange={(e) => {
                                                    formik.setFieldValue("formData.access[0].FleetReports", e.target.checked);
                                                    if (!e.target.checked) {
                                                        setSelectAllaccess(false); // Uncheck "Select All" if any checkbox is unchecked
                                                    }
                                                }}
                                            />
                                        </div>

                                        <div>
                                            <FormControl
                                                control="Checkbox"
                                                name={"formData.access[0].FleetBilling"}
                                                label={"FleetBilling (Channel Partner)"}
                                                onChange={(e) => {
                                                    formik.setFieldValue("formData.access[0].FleetBilling", e.target.checked);
                                                    if (!e.target.checked) {
                                                        setSelectAllaccess(false); // Uncheck "Select All" if any checkbox is unchecked
                                                    }
                                                }}
                                            />
                                        </div>
                                
                                        <div>
                                            <FormControl
                                                control="Checkbox"
                                                name={"formData.access[0].Maintenance"}
                                                label={"Maintenance"}
                                                onChange={(e) => {
                                                    formik.setFieldValue("formData.access[0].Maintenance", e.target.checked);
                                                    if (!e.target.checked) {
                                                        setSelectAllaccess(false); // Uncheck "Select All" if any checkbox is unchecked
                                                    }
                                                }}
                                            />
                                        </div>
                                        <div>
                                            <FormControl
                                                control="Checkbox"
                                                name={"formData.access[0].Plans"}
                                                label={"Plans"}
                                                onChange={(e) => {
                                                    formik.setFieldValue("formData.access[0].Plans", e.target.checked);
                                                    if (!e.target.checked) {
                                                        setSelectAllaccess(false); // Uncheck "Select All" if any checkbox is unchecked
                                                    }
                                                }}
                                            />
                                        </div>
                                        <div>
                                            <FormControl
                                                control="Checkbox"
                                                name={"formData.access[0].Reports"}
                                                label={"Reports"}
                                                onChange={(e) => {
                                                    formik.setFieldValue("formData.access[0].Reports", e.target.checked);
                                                    if (!e.target.checked) {
                                                        setSelectAllaccess(false); // Uncheck "Select All" if any checkbox is unchecked
                                                    }
                                                }}
                                            />
                                        </div>
                                        <div>
                                            <FormControl
                                                control="Checkbox"
                                                name={"formData.access[0].Transaction"}
                                                label={"Transaction"}
                                                onChange={(e) => {
                                                    formik.setFieldValue("formData.access[0].Transaction", e.target.checked);
                                                    if (!e.target.checked) {
                                                        setSelectAllaccess(false); // Uncheck "Select All" if any checkbox is unchecked
                                                    }
                                                }}
                                            />
                                        </div>

                                        <div>
                                            <FormControl
                                                control="Checkbox"
                                                name={"formData.access[0].Users"}
                                                label={"Users"}
                                                onChange={(e) => {
                                                    formik.setFieldValue("formData.access[0].Users", e.target.checked);
                                                    if (!e.target.checked) {
                                                        setSelectAllaccess(false); // Uncheck "Select All" if any checkbox is unchecked
                                                    }
                                                }}
                                            />
                                        </div>

                                        <div>
                                            <FormControl
                                                control="Checkbox"
                                                name={"formData.access[0].payment_followUp"}
                                                label={"payment followUp"}
                                                onChange={(e) => {
                                                    formik.setFieldValue("formData.access[0].payment_followUp", e.target.checked);
                                                    if (!e.target.checked) {
                                                        setSelectAllaccess(false); // Uncheck "Select All" if any checkbox is unchecked
                                                    }
                                                }}
                                            />
                                        </div>

                                    </div>




                                </div>

                            </div>

                            <div className='d-flex justify-content-end'>
                                <div className='col-lg-2 mx-5' onClick={closeModal}>
                                    <Button text="Cancel" type="button" isCancel={true} />
                                </div>

                                <div className='col-lg-2'>
                                    <Button text="Save" type="submit" />
                                </div>

                            </div>

                            <div>
                                <p>
                                    all asterisk (*) field are required
                                </p>
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
        </div >
    );
}

export default CreateAdmin;
