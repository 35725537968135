import { createSlice } from "@reduxjs/toolkit";

export const AuthSlice = createSlice({
  name: "auth",
  initialState: {
    Authdata: localStorage.getItem("_authData"),
    userData: localStorage.getItem("_userData"),
    channelId:localStorage.getItem("channelId"),
    isLoggedIn: localStorage.getItem("isauthenticatesd"),
    isAdmin: false,
    access: localStorage.getItem("access")|| [],
  },
  reducers: {
    AuthReducer: (state, action) => {
      state.Authdata = action.payload;
    },
    handleIsloggedinreducer: (state, action) => {
      state.isLoggedIn = action.payload;
    },
    handlePermissions: (state, action) => {
      state.userData = action.payload;
    },
    handleAccess: (state, action) => {


      state.access = action.payload;
    },
    handleChannel : (state,action)=>{
      state.channelId = action.payload
    },
    handleAdmin: (state, action) => {
      state.access = action.access;
    },
  },
});

export const {
  AuthReducer,
  handleIsloggedinreducer,
  handlePermissions,
  handleAdmin,
  handleAccess,
  handleChannel
} = AuthSlice.actions;

export default AuthSlice.reducer;
