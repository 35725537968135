import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import classNames from "classnames";
import cross from "assets/img/Icons/Dashboard/close.svg"


const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
  },
  palette: {
    background: {
      default: "#e4f0e2",
    },
  },
}));

const sizes = {
  xl: "width-75",
  lg: "width-50",
  md: "width-40",
  cs: "width-45",
  sm: "width-25",
};

const CommonModal = ({
  handleModalData,
  isModalOpen,
  modalData,
  modalSize,
  closeModal,
}) => {
  const classes = useStyles();

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={isModalOpen}
        onClose={closeModal}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
          style: { backgroundColor: "rgba(0, 0, 0, 0.2)" },
        }}
      >
        <Fade in={isModalOpen}>
          <div
            id="custom-modal"
            className={classNames({
              [classes.paper]: true,
              [sizes[modalSize]]: true,
            })}
          >
            <div className="d-flex justify-content-end mb-4">
              <div className="cross_sign" onClick={closeModal}>
                <img src={cross} alt=""  className="cross_sign" onClick={closeModal}/>
              </div>
            </div>
            {modalData}
           
          </div>
        </Fade>
      </Modal>
    </div>
  );
};

export default CommonModal;