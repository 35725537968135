import home_active from "../../assets/img/Icons/sidebar/home_active.svg";
import home_default from "../../assets/img/Icons/sidebar/home_default.svg";
import shield_default from "../../assets/img/Icons/sidebar/shield_default.svg";
import shield_active from "../../assets/img/Icons/sidebar/shield_active.svg";
import handshake_active from "../../assets/img/Icons/sidebar/handshake_active.svg";
import handshake_default from "../../assets/img/Icons/sidebar/handshake_default.svg";
import style_active from "../../assets/img/Icons/sidebar/style_active.svg";
import style_default from "../../assets/img/Icons/sidebar/style_default.svg";
import two_wheeler_active from "../../assets/img/Icons/sidebar/two_wheeler_active.svg";
import two_wheeler_default from "../../assets/img/Icons/sidebar/two_wheeler_default.svg";
import warehouse_default from "../../assets/img/Icons/sidebar/warehouse_default.svg";
import warehouse_active from "../../assets/img/Icons/sidebar/warehouse_active.svg";
import healing_active from "../../assets/img/Icons/sidebar/healing_active.svg";
import healing_default from "../../assets/img/Icons/sidebar/healing_default.svg";
import lab_profile_active from "../../assets/img/Icons/sidebar/lab_profile_active.svg";
import lab_profile_default from "../../assets/img/Icons/sidebar/lab_profile_default.svg";
import shield_person from "../../assets/img/Icons/sidebar/shield_person.svg";
import shield_person_active from "../../assets/img/Icons/sidebar/shield_person_active.svg";
import logout from "../../assets/img/Icons/sidebar/logout_default.svg";
import logout_active from "../../assets/img/Icons/sidebar/logout_active.svg";
import currency_rupee_default from "../../assets/img/Icons/sidebar/currency_rupee_default.svg";
import currency_rupee_active from "../../assets/img/Icons/sidebar/currency_rupee_active.svg";
const SidebarList = [
  {
    id: 1,
    label: "Home",
    ref: "dash",
    src1: home_active,
    src: home_default,
    path: "/",
    check: "Dashboard",
    module_id: 9,
  },
  {
    id: 3,
    label: "Channel Partners",
    ref: "dash",
    src1: handshake_active,
    src: handshake_default,
    path: "/channel",
    name: "Channel Partners",
    check: "Channel_Partners",
    module_id: 2,
  },
  {
    id: 2,
    label: "Admins & Managers",
    ref: "dash",
    src1: shield_active,
    src: shield_default,
    path: "/admin",
    check: "Admins_Managers",
    module_id: 1,
  },

  {
    id: 4,
    label: "Plans",
    ref: "dash",
    src1: style_active,
    src: style_default,
    path: "/plans",
    check: "Plans",
    module_id: 3,
  },
  {
    id: 6,
    label: "Dockyards",
    ref: "dash",
    src1: warehouse_active,
    src: warehouse_default,
    path: "/dockyards",
    check: "Dockyards",
    module_id: 5,
  },
  {
    id: 5,
    label: "Fleet Management",
    ref: "dash",
    src1: two_wheeler_active,
    src: two_wheeler_default,
    path: "/fleet",
    module_id: 4,
    check: "Fleet_Management",
  },

  {
    id: 7,
    label: "Users",
    ref: "dash",
    src1: shield_person_active,
    src: shield_person,
    path: "/users",
    check: "Users",
    module_id: 6,
  },

  {
    id: 8,
    label: "payment followUp",
    ref: "dash",
    src1: shield_person_active,
    src: shield_person,
    path: "/payment",
    check: "payment_followUp",
    module_id: 7,
  },


  {
    id: 9,
    label: "Maintenance",
    ref: "dash",
    src1: healing_active,
    src: healing_default,
    module_id: 8,
    check: "Maintenance",
    path: "/maintenance",
  },
  {
    id: 10,
    label: "Reports",
    ref: "dash",
    src1: lab_profile_active,
    src: lab_profile_default,
    path: "/reports",
    module_id: 9,
    check: "Reports",
  },
  {
    id: 11,
    label: "Transaction",
    ref: "dash",
    src1: currency_rupee_active,
    src: currency_rupee_default,
    path: "/transaction",
    module_id: 10,
    check: "Transaction",
  },

  {
    id: 12,
    label: "Fleet Status",
    ref: "dash",
    src1: lab_profile_active,
    src: lab_profile_default,
    path: "/fleetstatus",
    module_id: 1,
    check: "FleetStatus",
  },

  {
    id: 13,
    label: "Reports",
    ref: "dash",
    src1: lab_profile_active,
    src: lab_profile_default,
    path: "/reportstatus",
    module_id: 10,
    check: "FleetReports",
  },
  {
    id: 14,
    label: "Billing",
    ref: "dash",
    src1: lab_profile_active,
    src: lab_profile_default,
    path: "/billing",
    module_id: 10,
    check: "FleetBilling",
  },
  {
    id: 14,
    label: "Setting",
    ref: "dash",
    src1: lab_profile_active,
    src: lab_profile_default,
    path: "/setting",
    module_id: 13,
    check: "FleetSetting",
  },
  {
    id: 10,
    label: "Logout",
    ref: "dash",
    src1: logout_active,
    src: logout,
    path: "/logout",
  },
];
export default SidebarList;
