import React from "react";
import Input from "../Inputs/DefaultInput";
import InputLabel from "../Inputs/InputLabel";
import TextArea from "../Inputs/TextArea";

import Heading from "../Heading";
import FormHead from "../FormHead";
import SearchInput from "../SearchInput";
import SelectBox from "../Select";
import Checkbox from "../Checkbox";
import Radio from "../Radio";
import CalInput from "../Inputs/CalInput";

import CustomEditor from "../Inputs/Editor";
const FormControl = (props) => {
  const { control, ...rest } = props;
  switch (control) {
    case "Input":
      return <Input {...rest} />;

    case "InputLabel":
      return <InputLabel {...rest} />;

    case "Checkbox":
      return <Checkbox {...rest} />;
    case "Radio":
      return <Radio {...rest} />;
    case "SelectBox":
      return <SelectBox {...rest} />;
    case "CalInput":
      return <CalInput {...rest} />;
    case "FormHead":
      return <FormHead {...rest} />;
    case "heading":
      return <Heading {...rest} />;
    case "TextArea":
      return <TextArea {...rest} />
    case "Search":
      return <SearchInput {...rest} />;

    case "Editor":
      return <CustomEditor {...rest} />;

    default:
      return null;
  }
};

export default FormControl;
