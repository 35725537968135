import React, { useContext, useEffect, useMemo, useState } from 'react';
import FormControl from "components/controls/FormControl";
import CustomTable from "components/Table"
import Button from "components/buttons/ADD";
import EditDelete from "components/buttons/EditDelete";
import { ModalContext } from 'utils/Contexts/modalContext';
import CreatChannelPartner from 'components/modals/CreatChannelPartner';
import CreateAdmin from 'components/modals/CreateAdmin';
import EditAdmin from 'components/modals/EditAdmin';
import CoomonDelete from 'components/modals/CoomonDelete';
import Loader from 'components/loader';
import { VIEWADMINMANAGERPI, DELETECREATECHANNELPARTNERAPI, EDITADMINMANAGERPI } from 'utils/Services';
import { toast } from 'react-toastify';
import FallBack from "components/fallBack"


function Admin() {
    const modalContext = useContext(ModalContext);
    const { closeModal, handleModalData } = modalContext;
    const [loading, setLoading] = useState(false)
    const [allData, setallData] = useState([]);
    const options = [{
        id: '1',
        label: 'Zeway Super Admin',
        value: 'super_admin'
    }, {
        id: '2',
        label: 'Admin (Channel Partner)',
        value: 'admin'
    },
    {
        id: '3',
        label: 'Manager (Channel Partner)',
        value: 'manager'
    }]
    const FeedsColumns = useMemo(
        () => [
            {
                Header: "Sno.",
                accessor: (row, index) => {
                    return (
                        <>
                            <span className='text-sm'>{index + 1}</span>
                        </>
                    );
                }
            },
            {
                Header: "Full Name",
                accessor: (Customer) => Customer?.admin_name || "" // Use conditional rendering
            },
            {
                Header: "Channel Partner", // The header text for this column is "Service"
                accessor: (Customer) => Customer?.channelId || "" // Use conditional rendering
            },
            {
                Header: "Email", // The header text for this column is "Service"
                accessor: (Customer) => Customer?.email || "" // Use conditional rendering
            },
            {
                Header: "Mobile Number", // The header text for this column is "Service"
                accessor: (Customer) => Customer?.contact_no || "" // Use conditional rendering
            },
            {
                Header: "Status", // The header text for this column is "Service"
                accessor: (Customer) => (Customer?.is_active == true ? ('Active') : ('Inactive')) || "" // Use conditional rendering
            },

            {
                Header: "Role",
                accessor: (customer) => {
                    const role = options.filter((res) => res.id == customer?.role);
                    return role.length > 0 ? role[0].label : "";
                }
            },

            {
                Header: "Actions", // The header text for this column is "Service"
                accessor: (row, index) => {
                    return (
                        <div className='d-flex  justify-content-between  align-items-center'>
                            <div className='col-lg-8' onClick={() => handle("edit", row?.id)}>
                                <EditDelete text="Edit" type="Button" />
                            </div>


                        </div>



                    );
                }
            },
        ],
        []
    );
    const fecthData = async () => {
        try {
            setLoading(true)
            const result = await VIEWADMINMANAGERPI();
            if (result?.res?.status == 200) {
                setallData(result?.res?.data)
                setLoading(false)
            }
            else {
                setLoading(false)
            }

        }
        catch (err) {
            console.log(err)
            setLoading(false)
        }

    }
    useEffect(() => {
        fecthData()
    }, []);

    const handleDelete = async (data) => {
        setLoading(true)
        try {
            const result = await DELETECREATECHANNELPARTNERAPI(data);

            if (result?.res?.status == 200) {
                setLoading(false)
                toast.success(result?.res?.message)
                fecthData(); closeModal();
            }
        }
        catch (err) {
            toast.error(err?.message || err)
            setLoading(false)
            closeModal();
        }


    }
    const handle = (data, info) => {
        const CreateDocumnet = <CreateAdmin closeModal={closeModal} reload={fecthData} />;
        const EditDocumnet = <EditAdmin closeModal={closeModal} data={info} reload={fecthData} />;
        const DelteDocumnet = <CoomonDelete closeModal={closeModal} data={info} handle={handleDelete} reload={fecthData} />
        switch (data) {
            case "create":
                handleModalData(CreateDocumnet, "lg");
                break;
            case "edit":
                handleModalData(EditDocumnet, "md");
                break;
            case "delete":
                handleModalData(DelteDocumnet, "md");
                break;

        }

    }
    return (
        <div className="container-fluid pb-5">
            <div className='row'>
                <Loader loading={loading} />

                <div className='d-flex justify-content-between  my-3 '>
                    <div className='col-lg-6'>

                    </div>
                    <div onClick={() => handle("create")}>
                        <Button text="Create New Admin Manager" />

                    </div>


                </div>

                {
                    allData.length > 0 ? (<div className='default_table'>
                        <CustomTable data={allData} columns={FeedsColumns} />


                    </div>) : (<FallBack />)
                }

            </div>
        </div>
    )
}

export default Admin