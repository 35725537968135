

import React from "react";
import FormControl from "../../../components/controls/FormControl";
import EditDelete from "../../../components/buttons/Default";
import BoderButton from "../../../components/buttons/BoderButton"
import Loader from "components/loader"
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { toast } from "react-toastify";
import { CHANGEPASSWORDAPI, } from "utils/Services";
import { useNavigate } from "react-router-dom";
// Custom components
// Assets

function ResetPassword() {
    // Chakra color mode
    const [loading, setloading] = React.useState(false);
    const [show, setShow] = React.useState(false);
    const handleClick = () => setShow(!show);
    const navigate = useNavigate();
    const validationSchema = Yup.object().shape({
        formData: Yup.object().shape({
            contact_no: Yup.string()
            .required('Contact number is required.')
            .matches(/^\d{10}$/, 'Contact number must be exactly 10 digits.'),

        }),
    });
    const handle = async (values) => {
        const data =
        {
            ...values.formData
        }
        try {
            setloading(true);
            const result = await CHANGEPASSWORDAPI(data);

            if (result?.status == 201) {
                setloading(false)
                toast.success(result.message)
                navigate('/reset-verification', { state: { data } });

            }
            else {
                setloading(false)
                toast.error(result.message)
            }
        }
        catch (err) {

            setloading(false)
            toast.error(err && err?.message)
        }
    }
    const handleLogin = () => {
        navigate('/login')
    }
    return (


        <div className="login_wrapper">
            <Loader loading={loading} />
            <div className="login_block col-lg-3">
                <Formik
                    initialValues={{
                        formData: {
                            "contact_no": "",
                            'password': "",
                            'controlled': false

                        },
                    }}

                    onSubmit={handle}
                    validationSchema={validationSchema}
                >
                    {formik => (
                        <div className="row">
                            <Form className="form_default py-5">
                                <div className='mb-4'>
                                    <FormControl
                                        control='heading'
                                        title='Reset your password'
                                    />
                                </div>
                             
                                
                                <div className='mb-4'>
                                    <div className='col-lg-12'>
                                        <div className='mb-3'>
                                            <FormControl
                                                control='InputLabel'
                                                label="Phone Number"
                                                type="text"
                                                placeholder='Enter Phone Number '
                                                name='formData.contact_no'

                                            />
                                        </div>



                                    </div>
                                </div>
                                <div className="text-start">
                                    <p>
                                     * Enter the phone number associated with your account.

                                    </p>
                                </div>
                                <div className="d-flex justify-content-between align-items-center mt-5">

                                    <div className="col-lg-4" onClick={handleLogin}>
                                        <BoderButton type='button' text='Back to Log in' />
                                    </div>
                                    <div className="col-lg-4">
                                        <EditDelete type='submit' text='Proceed' disabled={formik.isSubmitting} />
                                    </div>


                                </div>




                            </Form>
                        </div>
                    )}
                </Formik>
            </div>


        </div>

    );
}

export default ResetPassword;
