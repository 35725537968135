import React from "react";
import PropTypes from "prop-types";
import Logo from "../../assets/img/Logo/logo_client.svg";

function AuthIllustration(props) {
  const { children, illustrationBackground } = props;
  // Chakra color mode
  return (
    <div className="position-relative container-fluid">
      <div className="">
        <div className="p-5 log_scr_logo">
          <img src={Logo} />
        </div>
        <div>{children}</div>
      </div>
    </div>
  );
}
// PROPS

AuthIllustration.propTypes = {
  illustrationBackground: PropTypes.string,
  image: PropTypes.any,
};

export default AuthIllustration;
