import React, { useContext, useEffect, useMemo, useState } from 'react';
import FormControl from "components/controls/FormControl";
import CustomTable from "components/Table"
import Button from "components/buttons/ADD";
import EditDelete from "components/buttons/EditDelete";
import { ModalContext } from 'utils/Contexts/modalContext';
import FleetMangement from 'components/modals/FleetMangement';
import EditDockayard from 'components/modals/EditDockayard';
import CoomonDelete from 'components/modals/CoomonDelete';
import Loader from 'components/loader';
import FallBack from "components/fallBack"
import { VIEWFLEETDAPI, FLEETDELETEAPI, GENRATEQRAPI } from 'utils/Services';
import { toast } from 'react-toastify';
import { saveAs } from 'file-saver';
function Fleet() {
    const modalContext = useContext(ModalContext);
    const { closeModal, handleModalData } = modalContext;
    const [loading, setLoading] = useState(false)
    const [allData, setallData] = useState([]);
    const [filterData, setfilterData] = useState([])
    const [searchText, setsearchText] = useState('')




    const FeedsColumns = useMemo(
        () => [
            {
                Header: "Sno.",
                accessor: (row, index) => {
                    return (
                        <>
                            <span className='text-sm'>{index + 1}</span>
                        </>
                    );
                }
            },

            {
                Header: "Registration number", // The header text for this column is "Service"
                accessor: (Customer) => Customer?.registration_no || "" // Use conditional rendering
            },
            {
                Header: "Plan Type", // The header text for this column is "Service"
                accessor: (Customer) => Customer?.planType == "1" ? "Pay-Per-Use" : "Subscription" || "" // Use conditional rendering
            },
            {
                Header: "Vehicle Make", // The header text for this column is "Service"
                accessor: (Customer) => Customer?.vehicle_make || "" // Use conditional rendering
            },
            {
                Header: "Vehicle Color", // The header text for this column is "Service"
                accessor: (Customer) => Customer?.vehicle_color || "" // Use conditional rendering
            },
            {
                Header: "Lock Status", // The header text for this column is "Service"
                accessor: (Customer) => Customer?.lock_status == 'locked' ? "Locked" : "Unlocked" || "" // Use conditional rendering
            },
            {
                Header: "Availablity Status", // The header text for this column is "Service"
                accessor: (Customer) => Customer?.availability_status == 'available' ? "Available" : "Unavailable" || "" // Use conditional rendering
            },
            {
                Header: "Date of Manufacture", // The header text for this column is "Service"
                accessor: (Customer) => Customer?.manufacture_date || "" // Use conditional rendering
            },
            {
                Header: "Insurance Expiry Date", // The header text for this column is "Service"
                accessor: (Customer) => Customer?.insurance_expiry_date || "" // Use conditional rendering
            },

            {
                Header: "Geo-Fencing Id", // The header text for this column is "Service"
                accessor: (Customer) => Customer?.geo_fencingID || "" // Use conditional rendering
            },
            {
                Header: "SoS Integration Status", // The header text for this column is "Service"
                accessor: (Customer) => Customer?.sos == true ? "Enabled" : "Disabled" || "" // Use conditional rendering
            },
            {
                Header: "SoS Notification Text", // The header text for this column is "Service"
                accessor: (Customer) => Customer?.sos_text || "" // Use conditional rendering
            },
            {
                Header: "Actions", // The header text for this column is "Service"
                accessor: (row, index) => {
                    return (
                        <div className='d-flex  justify-content-between  align-items-center'>
                            <div className='col-lg-4' onClick={() => handle("edit", row?.id)}>
                                <EditDelete text="Edit" type="Button" />
                            </div>

                            <div className='col-lg-7' onClick={() => handle("Genrate", row)}>
                                <EditDelete text="Generate" type="Button" />
                            </div>
                        </div>



                    );
                }
            },
        ],
        []
    );
    const fecthData = async () => {
        try {
            setLoading(true)
            const result = await VIEWFLEETDAPI();
            if (result?.res?.status == 200) {
                setallData(result?.res?.data)
                setfilterData(result?.res?.data)
                setLoading(false)
            }
            else {
                setLoading(false)
            }

        }
        catch (err) {
            console.log(err)
            setLoading(false)
        }

    }
    useEffect(() => {
        fecthData()
    }, []);

    const handleDelete = async (data) => {
        setLoading(true)
        try {
            const result = await FLEETDELETEAPI(data);
            if (result?.res?.status == 200) {
                setLoading(false)
                toast.success(result?.res?.message)
                fecthData(); closeModal();
            }
        }
        catch (err) {
            toast.error(err?.message || err)
            setLoading(false)
            closeModal();
        }


    }

    const handleQR = async (info) => {
        console.log(info);
        try {
            const result = await GENRATEQRAPI(info?.planDetailId, info?.dockyardId, info?.planType , info?.id);
            if (result?.res?.status == 200) {
                toast.success(result?.res?.message)
                saveAs(result?.res?.data, `${info?.registration_no}.png`);
            }
            else {
                toast.error(result?.res?.message)
            }

        }
        catch (err) {
            toast.error(err)
        }

    }

    const handle = (data, info) => {

        const CreateDocumnet = <FleetMangement closeModal={closeModal} reload={fecthData} res={info} />;
        const EditDocumnet = <FleetMangement closeModal={closeModal} reload={fecthData} res={info} />;
        const DelteDocumnet = <CoomonDelete closeModal={closeModal} data={info} handle={handleDelete} reload={fecthData} />
        switch (data) {
            case "create":
                handleModalData(CreateDocumnet, "xl");
                break;
            case "edit":
                handleModalData(EditDocumnet, "xl");
                break;
            case "delete":
                handleModalData(DelteDocumnet, "md");
                break;
            case "Genrate":
                handleQR(info);
                break;


        }

    }

    return (
        <div className="container-fluid pb-5">
            <div className='row'>
                <Loader loading={loading} />

                <div className='d-flex justify-content-between  my-3 '>
                    <div className='col-lg-4'>
                        <FormControl control={"Search"} setSearchVal={setsearchText} />


                    </div>
                    <div onClick={() => handle("create")} className='col-lg-2'>
                        <Button text="Create Fleet" />

                    </div>


                </div>

                {
                    allData.length > 0 ? (<div className='default_table'>
                        <CustomTable data={filterData} columns={FeedsColumns} searchVal={searchText}/>


                    </div>) : (<FallBack />)
                }



            </div>
        </div>
    )
}

export default Fleet