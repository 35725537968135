import React from 'react';
import { FaSearch } from 'react-icons/fa';
import  search  from "../../../assets/img/Icons/Dashboard/search.svg"

function SearchBar(props) {
    const { placeholder, searchVal, setSearchVal } = props;

    const handleSearch = (event) => {
        const query = event.target.value;
        setSearchVal(query); // Update search query state
    };

    return (
        <div className="search">
            <div className="">
                <button id="button-addon2" type="submit" className="search_btn">
                <img src={search} />
                </button>
            </div>
            <input
                type="search"
                value={searchVal} // Make sure to use the searchVal state as the input value
                onChange={handleSearch}
                placeholder={placeholder}
                aria-describedby="button-addon2"
                className="serch_input"
            />
        </div>
    );
}

export default SearchBar;
