import React, { useState, useEffect } from "react";
import { Formik, Form } from 'formik';
import FormControl from "../../../components/controls/FormControl";
import Button from "../../../components/buttons/Default"
import * as Yup from 'yup';
import { toast } from "react-toastify";
import Loader from "components/loader";
import { OTPVERIFYService, RESENDOTPAPI } from "utils/Services";
import { useDispatch } from "react-redux";
import { AuthReducer, handleIsloggedinreducer, handlePermissions, handleAccess,handleChannel } from "utils/slices/AuthSlice";
import { useNavigate } from "react-router-dom";
import BoderButton from "../../../components/buttons/BoderButton"


function OtpVerify(props) {
    const { nextStep, details, setDetails } = props;
    const [loading, setloading] = React.useState(false);
    const [show, setShow] = React.useState(false);
    const handleClick = () => setShow(!show);
    const [seconds, setSeconds] = useState(30);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const validationSchema = Yup.object().shape({
        formData: Yup.object().shape({
            otp: Yup.string()
                .required('OTP is required')
                .matches(/^\d{6}$/, 'OTP must be 6 digits'),
        }),
    });

    const handle = async (values) => {
        const data =
        {

            "contact_no": details?.data,
            'otp': values.formData?.otp
        }
        try {
            setloading(true);
            const result = await OTPVERIFYService(data);

            if (result?.res?.status == 200) {
                setloading(false)
                toast.success(result?.res?.message)
                dispatch(AuthReducer(result?.res?.data?.accessToken));
                dispatch(handleIsloggedinreducer(result?.res?.data?.isActive));
                dispatch(handlePermissions(result?.res?.data?.adminName));
                dispatch(handleAccess(result?.res?.data?.access));
                dispatch(handleChannel(result?.res?.data?.channelId));
                localStorage.setItem("isauthenticatesd", result?.res?.data?.isActive);
                localStorage.setItem("_authData", result?.res?.data?.accessToken);
                localStorage.setItem("refreshToken", result?.res?.data?.refreshToken);
                localStorage.setItem("_userData", result?.res?.data?.adminName);
                localStorage.setItem('admin', result?.res?.data?.role == "1" ? true : false);
                localStorage.setItem('role', result?.res?.data?.role);
                localStorage.setItem("access", (JSON.stringify(result?.res?.data?.access)));
                localStorage.setItem("channelId", result?.res?.data?.channelId)
                navigate("/");
            }

            else {
                setloading(false)
                toast.error(result.message)
            }
        }
        catch (err) {
            setloading(false)
            toast.error(err && err?.message)
        }
    }

    const handleResend = async () => {
        setloading(true)
        try {
            const data = {
                contact: details?.data
            }
            const result = await RESENDOTPAPI(data);
            if (result?.status == 200) {
                toast.success(result?.message)
                setSeconds(30);
                setloading(false)
            }
            else {
                toast.error(result?.message);
                setloading(false)
            }
        }
        catch (e) {
            toast.error(e);
            setloading(false)
        }


    }
    useEffect(() => {
        const interval = setInterval(() => {
            if (seconds > 0) {
                setSeconds(seconds - 1);
            } else {
                clearInterval(interval);
            }
        }, 1000);

        return () => {
            clearInterval(interval);
        };
    }, [seconds]);
    return (


        <div className="login_wrapper">
            <Loader loading={loading} />
            <div className="login_block col-lg-3">
                <Formik
                    initialValues={{
                        formData: {
                            "contact_no": details?.contact,
                            'otp': ""

                        },
                    }}
                    validationSchema={validationSchema}
                    onSubmit={handle}
                >
                    {formik => (
                        <div className="row">
                            <Form className="form_default ">
                                <div className='mb-4'>
                                    <FormControl
                                        control='heading'
                                        title='2- Factor Authentication'
                                    />
                                </div>
                                <div className="cus_text my-3 text_left">
                                    <p>
                                        A verification code has been sent to your email/Mobile No. This code will be valid for 15 minutes.
                                    </p>
                                </div>
                                <div className='mb-5'>
                                    <div className='col-lg-12'>
                                        <div className='mb-5'>
                                            <FormControl
                                                control='InputLabel'
                                                label="OTP"
                                                placeholder=''
                                                name='formData.otp'

                                            />
                                        </div>





                                    </div>
                                </div>
                                <div className="text-start">
                                <p>
                                    ReSend  Otp : {seconds} sec
                                </p>
                                </div>
                                <div className="mt-5">
                                    <div className="d-flex justify-content-between align-items-center mt-5">

                                        <div className="col-lg-4" onClick={handleResend}>
                                            <BoderButton type='button' text='Resend Otp' disabled={seconds > 0} />
                                        </div>
                                        <div className="col-lg-4">
                                            <Button type='submit' text='Proceed' disabled={formik.isSubmitting} />
                                        </div>


                                    </div>

                                </div>




                            </Form>
                        </div>
                    )}
                </Formik>
            </div>


        </div>

    );
}

export default OtpVerify;
