import React from 'react';
import { Field, ErrorMessage } from 'formik';
import TextError from '../Errros'; // Assuming the correct path to the Errors component

function Radio(props) {
  const { name, value, label, ...rest } = props;

  return (
    <div>
      <Field type="checkbox" name={name} value={value}>
        {({ field, form }) => (
          <div className=''>
            <div className='d-flex align-items-center'>
              <input
                className='me-2'
                id={name}
                type="checkbox"
                {...field}
                {...rest}
              />
              <label htmlFor={name}>
                {label}
              </label>
            </div>
            <ErrorMessage component={TextError} name={name} />
          </div>
        )}
      </Field>
    </div>
  );
}

export default Radio;
