import React, { useState } from 'react';
import { Formik, Form } from 'formik';
import FormControl from 'components/controls/FormControl';
import Loader from "components/loader"
import Button from 'components/buttons/ADD';
import { GETSTATEAPI, CREATECHANNELPARTNERAPI } from 'utils/Services';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { BiCalendarEvent } from "react-icons/bi";

import { saveAs } from 'file-saver';
import { Downloadstatusdata, DownloadstatusTrip } from 'utils/Services';

function CreatChannelPartner({ closeModal, reload, customerId, createdAt, fullname }) {
    const [loading, setLoading] = useState(false);
    const [selectedRole, setSelectedRole] = useState('Summary');
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);

    const validationSchema = Yup.object().shape({
        formData: Yup.object().shape({
            channel_partner_name: Yup.string().required('Channel Partner Name is required'),
            zip_code: Yup.number()
                .required('Zip Code is required')
                .typeError('Zip Code must be a number')
                .integer('Zip Code must be an integer')
                .min(100000, 'Zip Code must be exactly 6 digits')
                .max(999999, 'Zip Code must be exactly 6 digits'),
            gst_no: Yup.string()
                .matches(
                    /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}[Z]{1}[0-9A-Z]{1}$/,
                    'Invalid GST Number'
                )
                .required('GST Number is required')
        }),
        startDate: Yup.date()
            .required('Start Date is required')
            .nullable(),
        endDate: Yup.date()
            .required('End Date is required')
            .nullable()
            .min(Yup.ref('startDate'), "End Date can't be before Start Date"),
    });


    const handleRoleChange = (e) => {
        setSelectedRole(e.target.value);
    };

    const handle = async (values) => {
        setLoading(true);
        try {
            const data = { ...values.formData };
            const result = await CREATECHANNELPARTNERAPI(data);
            toast.success(result?.message);
            setLoading(false);
            { reload && reload(); }
            closeModal();
        } catch (err) {
            setLoading(false);
            toast.error(err?.message);
        }
    };

    function convertToISOWithOffset(dateString) {
        const date = new Date(dateString);

        date.setHours(date.getHours() + 5);
        date.setMinutes(date.getMinutes() + 30);

        return date.toISOString();
    }


    const handleDetails = async (customerId) => {
        if (!startDate || !endDate) {
            toast.error("Both Start Date and End Date are required.");
            return;
        }
    
        try {
            setLoading(true);
    
            const formattedStartDate = convertToISOWithOffset(startDate);
            const formattedEndDate = convertToISOWithOffset(endDate);
    
            const result = await Downloadstatusdata(`?customerId=${customerId}&startTime=${formattedStartDate}&endTime=${formattedEndDate}`);
            if (result) {
                let filename = `Summarywise Report-${fullname}.xlsx`;
                saveAs(result.res, filename);
                toast.success(`${filename} Downloaded Successfully`);
            } else {
                toast.error("Failed to export the report.");
            }
            setLoading(false);
        } catch (err) {
            setLoading(false);
            toast.error(err.message || "An error occurred while downloading the report.");
        }
        closeModal();
    };
    

    const handleDetailsTrip = async (customerId) => {
        if (!startDate) {
            toast.error("Start Date is required.");
            return;
        }
    
        try {
            setLoading(true);
    
            const formattedStartDate = convertToISOWithOffset(new Date(startDate.setHours(0, 0, 0, 0)));
            const formattedEndDate = convertToISOWithOffset(new Date(startDate.setHours(12, 0, 0, 0))); 
    
            const result = await DownloadstatusTrip(`?customerId=${customerId}&startTime=${formattedStartDate}&endTime=${formattedEndDate}`);
            if (result) {
                let filename = `Tripwise Report--${fullname}.xlsx`;
                saveAs(result.res, filename);
                toast.success(`${filename} Downloaded Successfully`);
            } else {
                toast.error("Failed to export the report.");
            }
            setLoading(false);
        } catch (err) {
            setLoading(false);
            toast.error(err.message || "An error occurred while downloading the report.");
        }
        closeModal();
    };
    

    <DatePicker
        selected={startDate}
        minDate={new Date(createdAt)}
        maxDate={new Date()}
        onChange={(date) => {
            setStartDate(date);
            setEndDate(null);
        }}
        placeholderText="Select date"
        customInput={<CustomInput icon={<BiCalendarEvent />} />}
    />




    return (
        <div className='row'>
            <Loader loading={loading} />
            <div className='com_modal'>
                <Formik
                    initialValues={{
                        formData: {
                            channel_partner_name: "",
                            zip_code: "",
                            gst_no: ""
                        },
                        startDate: null,
                        endDate: null,
                    }}
                    validationSchema={validationSchema}
                    onSubmit={handle}
                >
                    {(formik) => (
                        <Form className='form_default text_left'>
                            <div className='mb-3'>
                                <FormControl control="FormHead" title="Generate New Admin/Manager" />
                            </div>

                            <div className='mb-3'>
                                <div className='row flex justify-content-between'>
                                    <label>Role</label>
                                    <div className='col-lg-5 borderbody' style={{ width: '290px', marginLeft: '10px' }}>
                                        <label className="d-flex align-items-center">
                                            <input
                                                type="radio"
                                                name="role"
                                                value="Summary"
                                                checked={selectedRole === 'Summary'}
                                                onChange={handleRoleChange}
                                            />
                                            <span style={{ marginLeft: '8px' }}>Summary</span>
                                        </label>
                                    </div>
                                    <div className='col-lg-6 borderbody' style={{ width: '300px', marginRight: '60px' }}>
                                        <label className="d-flex align-items-center">
                                            <input
                                                type="radio"
                                                name="role"
                                                value="Trip"
                                                checked={selectedRole === 'Trip'}
                                                onChange={handleRoleChange}
                                            />
                                            <span style={{ marginLeft: '8px' }}>Trip</span>
                                        </label>
                                    </div>
                                </div>
                            </div>

                            {selectedRole === 'Summary' && (
                                <div className='mt-4'>
                                    <div className="row flex justify-content-between g-3">
                                        <div className="col-lg-6 col-md-6 date_input">
                                            <label>Start Date</label>

                                            <DatePicker
                                                selectsStart
                                                selected={startDate}
                                                startDate={startDate}
                                                endDate={endDate}
                                                minDate={new Date(createdAt)}
                                                maxDate={new Date()}
                                                onChange={(date) => setStartDate(date)}
                                                placeholderText="Select start date"
                                                customInput={<CustomInput icon={<BiCalendarEvent />} />}
                                            />

                                        </div>
                                        <div className="col-lg-6 col-md-6 date_input">
                                            <label>End Date</label>

                                            <DatePicker
                                                selectsEnd
                                                selected={endDate}
                                                startDate={startDate}
                                                endDate={endDate}
                                                minDate={new Date(createdAt)}
                                                maxDate={new Date()}
                                                onChange={(date) => setEndDate(date)}
                                                placeholderText="Select end date"
                                                customInput={<CustomInput icon={<BiCalendarEvent />} />}
                                            />

                                        </div>
                                    </div>
                                </div>
                            )}

                            {selectedRole === 'Trip' && (
                                <div className='mt-4'>
                                    <div className="row flex justify-content-between g-3">
                                        <div className="col-lg-6 col-md-6 date_input">
                                            <label>Select Date</label>
                                            <DatePicker
                                                selected={startDate}
                                                minDate={new Date(createdAt)}
                                                maxDate={new Date()}
                                                onChange={(date) => {
                                                    setStartDate(date);
                                                    setEndDate(null);
                                                }}
                                                placeholderText="Select date"
                                                customInput={<CustomInput icon={<BiCalendarEvent />} />}
                                            />
                                        </div>
                                    </div>
                                </div>
                            )}

                            <div className='d-flex justify-content-end mt-4' >
                                <div className='col-lg-2 mx-5' onClick={closeModal}>
                                    <Button text="Cancel" type="button" isCancel={true} />
                                </div>
                                {selectedRole === 'Summary' && (
                                    <div className='col-lg-2' onClick={() => handleDetails(customerId)}>
                                        <Button text="Download" type="submit" />
                                    </div>
                                )}
                                {selectedRole === 'Trip' && (
                                    <div className='col-lg-2' onClick={() => handleDetailsTrip(customerId)}>
                                        <Button text="Download" type="submit" />
                                    </div>
                                )}
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
        </div>
    );
}

const CustomInput = ({ icon, placeholder, ...restProps }) => (
    <div style={{ position: "relative" }}>
        <input {...restProps} className="form-control" placeholder={placeholder} />
        <div style={{ position: "absolute", right: "10px", top: "50%", transform: "translateY(-50%)" }}>
            {icon}
        </div>
    </div>
);

export default CreatChannelPartner;
