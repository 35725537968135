import React from 'react'
import menu from 'assets/img/Icons/Dashboard/menu.svg';
import FormLabel from "../../components/controls/FormControl";
import routes from "../../routes";
import { useLocation } from 'react-router-dom';
import UserProfile from "./UserProfile"


function Header(props) {
    const location = useLocation();
    const currentURL = location.pathname;
    const toggle = props.toggle;
    const handletoggle = props.handletoggle;

    const matchedRoute = routes.find(route => route.path === currentURL);


    return (
        <>
            <div className='header'>


                <div className='header_content '>

                    <div>
                        <FormLabel control="heading" title={matchedRoute?.name} />

                    </div>
                    {
                        toggle && <div className='col-lg-3'>
                            <UserProfile />
                        </div>
                    }


                    <div onClick={() => { handletoggle() }} id='hamburger-1' className={`hamburger ${toggle ? 'is-active' : ''}`}>
                        <img src={menu} />
                    </div>

                </div>

            </div>
            {
                !toggle && <div className='col-lg-3'>
                    <UserProfile />
                </div>
            }
        </>

    )
}

export default Header