import React, { useState } from 'react';
import Loader from 'components/loader';

function Notification() {
    const [loading, setLoading] = useState(false);

    // Separate state for each toggle
    const [emailAlerts, setEmailAlerts] = useState(true);
    const [inAppNotifications, setInAppNotifications] = useState(true);
    const [smsAlerts, setSmsAlerts] = useState(true);

    return (
        <div className="container-fluid pb-5">
            <div className='row my-5'>
                <Loader loading={loading} />

                <div className="col-5">
                    <div className='custom_tile_tablesetting mb-3'>
                        <div>Email Alerts</div>
                        <div className="form-check form-switch">
                            <input
                                className="form-check-input"
                                type="checkbox"
                                role="switch"
                                id="emailAlerts"
                                checked={emailAlerts}
                                onChange={() => setEmailAlerts(!emailAlerts)}
                            />
                        </div>
                    </div>

                    <div className='custom_tile_tablesetting mb-3'>
                        <div>In-app Notifications</div>
                        <div className="form-check form-switch">
                            <input
                                className="form-check-input"
                                type="checkbox"
                                role="switch"
                                id="inAppNotifications"
                                checked={inAppNotifications}
                                onChange={() => setInAppNotifications(!inAppNotifications)}
                            />
                        </div>
                    </div>

                    <div className='custom_tile_tablesetting mb-3'>
                        <div>SMS Alerts</div>
                        <div className="form-check form-switch">
                            <input
                                className="form-check-input"
                                type="checkbox"
                                role="switch"
                                id="smsAlerts"
                                checked={smsAlerts}
                                onChange={() => setSmsAlerts(!smsAlerts)}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Notification;
