import React from 'react'
function ADD({ text, type, disabled, isCancel }) {
    return (
        <div>
            <button className={` ${isCancel ? 'cancelClass add_btn' : 'add_btn colorClass'}`} type={type} disabled={disabled}>
                {text}
            </button>
        </div>
    );
}


export default ADD
