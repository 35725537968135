

import React from "react";
import FormControl from "../../../components/controls/FormControl";
import Button from "../../../components/buttons/Default"
import Loader from "components/loader"
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { toast } from "react-toastify";
import { loginService } from "utils/Services";
import { useNavigate } from "react-router-dom";
// Custom components
// Assets

function SignIn(props) {
  const { nextStep, details, setDetails } = props
  const navigate = useNavigate();
  // Chakra color mode
  const [loading, setloading] = React.useState(false);
  const [show, setShow] = React.useState(false);
  const handleClick = () => setShow(!show);
  const validationSchema = Yup.object().shape({
    formData: Yup.object().shape({
      contact_no: Yup.string()
      .required('Contact number is required.')
      .matches(/^\d{10}$/, 'Contact number must be exactly 10 digits.'),
      password: Yup.string().required('Password is required'),
    }),
  });
  const handle = async (values) => {
    const data =
    {
      ...values.formData
    }
    try {
      setloading(true);
      const result = await loginService(data);
      if (result?.status == 201) {
        setloading(false)
        toast.success(result.message)
        nextStep();
        setDetails((pre) =>
        ({
          ...pre,
          'data': data?.contact_no
        }))
      }
      else {
        setloading(false)
        toast.error(result.message)
      }
    }
    catch (err) {

      setloading(false)
      toast.error(err && err?.message)
    }
  }
  const handleForgot = () => {
    navigate('/reset-password')
  }
  return (


    <div className="login_wrapper">
      <Loader loading={loading} />
      <div className="login_block col-lg-3">
        <Formik
          initialValues={{
            formData: {
              "contact_no": "",
              'password': "",
              'controlled': true

            },
          }}
          validationSchema={validationSchema}
          onSubmit={handle}
        >
          {formik => (
            <div className="row">
              <Form className="form_default ">
                <div className='mb-4'>
                  <FormControl
                    control='heading'
                    title='Login'
                  />
                </div>
                <div className='mb-4'>
                  <div className='col-lg-12'>
                    <div className='mb-3'>
                      <FormControl
                        control='InputLabel'
                        label="User ID"
                        placeholder='Enter Email Or Phone no.'
                        name='formData.contact_no'

                      />
                    </div>

                    <div className='mb-3'>
                      <FormControl
                        label="Password"
                        control='InputLabel'
                        type="password"
                        name='formData.password'

                      />
                    </div>

                    <div className="d-flex justify-content-end" onClick={handleForgot}>
                      <span className="tetxdark_under">
                        Forgot Passwordss?
                      </span>

                    </div>

                  </div>
                </div>

                <Button type='submit' text='Proceed' disabled={formik.isSubmitting} />
                <div className="mt-4">
                  <p>

                    {/* <span className="tetxdark_under mx-1">
                      Sign Up
                    </span> */}
                  </p>
                </div>

              </Form>
            </div>
          )}
        </Formik>
      </div>


    </div>

  );
}

export default SignIn;
