import React, { useState, useEffect } from 'react';
import { CustomTabs, CustomTabPanel } from 'components/Tabs';
import Genral from 'components/ViewDetails/Genral';
import Kyc from 'components/ViewDetails/Kyc';
import Plan from 'components/ViewDetails/Plan';
import Vehiclehistory from 'components/ViewDetails/Vehiclehistory'
import Transjections from 'components/ViewDetails/Transjections';
import { useParams } from "react-router-dom";

function Raci_Role() {
    // Get the active tab from local storage or default to the first tab
    const initialTab = parseInt(localStorage.getItem('activeTabservice')) || 0;
    const params = useParams();
    // State to manage the active tab
    const [activeTab, setActiveTab] = useState(initialTab);

    // Update local storage when the active tab changes
    useEffect(() => {
        localStorage.setItem('activeTabservice', parseInt(activeTab));
    }, [activeTab]);

    return (
        <div>
            <div className="container-fluid pb-5">

                <CustomTabs activeTab={activeTab} onChange={setActiveTab} setActiveTab={setActiveTab} >
                    <CustomTabPanel tabLabel="General Details" key="1">
                        <Genral data={params.id} />
                    </CustomTabPanel>
                    <CustomTabPanel tabLabel="KYC Details" key="2">
                        <Kyc data={params.id} />
                    </CustomTabPanel>
                    <CustomTabPanel tabLabel="Plan Details" key="3">
                        <Plan data={params.id} />
                    </CustomTabPanel>
                    <CustomTabPanel tabLabel="Transaction" key="4">
                        <Transjections data={params.id} />
                    </CustomTabPanel>
                    <CustomTabPanel tabLabel="User Vehicle History" key="4">
                        <Vehiclehistory data={params.id} />
                    </CustomTabPanel>
                </CustomTabs>
            </div>
        </div>
    );
}

export default Raci_Role;