import React, { useContext, useEffect, useMemo, useState } from 'react';
import FormControl from "components/controls/FormControl";
import CustomTable from "components/Table";
import Button from "components/buttons/ADD";
import EditDelete from "components/buttons/EditDelete";
import { ModalContext } from 'utils/Contexts/modalContext';
import CreatChannelPartner from 'components/modals/CreatChannelPartner';
import CreateAdmin from 'components/modals/CreateAdmin';
import EditAdmin from 'components/modals/EditAdmin';
import CoomonDelete from 'components/modals/CoomonDelete';
import Loader from 'components/loader';
import { DELETECREATECHANNELPARTNERAPI, EDITADMINMANAGERPI, PAYMENTSTATUSAPISERVICES } from 'utils/Services';
import { toast } from 'react-toastify';
import FallBack from "components/fallBack";
import { PAYMENTFLOWYOAPISERVICES } from 'utils/Services';
import moment from 'moment';

function Paymentuser() {
    const modalContext = useContext(ModalContext);
    const { closeModal, handleModalData } = modalContext;
    const [loading, setLoading] = useState(false);
    const [allData, setAllData] = useState([]);
    const [toggleStates, setToggleStates] = useState({});

    const options = [
        { id: '1', label: 'Zeway Super Admin', value: 'super_admin' },
        { id: '2', label: 'Admin (Channel Partner)', value: 'admin' },
        { id: '3', label: 'Manager (Channel Partner)', value: 'manager' }
    ];

    const handleToggle = (id) => {
        setToggleStates((prevState) => ({
            ...prevState,
            [id]: !prevState[id] // Toggle the state for the corresponding row ID
        }));
    };

    const togglefunction = async (e, data) => {
        const check = e.target.checked;

        try {
            const response = await PAYMENTSTATUSAPISERVICES({
                fleet_no: data.fleetNo,
                status: check
            });
            if (response.res.status === 200) {
                // Update allData state using map to create a new array
                setAllData((prevData) =>
                    prevData.map((item) =>
                        item.fleetNo === data.fleetNo
                            ? { ...item, status: check ? "Idle" : "Ignition off" }
                            : item
                    )
                );
            }
        } catch (error) {
            console.error("Error updating status:", error);
        }
    };


    const FeedsColumns = useMemo(
        () => [
            {
                Header: "Sno.",
                accessor: (row, index) => <span className='text-sm'>{index + 1}</span>
            },
            {
                Header: "Customer Name",
                accessor: (Customer) => Customer?.full_name || ""
            },
            {
                Header: "Vehicle Number",
                accessor: (Customer) => Customer?.fleetNo || ""
            },
            {
                Header: "Date",
                accessor: (Customer) =>
                    Customer.date ? moment(Customer.date).format("DD-MM-YYYY") : ""
            },
            {
                Header: "Status",
                accessor: (row) => {
                    const isToggled = toggleStates[row?.id] || false; // Get toggle state for the row
                    return (
                        <div className='d-flex justify-content-between align-items-center'>
                            {/* <div className='col-lg-8' onClick={() => handle("edit", row?.id)}>
                                <EditDelete text="Edit" type="Button" />
                            </div> */}

                            <div>
                                {
                                    row.status !== "Ignition off" && row.status !== "Idle" ?
                                        <span className={`status-badge ${row.status.replace(" ", "-").toLowerCase()}`} style={{color:'#212529'}}>
                                            {row.status}
                                        </span>
                                        :
                                        <label class="switch">
                                            <input type="checkbox" checked={row.status === "Idle"} onClick={(e) => togglefunction(e, row)} />

                                            <span class="slider round"></span>
                                        </label>
                                }

                            </div>
                        </div>
                    );
                }
            },
        ],
        [toggleStates] // Add toggleStates as a dependency to update when toggling
    );

    const fetchData = async () => {
        try {
            setLoading(true);
            const result = await PAYMENTFLOWYOAPISERVICES();
            if (result?.res?.status === 200) {
                setAllData(result?.res?.data);
            }
            setLoading(false);
        } catch (err) {
            console.error(err);
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    const handleDelete = async (data) => {
        setLoading(true);
        try {
            const result = await DELETECREATECHANNELPARTNERAPI(data);
            if (result?.res?.status === 200) {
                toast.success(result?.res?.message);
                fetchData();
                closeModal();
            }
        } catch (err) {
            toast.error(err?.message || err);
            setLoading(false);
            closeModal();
        }
    };

    const handle = (action, info) => {
        const CreateDocument = <CreateAdmin closeModal={closeModal} reload={fetchData} />;
        const EditDocument = <EditAdmin closeModal={closeModal} data={info} reload={fetchData} />;
        const DeleteDocument = <CoomonDelete closeModal={closeModal} data={info} handle={handleDelete} reload={fetchData} />;

        switch (action) {
            case "create":
                handleModalData(CreateDocument, "lg");
                break;
            case "edit":
                handleModalData(EditDocument, "md");
                break;
            case "delete":
                handleModalData(DeleteDocument, "md");
                break;
            default:
                break;
        }
    };

    return (
        <div className="container-fluid pb-5">
            <div className='row'>
                <Loader loading={loading} />
                {allData.length > 0 ? (
                    <div className='default_table'>
                        <CustomTable data={allData} columns={FeedsColumns} />
                    </div>
                ) : (
                    <FallBack />
                )}
            </div>
        </div>
    );
}

export default Paymentuser;
