import React, { useEffect, useState } from 'react';
import { Formik, Form } from 'formik';  // Import Formik and Form
import FormControl from 'components/controls/FormControl';
import Loader from "components/loader"
import Button from 'components/buttons/ADD';
import { GETSTATEAPI, GETCITYAPI, USERCREATEDAPI } from 'utils/Services';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { VIEWCREATECHANNELPARTNERAPI } from 'utils/Services';


function CreateUser({ closeModal, reload }) {
    const [options, setOptions] = useState({
        state: [], city: [], status: [{ 
            id: "Ideal",
            label: 'Ideal',
            value: "Ideal"
        }, {
            id: "Active",
            label: 'Active',
            value: "Active"
        },
        { 
            id: "Inactive",
            label: 'Inactive',
            value: "Inactive"
        }, {
            id: "Disable",
            label: 'Disable',
            value: "Disable"
        }, {
            id: "blank",
            label: 'blank',
            value: "blank"
        }],
        gender: [{
            id: 1,
            label: 'Male',
            value: '0',
        }, {
            id: 2,
            label: 'Female',
            value: '1',
        },
        {
            id: 3,
            label: 'Other',
            value: '2',
        }],
        ass: [{
            id: 1,
            label: 'Other ',
            value: 'Other',
        },
        {
            id: 2,
            label: 'Zeway Rider',
            value: 'Zeway Rider',
        },
        {
            id: 3,
            label: 'Channel Partner',
            value: 'Channel Partner',
        }],

    });
    const [loading, setLoading] = useState(false)
    const [selectState, setSelectState] = useState()
    const fetchState = async () => {
        setLoading(true)
        try {
            const result = await GETSTATEAPI();
            if (result?.res?.status == 200) {
                const data = result?.res?.data?.map((res) => ({
                    id: res?.id,
                    label: res?.name,
                    value: res?.name
                }));

                setOptions((res) =>
                ({
                    ...res,
                    state: data
                }))
                setLoading(false)
            }
            else {
                setLoading(false)
            }
        }
        catch (err) {
            console.log(err);
            setLoading(false)

        }



    }
    const fetchCity = async () => {

        if (selectState !== '' && selectState !== null && selectState !== undefined) {
            const data = {
                "state_id": selectState
            };
            setOptions((res) => ({
                ...res,
                city: []
            }));
            try {
                setLoading(true);
                const result = await GETCITYAPI(data);

                if (result?.status === 200) {
                    const data = result?.data?.map((res) => ({
                        id: res?.id,
                        label: res?.name,
                        value: res?.name
                    }));

                    setOptions((res) => ({
                        ...res,
                        city: data
                    }));
                    setLoading(false);
                } else {
                    setLoading(false);
                }
            } catch (err) {
                console.log(err);
                setLoading(false);
            }
        }


    }
    useEffect(() => {
        fetchState()
    }, [])
    useEffect(() => {
        fetchCity()
    }, [selectState])
    const validationSchema = Yup.object().shape({
        formData: Yup.object().shape({
            full_name: Yup.string().required('Full Name is required'),
            gender: Yup.string().required('Gender is required'),
            date_birth: Yup.date()
                .required('Date of Birth is required'),
                // .test(
                //     'DOB',
                //     'You must be at least 18 years old',
                //     function (value) {
                //         const today = new Date();
                //         const birthDate = new Date(value);
                //         const age = today.getFullYear() - birthDate.getFullYear();
                //         const m = today.getMonth() - birthDate.getMonth();
                //         if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
                //             return age > 18;
                //         }
                //         return age >= 18;
                //     }
                // ),
            contact_no: Yup.string()
                .required('Contact number is required.')
                .matches(/^\d{10}$/, 'Contact number must be exactly 10 digits.'),
            email: Yup.string().email('Invalid email').required('Email is required'),
            address_line_1: Yup.string().required('Address Line 1 is required'),
            address_line_2: Yup.string().required('Address Line 2 is required'),
            state: Yup.string().required('State is required'),
            city: Yup.string().required('City is required'),
            pincode: Yup.string()
                .required('Pincode is required')
                .matches(/^\d{6}$/, 'Pincode must be exactly 6 digits')

        }),
    });
    // const handle = async (values) => {
    //     setLoading(true)
    //     try {
    //         const formData = new FormData();

    //         const data = { ...values.formData, 'channel_partner_name': values.formData.asso_ciate == 3 ? values.formData.channel_partner : values.formData.asso_ciate }
    //         const result = await USERCREATEDAPI(data);

    //         toast.success(result?.message)
    //         setLoading(false)
    //         { reload && reload() }
    //         closeModal()
    //     }
    //     catch (err) {
    //         setLoading(false)
    //         toast.error(err?.message)
    //     }
    // }

    const handle = async (values) => {
        setLoading(true);
        try {
            // Parse the date_birth value
            const birthDate = new Date(values.formData.date_birth);
            
            // Get the year, month, and day
            const year = birthDate.getFullYear();
            let month = birthDate.getMonth() + 1; // getMonth() is 0-based, so add 1
            let day = birthDate.getDate();
            
            // Convert month and day to remove leading zero
            month = month.toString().replace(/^0+/, '');
            day = day.toString().replace(/^0+/, '');
            
            // Format the date as "YYYY-M-D"
            const formattedDateOfBirth = `${year}-${month}-${day}`;
    
            const formData = new FormData();
    
            const data = {
                ...values.formData,
                'date_birth': formattedDateOfBirth,
                'channel_partner_name': values.formData.asso_ciate == 3 ? values.formData.channel_partner : values.formData.asso_ciate
            };
            
            const result = await USERCREATEDAPI(data);
    
            toast.success(result?.message);
            setLoading(false);
            if (reload) reload();
            closeModal();
        } catch (err) {
            setLoading(false);
            toast.error(err?.message);
        }
    };

 
    

    
    const fetchChannel = async () => {
        setLoading(true);
        try {
            const result = await VIEWCREATECHANNELPARTNERAPI();

            if (result?.res?.status === 200) {
                const data = result?.res?.data?.map((res) => ({
                    id: res?.id,
                    label: res?.channel_partner_name,
                    value: res?.channel_partner_name
                }));

                setOptions((res) => ({
                    ...res,
                    channel: data
                }));
                setLoading(false);
            } else {
                setLoading(false);
            }
        } catch (err) {
            console.log(err);
            setLoading(false);
        }
    }
    useEffect(() => {
        fetchChannel()
    }, [])
    return (
        <div className='row'>
            <Loader loading={loading} />
            <div className='com_modal'>
                {/* Use Formik wrapper */}
                <Formik
                    initialValues={{
                        formData: {
                            "contact_no": "",
                            "email": "",
                            "address_line_1": "",
                            "address_line_2": "",
                            "state": "",
                            "city": "",
                            "pincode": "",
                            "channel_partner": "",
                            "full_name": "",
                            "gender": "",
                            "date_birth": "",
                            'customerRideStatus': 'Inactive',
                            'asso_ciate': "",
                            'profile_photo': '',
                            'is_policy': true,
                            'profile_photo': 'ProfilePhoto/dummy.jpg'
                        },
                    }}
                    validationSchema={validationSchema}
                    onSubmit={handle}

                >
                    {(formik) => (
                        // Wrap your content in the Form component
                        <Form className='form_default text_left'>
                            <div className='mb-3'>
                                <FormControl control="FormHead" title="Create User" />
                            </div>
                            <div className='mb-3'>
                                <div className='row g-3'>



                                    <div className='col-lg-6'>
                                        <FormControl
                                            control="InputLabel"
                                            name={"formData.full_name"}
                                            type={"text"}
                                            label={"Full Name"}
                                            required={true}
                                            onKeyPress={(e) => {
                                                const charCode = e.which ? e.which : e.keyCode;
                                                // Allow only alphabetic characters and spaces
                                                if (!/[a-zA-Z\s]/.test(String.fromCharCode(charCode))) {
                                                    e.preventDefault();
                                                }
                                            }}
                                        
                                        />
                                    </div>
                                    <div className='col-lg-6'>
                                        <FormControl
                                            control="SelectBox"
                                            name={"formData.asso_ciate"}
                                            options={options.ass}
                                            label={"Associated With"}
                                        />
                                    </div>

                                    {
                                        formik.values.formData.asso_ciate == 3 ? (<div className='col-lg-6'>
                                            <FormControl
                                                control="SelectBox"
                                                name={"formData.channel_partner"}
                                                options={options.channel}
                                                label={"Channel Partner"}
                                                required={true}


                                            />
                                        </div>) : (<></>)
                                    }


                                    <div className='col-lg-6'>
                                        <FormControl
                                            control="InputLabel"
                                            name={"formData.contact_no"}
                                            type={"text"}
                                            label={"Contact Number"}
                                            required={true}
                                        />
                                    </div>
                                    <div className='col-lg-6'>
                                        <FormControl
                                            control="InputLabel"
                                            name={"formData.email"}
                                            type={"text"}
                                            label={"Email ID"}
                                            required={true}
                                        />
                                    </div>
                                    <div className='col-lg-6'>
                                        <FormControl
                                            control="InputLabel"
                                            name={"formData.address_line_1"}
                                            type={"text"}
                                            label={"Address 1"}
                                            required={true}
                                        />
                                    </div>
                                    <div className='col-lg-6'>
                                        <FormControl
                                            control="InputLabel"
                                            name={"formData.address_line_2"}
                                            type={"text"}
                                            label={"Address 2"}
                                            required={true}
                                        />
                                    </div>
                                    <div className='col-lg-6'>
                                        <FormControl
                                            control="SelectBox"
                                            name={"formData.state"}
                                            options={options.state}
                                            label={"State"}
                                            setfield={setSelectState}
                                            required={true}
                                        />
                                    </div>
                                    <div className='col-lg-6'>
                                        <FormControl
                                            control="SelectBox"
                                            name={"formData.city"}
                                            options={options.city}
                                            label={"City"}
                                            required={true}
                                        />
                                    </div>
                                    <div className='col-lg-6'>
                                        <FormControl
                                            control="InputLabel"
                                            name={"formData.pincode"}
                                            type={"text"}
                                            label={"Pincode"}
                                            required={true}
                                        />
                                    </div>
                                    <div className='col-lg-6'>
                                        <FormControl
                                            control="SelectBox"
                                            name={"formData.gender"}
                                            options={options.gender}
                                            label={"Gender"}
                                            required={true}
                                        />
                                    </div>
                                    <div className='col-lg-6'>
                                        <FormControl
                                            control="InputLabel"
                                            name={"formData.date_birth"}
                                            type={"date"}
                                            label={"DOB."}
                                            required={true}
                                        />
                                    </div>
                                    <div className='col-lg-6'>
                                        <FormControl
                                            control="SelectBox"
                                            name={"formData.customerRideStatus"}
                                            options={options.status}
                                            label={"User Status"}
                                            prevalue={formik?.values?.formData?.customerRideStatus}
                                        />
                                    </div>
                                </div>

                            </div>

                            <div className='d-flex justify-content-end'>
                                <div className='col-lg-2 mx-5' onClick={closeModal}>
                                    <Button text="Cancel" type="button" isCancel={true} />
                                </div>

                                <div className='col-lg-2'>
                                    <Button text="Save" type="submit" />
                                </div>

                            </div>

                            <div>
                                <p>
                                    all asterisk (*) field are required
                                </p>
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
        </div >
    );
}

export default CreateUser;
